import { HexColorPicker } from "react-colorful";
import { useLocation, useNavigate } from "react-router-dom";
import { useApi } from "../../utils/Api";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  Stack,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Translate from "../../utils/Translate";
import { useState } from "react";
import { useEffect } from "react";
import Preloader from "../Preloader";
import ButtonWithSpinner from "../ButtonWithSpinner";

export default function AddOrEditLicenseGroupDialog({ licenseGroupId }) {
  const [licenseGroup, setLicenseGroup] = useState(licenseGroupId ? null : {});
  const [isLoading, setIsLoading] = useState(true);

  const api = useApi();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (licenseGroupId) {
      api
        .fetch(
          `${process.env.REACT_APP_MAIN_URL}licenses/groups/${licenseGroupId}`,
          false,
          "GET"
        )
        .then((response) => {
          if (response.isSuccessful) {
            setLicenseGroup({
              licenseGroupId: response.licenseGroupId,
              name: response.name,
              webColor: response.webColor,
            });
          }
        });
    }
    setIsLoading(false);
  }, [api, licenseGroupId]);

  const closeAndReload = () => {
    //Soft reload with redirect
    const hash = window.location.hash.endsWith("#dialog")
      ? window.location.hash.substring(0, window.location.hash.length - 7)
      : window.location.hash;
    const loc = encodeURIComponent(
      `${location.pathname}${location.search}${hash}`
    );
    navigate(`/redirect/${loc}`, { replace: true });
  };

  async function postLicenseGroup() {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}licenses/groups`,
      licenseGroup,
      "POST"
    );
    return response.isSuccessful;
  }

  async function handleSaveLicenseGroup() {
    if (await postLicenseGroup()) {
      closeAndReload();
    }
  }

  return (
    <>
      {isLoading && <Preloader />}
      {!isLoading && (
        <>
          <DialogContent className="stdDialogContent dialogOverlay">
            <br />
            <Stack direction="column" gap={4}>
              <TextField
                fullWidth
                value={licenseGroup?.name ?? ""}
                onChange={(event) =>
                  setLicenseGroup({ ...licenseGroup, name: event.target.value })
                }
                label={Translate.get("LicenseName")}
                placeholder={Translate.get("LicenseName")}
              />

              <Stack direction="column">
                <InputLabel>{Translate.get("Color")}</InputLabel>
                <HexColorPicker
                  color={licenseGroup?.webColor}
                  onChange={(color) =>
                    setLicenseGroup((prev) => ({ ...prev, webColor: color }))
                  }
                />
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAndReload}>{Translate.get("Cancel")}</Button>
            <ButtonWithSpinner
              disabled={!licenseGroup || !licenseGroup.name}
              onClick={handleSaveLicenseGroup}
            >
              {Translate.get("Save")}
            </ButtonWithSpinner>
          </DialogActions>
        </>
      )}
    </>
  );
}
