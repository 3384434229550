import { useEffect, useState } from "react";
import Translate from "../../../utils/Translate";
import { useApi } from "../../../utils/Api";
import { useSnackbar } from "notistack";
import Preloader from "../../Preloader";
import { TextField, Button, Tooltip, Box, Stack } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { LoadingButton } from "@mui/lab";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InfoIcon from "@mui/icons-material/Info";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SchoolIcon from "@mui/icons-material/School";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import PlaceIcon from "@mui/icons-material/Place";
import PeopleIcon from "@mui/icons-material/People";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import MoneyIcon from "@mui/icons-material/Money";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ShortTextIcon from "@mui/icons-material/ShortText";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import NotesIcon from "@mui/icons-material/Notes";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import BadgeIcon from "@mui/icons-material/Badge";
import ImageIcon from "@mui/icons-material/Image";
import { accessKeys, hasAccess } from "../../../utils/userAccess";
import CourseTypeSkillsDialog from "./CourseTypeSkillsDialog";

const CourseTypeForm = ({ courseTemplate }) => {
  const api = useApi();
  const location = useLocation();
  const navigate = useNavigate();
  const closeAndReload = () => {
    const hash = window.location.hash.endsWith("#dialog")
      ? window.location.hash.substring(0, window.location.hash.length - 7)
      : window.location.hash;
    const loc = encodeURIComponent(
      `${location.pathname}${location.search}${hash}`
    );
    navigate(`/redirect/${loc}`, { replace: true });
  };
  const [data, setData] = useState(false);
  const [licenseGroups, setLicenseGroups] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [isEditing] = useState(!!courseTemplate.courseTemplateId);
  const [courseIcons, setCourseIcons] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [openCourseTypeSkillsDialog, setOpenCourseTypesSkillsDialog] = useState(false);
  const [retCourseTemplateId, setRetCourseTemplateId] = useState(null);

  useEffect(() => {
    async function loadData() {
      const [courseLocations, teachers, cities, licenseGroups, icons] =
        await Promise.all([
          api.fetch(
            `${process.env.REACT_APP_MAIN_URL}courses/courselocations`,
            false,
            "GET"
          ),
          api.fetch(
            `${process.env.REACT_APP_MAIN_URL}actors/teachers`,
            false,
            "GET"
          ),
          api.fetch(
            `${process.env.REACT_APP_MAIN_URL}courses/cities`,
            false,
            "GET"
          ),
          api.fetch(
            `${process.env.REACT_APP_MAIN_URL}licenses/groups`,
            false,
            "GET"
          ),
          api.fetch(
            `${process.env.REACT_APP_MAIN_URL}courses/templates/icons`,
            false,
            "GET"
          ),
        ]);

      let fixedLocations = courseLocations.courseLocations.map(
        ({ actorAddressId, actorAddressStreet1 }) => ({
          id: actorAddressId,
          label: actorAddressStreet1,
        })
      );
      fixedLocations = fixedLocations.filter((x) => x.label !== null);
      teachers.sort((a, b) =>
        a.actorName > b.actorName ? 1 : b.actorName > a.actorName ? -1 : 0
      );
      cities.sort((a, b) =>
        a.description > b.description
          ? 1
          : b.description > a.description
          ? -1
          : 0
      );

      const formData = courseTemplate;
      formData.startTime =
        toDateWithOutTimeZone(formData.startTime) ??
        new Date("December 17, 1995 08:00:00");
      formData.endTime =
        toDateWithOutTimeZone(formData.endTime) ??
        new Date("December 17, 1995 16:00:00");
      setData({
        courseLocations: fixedLocations,
        teachers: teachers,
        cities: cities,
        formData: courseTemplate,
      });
      setLicenseGroups(licenseGroups.licenseGroups);
      icons.sort((a, b) => a.name.localeCompare(b.name));
      setCourseIcons(icons);
    }

    loadData();
  }, [courseTemplate, api]);

  const submitForm = () => {
    const outData = data.formData;
    setSubmitting(true);

    if (outData.startTime === null) {
      outData.startTime = new Date("December 17, 1995 08:00:00");
    }

    if (outData.endTime === null) {
      outData.endTime = new Date("December 17, 1995 16:00:00");
    }
    outData.startTime = formatOutTime(outData.startTime);
    outData.endTime = formatOutTime(outData.endTime);

    (async () => {
      const ret = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}courses/templates/add`,
        outData
      );
      if (ret.isSuccessful) {
        enqueueSnackbar(Translate.get("CourseTypeUpdated"), {
          variant: "success",
          autoHideDuration: 3000,
        });
      }

      setSubmitting(false);

      setRetCourseTemplateId(ret.courseTemplateId);
      setOpenCourseTypesSkillsDialog(true);
      //closeAndReload();

    })();
  };
  const formatOutTime = (dateOrTimeString) => {
    if (dateOrTimeString instanceof Date) {
      return dateOrTimeString.toLocaleTimeString("sv-SE");
    } else {
      return false;
    }
  };

  function toDateWithOutTimeZone(date) {
    if (!date || date instanceof Date) {
      return date;
    }

    let tempTime = date.split(":");
    if (tempTime.length !== 3) {
      return date;
    }

    let dt = new Date();
    dt.setHours(tempTime[0]);
    dt.setMinutes(tempTime[1]);
    dt.setSeconds(tempTime[2]);
    return dt;
  }

  if (!data) {
    return <Preloader />;
  }
  return (
    <>
      {openCourseTypeSkillsDialog && <CourseTypeSkillsDialog
              open={true}
              courseTemplateId={retCourseTemplateId}
              onClose={() => {setOpenCourseTypesSkillsDialog(false); closeAndReload()}}
            />}

      <DialogTitle>
        <div className="stdFlexBetween">
          <div>
            {Translate.get(
              isEditing ? "EditCourseType" : "CreateCourseTemplate"
            )}
          </div>
          <Tooltip title={Translate.get("CourseTypeToolTip")}>
            <IconButton>
              <InfoIcon color="primary" />
            </IconButton>
          </Tooltip>
        </div>
      </DialogTitle>

      <DialogContent dividers={true} className="stdDialogContent">
        <div className="stdForm">
          {hasAccess(accessKeys.admin) && (
            <div>
              <ImageIcon />
              <FormControl>
                <InputLabel id="icon-label">{Translate.get("Icon")}</InputLabel>
                <Select
                  sx={{ minWidth: "146px", minHeight: "133px" }}
                  labelId="icon-label"
                  id="icon-select"
                  value={data.formData.iconName || ""}
                  label={Translate.get("Icon")}
                  onChange={(event) => {
                    data.formData.iconName = event.target.value;
                    setData({ ...data });
                  }}
                >
                  {courseIcons.map((icon) => (
                    <MenuItem key={icon.name} value={icon.name}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        columnGap="10px"
                      >
                        <img
                          src={icon.url}
                          alt=""
                          width="100px"
                          height="100px"
                        />
                        <span>{icon.name}</span>
                      </Stack>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          <div>
            <SchoolIcon />

            <TextField
              required
              fullWidth
              label={Translate.get("Name")}
              name="courseShortName"
              value={data.formData.courseShortName || ""}
              onChange={(event, value) => {
                data.formData.courseShortName = event.target.value;
                setData({ ...data });
              }}
            />
          </div>
          <div>
            <ShortTextIcon />

            <TextField
              required
              fullWidth
              label={Translate.get("CourseNameLong")}
              name="description"
              value={data.formData.description || ""}
              onChange={(event, value) => {
                data.formData.description = event.target.value;
                setData({ ...data });
              }}
            />
          </div>
          {hasAccess(accessKeys.admin) && (
            <div>
              <BadgeIcon />
              <FormControl fullWidth>
                <InputLabel id="license-group-label" required>
                  {Translate.get("License")}
                </InputLabel>
                <Select
                  required
                  disabled={isEditing}
                  labelId="license-group-label"
                  id="license-group-select"
                  value={data.formData.licenseGroupId || ""}
                  label={Translate.get("License")}
                  fullWidth
                  onChange={(event, value) => {
                    data.formData.licenseGroupId = event.target.value;
                    setData({ ...data });
                  }}
                >
                  {licenseGroups.map((licenseGroup) => (
                    <MenuItem
                      key={licenseGroup.licenseGroupId}
                      value={licenseGroup.licenseGroupId}
                    >
                      {licenseGroup.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          <div>
            <DateRangeIcon />

            <TextField
              required
              fullWidth
              label={Translate.get("NumberOfDays")}
              name="noDays"
              //defaultValue={data.formData.noDays || ''}
              value={data.formData.noDays || ""}
              onChange={(event, value) => {
                data.formData.noDays = event.target.value;
                setData({ ...data });
              }}
            />
          </div>
          <div>
            <AccountBoxIcon />

            <FormControl fullWidth>
              <InputLabel id="instructor-label">
                {Translate.get("Instructor")}
              </InputLabel>
              <Select
                labelId="instructor-label"
                value={data.formData.instructorActorId || ""}
                label={Translate.get("Instructor")}
                fullWidth
                name="instructorActorId"
                onChange={(event, value) => {
                  data.formData.instructorActorId = event.target.value;
                  setData({ ...data });
                }}
              >
                {data.teachers.map((row, index) => (
                  <MenuItem key={index} value={row.actorId}>
                    <div>
                      <div>{row.actorName}</div>
                      <div>{row.email}</div>{" "}
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <PlaceIcon />
            <FormControl fullWidth>
              <InputLabel id="city-label">{Translate.get("City")}</InputLabel>
              <Select
                labelId="city-label"
                name="courseCityId"
                value={data.formData.courseCityId || ""}
                label={Translate.get("City")}
                fullWidth
                onChange={(event, value) => {
                  data.formData.courseCityId = event.target.value;
                  setData({ ...data });
                }}
              >
                {data.cities.map((row, index) => (
                  <MenuItem key={index} value={row.courseCityId}>
                    {row.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div>
          <Accordion className="noPaperMargin" sx={{ padding: 0 }}>
            <AccordionSummary
              sx={{ padding: 0 }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="smallHeader">
                {Translate.get("AdvancedSettings")}
              </div>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <div className="stdForm">
                <div>
                  <PeopleIcon />

                  <TextField
                    fullWidth
                    label={Translate.get("NoOfSeats")}
                    name="maxParticipants"
                    value={data.formData.maxParticipants || ""}
                    onChange={(event, value) => {
                      data.formData.maxParticipants = event.target.value;
                      setData({ ...data });
                    }}
                  />
                </div>
                <div className="stdFlexBetween">
                  <EventAvailableIcon />
                  <div style={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      label={Translate.get("ValidTime")}
                      name="validTimeValue"
                      value={data.formData.validTimeValue || ""}
                      onChange={(event, value) => {
                        data.formData.validTimeValue = event.target.value;
                        setData({ ...data });
                      }}
                    />
                  </div>
                  <div style={{ flexGrow: 1 }}>
                    <FormControl fullWidth>
                      <InputLabel id="unittype-label">
                        {Translate.get("UnitType")}
                      </InputLabel>
                      <Select
                        labelId="unittype-label"
                        name="validTimeDefinition"
                        value={data.formData.validTimeDefinition || ""}
                        label={Translate.get("UnitType")}
                        fullWidth
                        onChange={(event, value) => {
                          data.formData.validTimeDefinition =
                            event.target.value;
                          setData({ ...data });
                        }}
                      >
                        <MenuItem value={1}>
                          {Translate.getValidTimeDefinition(1)}
                        </MenuItem>
                        <MenuItem value={2}>
                          {Translate.getValidTimeDefinition(2)}
                        </MenuItem>
                        <MenuItem value={3}>
                          {Translate.getValidTimeDefinition(3)}
                        </MenuItem>
                        <MenuItem value={4}>
                          {Translate.getValidTimeDefinition(4)}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div>
                  <MoneyIcon />

                  <TextField
                    fullWidth
                    label={Translate.get("PricePerParticipant")}
                    name="price"
                    //defaultValue={data.formData.price || ''}
                    value={data.formData.price || ""}
                    onChange={(event, value) => {
                      data.formData.price = event.target.value;
                      setData({ ...data });
                    }}
                  />
                </div>
                <div className="stdFlexBetween timePickerWrap">
                  <AccessTimeIcon />
                  <div style={{ flexGrow: 1 }}>
                    <TimePicker
                      label={Translate.get("StartTime")}
                      value={toDateWithOutTimeZone(data.formData.startTime)}
                      inputFormat="HH:mm"
                      onChange={(newValue) => {
                        data.formData.startTime = newValue;
                        setData({ ...data });
                      }}
                      renderInput={(params) => (
                        <TextField fullWidth {...params} />
                      )}
                    />
                  </div>
                  <div style={{ flexGrow: 1 }}>
                    <TimePicker
                      label={Translate.get("EndTime")}
                      //name="endTime"
                      value={toDateWithOutTimeZone(data.formData.endTime)}
                      inputFormat="HH:mm"
                      onChange={(newValue) => {
                        data.formData.endTime = newValue;
                        setData({ ...data });
                      }}
                      renderInput={(params) => (
                        <TextField fullWidth {...params} />
                      )}
                    />
                  </div>
                </div>
                <div>
                  <MeetingRoomIcon />
                  <FormControl fullWidth>
                    <InputLabel id="adress-label">
                      {Translate.get("ClassRoom")}
                    </InputLabel>
                    <Select
                      labelId="adress-label"
                      name="actorAddressId"
                      value={data.formData.actorAddressId ?? ""}
                      label={Translate.get("ClassRoom")}
                      fullWidth
                      onChange={(event, value) => {
                        data.formData.actorAddressId = event.target.value;
                        setData({ ...data });
                      }}
                    >
                      {data.courseLocations &&
                        data.courseLocations.map((row, index) => (
                          <MenuItem key={index} value={row.id}>
                            {row.label}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <NotesIcon />

                  <TextField
                    fullWidth
                    multiline
                    minRows={4}
                    label={Translate.get("TextInCourseSummoning")}
                    name="courseInformation"
                    value={data.formData.courseInformation || ""}
                    onChange={(event, value) => {
                      data.formData.courseInformation = event.target.value;
                      setData({ ...data });
                    }}
                  />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeAndReload()}>
          {Translate.get("Close")}
        </Button>
        <LoadingButton
          className="stdLightLoader"
          loading={submitting}
          onClick={submitForm}
          disabled={
            !data.formData.licenseGroupId ||
            !data.formData.description ||
            !data.formData.courseShortName ||
            !data.formData.noDays
          }
        >
          {Translate.get("Save")}
        </LoadingButton>
      </DialogActions>
    </>
  );
};
export default CourseTypeForm;
