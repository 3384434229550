import { useState } from 'react';
import { Button, Dialog, DialogContent, DialogActions } from '@mui/material';
// material
import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';
// components
import Page from '../components/Page';
import { LoginForm } from '../components/authentication/login';
// import AuthSocial from '../components/authentication/AuthSocial';
import Translate from '../utils/Translate';
import Preloader from '../components/Preloader';
import { getQueryParam } from '../utils/uriHelper';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
    display: 'flex',
}));


const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  alignContent:'center',
  //padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login({redirectPath, openBankIdLogin}) {
  const [dataFetched, setDataFetched] = useState(false);
  const [isTimeout, setIsTimeout] = useState(getQueryParam("timeout"));
  const [isLogout, setIsLogout] = useState(getQueryParam("logout"));
  const [showQrCodeOnDesktop, setShowQrCodeOnDesktop] = useState(!(getQueryParam("logout") && true));

  if (!dataFetched && !isTimeout) {
    if (!sessionStorage.translation) {
     /*  let lang=9;
      if (localStorage.lang) {
        lang = localStorage.lang;
      } 
       Translate.loadPreLogin(lang).then
      */
      Translate.loadPreLogin(false).then((data) => {
        setDataFetched(true);
      });
    } else {
      setDataFetched(true);
    }
  }

  function handleTimeoutOk() {
    setIsTimeout(false);
  }

  function handleLogOutOk() {
    setIsLogout(false);
  }

  return (
    <RootStyle  className='loginPage'>
      {!dataFetched && !isTimeout && <Preloader />}

      {isTimeout && (
        <Dialog open={true}>
          <DialogContent>{Translate.get("LoggedOutTimeout")}</DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleTimeoutOk}>
              {Translate.get("Ok")}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {isLogout && (
        <Dialog open={true}>
          <DialogContent>{Translate.get("YouAreNowLoggedOut")}</DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleLogOutOk}>
              {Translate.get("Ok")}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {!(isTimeout || isLogout) && (
        <Container maxWidth="sm">
          <ContentStyle>
            <LoginForm redirectPath={redirectPath} openBankIdLogin={openBankIdLogin} startQrCodeOnDesktop={showQrCodeOnDesktop} />
          </ContentStyle>
        </Container>
      )}
    </RootStyle>
  );
}
