import React, { useEffect, useState } from 'react';
import { useApi } from '../../utils/Api';
import Paper from '@mui/material/Paper';
import Preloader from '../Preloader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Translate from "../../utils/Translate";

export function LoginWithId(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [dataFetched, setDataFetched] = useState(false);
  const api = useApi();

  useEffect(() => {
    setIsLoading(true);
    const lang = Translate.getBrowserLanguage();
    Translate.loadSelfReg(lang).then((data) => {
      setDataFetched(true);
      setIsLoading(false);
    });
  }, []);

  async function getQrToken() {
    setIsLoading(true);

    let response = null;
    try {
      response = await api.fetchWithOverride(
        `${process.env.REACT_APP_AUTH_URL}auth/login-from-qr-code/${id}`,
        false,
        'GET',
        null,
        (response) => setErrorMessage(response.errorMessageTranslationKey),
        null,
        null,
        null
      );
      setIsLoading(false);
      if (response.isSuccessful) {
        props.setToken(response.token);
        props.setId(id);
      }
    } catch (error) {
      setErrorMessage("SomethingFailed");
      setIsLoading(false);
    }
    return response?.token;
  }

  async function getType(qrToken, id) {
    setIsLoading(true);

    let response = null;
    try {
      response = await api.fetchWithOverride(
        `${process.env.REACT_APP_MAIN_URL}self/type/${id}`,
        false,
        'GET',
        null,
        (response) => setErrorMessage(response.errorMessageTranslationKey),
        null,
        null,
        qrToken
      );
      setIsLoading(false);
    } catch (error) {
      setErrorMessage("SomethingFailed");
      setIsLoading(false);
    }
    return response?.isActorSpecific;
  }

  function handleChange(event) {
    setId(event.target.value);
    if (event.target.value.length < 6) {
      setErrorMessage("");
    }
  }

  async function handleClick() {
    let qrToken = await getQrToken();
    if (qrToken === null) {
      return;
    }
    let res = await getType(qrToken, id);
    if (res) {
      props.setIsActorSpecific(true);
      props.setMode("Form");
    } else {
      props.setMode("ChooseCourseBooking");
    }
  }

  return (
    <div className="stdPage" style={{ display: 'flex', justifyContent: 'center' }}>
      {(!dataFetched || isLoading) && <Preloader />}
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={3}>

          <Paper elevation={3} sx={{ p: 8 }}>
            <img src="/instructor_logo.png" alt="ePort-logotype" className='maxWidthImageSelf' />
            <p>{Translate.get("SubmitCode")}</p>
            <TextField id="outlined-basic" variant="outlined" sx={{ pt: 1, pb: 1, width: '100%'}} value={id} onChange={handleChange} type="text" inputProps={{
              maxLength: 20,
            }} />
            <br />
            {errorMessage !== "" && <Alert severity="error">{Translate.get(errorMessage)}</Alert>}
            <Button variant='contained' disabled={id?.length < 1} onClick={handleClick} sx={{ mt: 1 }}>{Translate.get("Continue")}</Button>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
