import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import Translate from "../../utils/Translate";
import { useEffect, useState } from "react";
import { useApi } from "../../utils/Api";
import Preloader from "../Preloader";
import ButtonWithSpinner from "../ButtonWithSpinner";
import { useSnackbar } from "notistack";
import Chip from '@mui/material/Chip';
import { ShoppingCart } from "@mui/icons-material";

export default function CardPrintAtMaSystemDialog({
  open,
  onClose,
  isGenericActorLicenses,
  extraRequestData,
}) {
  const [addresses, setAddresses] = useState([]);
  const [tempAddress, setTempAddress] = useState({});
  const [useTempAddress, setUseTempAddress] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [customerOrderNo, setCustomerOrderNo] = useState(null);
  const [extraNotification, setExtraNotification] = useState(null);
  const [companyDefaultCustomerOrderNo, setCompanyDefaultCustomerOrderNo] =
    useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const api = useApi();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (open) {
      api
        .fetch(
          `${process.env.REACT_APP_MAIN_URL}licenseprint/card/masystem/info`,
          false,
          "GET"
        )
        .then((response) => {
          if (response && response.isSuccessful) {
            setAddresses(response.deliveryAddresses);
            setContacts(response.contacts);
            setCompanyDefaultCustomerOrderNo(response.defaultCustomerOrderNo);

            let defaultCustomerOrderNo = response.defaultCustomerOrderNo;
            if (response.deliveryAddresses?.length > 0) {
              setSelectedAddressId(
                response.deliveryAddresses[0].actorAddressId
              );
            }
            if (response.contacts?.length > 0) {

              //const index = response.contacts.findIndex(x => x.actorName ==="Klas Turesson");
              ////console.log(index);
              //setSelectedContactId(response.contacts[index].actorId);
              setSelectedContactId(response.contacts[0].actorId);
              defaultCustomerOrderNo =
                response.contacts[0].defaultCustomerOrderNo ??
                defaultCustomerOrderNo;
            }
            setCustomerOrderNo(defaultCustomerOrderNo);
          }
        })
        .finally((_) => setIsLoading(false));
    }
  }, [open, api]);

  async function sendPrintJobToMaSystem() {
    const dto = {
      deliveryAddressId: useTempAddress ? 0 : selectedAddressId,
      contactActorId: selectedContactId,
      customerOrderNo,
      extraNotification,
      tempAddress: useTempAddress ? tempAddress : null,
      ...extraRequestData,
    };

    const url = isGenericActorLicenses
      ? `${process.env.REACT_APP_MAIN_URL}license-registration/print`
      : `${process.env.REACT_APP_MAIN_URL}licenseprint/card/masystem/print`;
    const response = await api.fetch(url, dto);

    if (response && response.isSuccessful) {
      const successMessage = enqueueSnackbar(Translate.get("OrderOk"), {
        variant: "success",
        autoHideDuration: 6000,
        onClick: () => closeSnackbar(successMessage),
      });
      onClose(true);
    }
  }

  function handleTempAddressChange(event, fieldName) {
    const newValue = event.target.value;
    const tempAddressCopy = { ...tempAddress };
    tempAddressCopy[fieldName] = newValue;
    setTempAddress(tempAddressCopy);
  }

  function handleContactSelected(event) {
    const previousContact = contacts.find(
      (c) => c.actorId === selectedContactId
    );
    if (previousContact.defaultCustomerOrderNo === customerOrderNo) {
      setCustomerOrderNo(companyDefaultCustomerOrderNo);
    }

    const actorId = event.target.value;
    const contact = contacts.find((c) => c.actorId === actorId);
    if (contact.defaultCustomerOrderNo) {
      setCustomerOrderNo(contact.defaultCustomerOrderNo);
    }
    setSelectedContactId(actorId);
  }

  return (
    <>
      {open && isLoading && <Preloader zIndexOverride={3000} />}
      {open && !isLoading && (
        <Dialog
          maxWidth="sm"
          scroll="paper"
          onClose={() => onClose(false)}
          open={open}
        >
          <DialogTitle>
            {Translate.get("OrderMaUtbildningCardPrint")}
          </DialogTitle>
          <DialogContent
            sx={{ paddingBottom: "24px", minWidth: { sm: "590px" } }}
          >
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <FormControl fullWidth sx={{ marginTop: "10px" }}>
                  <InputLabel id="contact-select-label" required>
                    {Translate.get("Contact")}
                  </InputLabel>
                  <Select
                    required
                    labelId="contact-select-label"
                    id="contact-select"
                    value={selectedContactId ?? ""}
                    label={Translate.get("Contact")}
                    onChange={handleContactSelected}
                    disabled={!contacts || contacts.length === 0}
                  >
                    {contacts &&
                      contacts.map((contact) => (
                        <MenuItem key={contact.actorId} value={contact.actorId}>
                          <div><div>{contact.actorName}</div><div>{contact?.actorEmail ? contact?.actorEmail : ''}</div></div>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  value={customerOrderNo}
                  InputProps={{ spellCheck: false }}
                  onChange={(event) => setCustomerOrderNo(event.target.value)}
                  label={Translate.get("YourOrderNo")}
                  inputProps={{ maxLength: 30 }}
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  value={extraNotification}
                  InputProps={{ spellCheck: false }}
                  onChange={(event) => setExtraNotification(event.target.value)}
                  label={Translate.get("ExtraInvoiceDetails")}
                  inputProps={{ maxLength: 255 }}
                />
              </Grid>
              <Grid item>{/* Empty item for some space */}</Grid>
              <Grid item>
                <FormControl fullWidth>
                  <InputLabel id="address-select-label" required>
                    {Translate.get("Address")}
                  </InputLabel>
                  <Select
                    required
                    sx={{ height: "105px" }}
                    multiline
                    rows={3}
                    labelId="address-select-label"
                    id="address-select"
                    value={useTempAddress ? -1 : selectedAddressId ?? ""}
                    label={Translate.get("Address")}
                    onChange={(event) =>
                      setSelectedAddressId(event.target.value)
                    }
                    disabled={
                      useTempAddress || !addresses || addresses.length === 0
                    }
                  >
                    {!useTempAddress &&
                      addresses &&
                      addresses.map((address) => (
                        <MenuItem
                          divider
                          key={address.actorAddressId}
                          value={address.actorAddressId}
                        >
                          <Grid container>
                            <Grid item xs={9} >
                              {address.actorAddressStreet1}
                            </Grid>
                            <Grid item xs={3}>
                              <span style={{ backgroundColor: "#EDEFF1", padding: "3px", borderRadius: "3px", fontSize: "smaller" }}>{Translate.getActorAddressType(address.actorAddressType)}</span>
                            </Grid>
                            <Grid item xs={12}>{address.actorAddressStreet2}</Grid>
                            <Grid item xs={12}>
                              {address.actorAddressZipCode}{" "}
                              {address.actorAddressCity}
                            </Grid>
                          </Grid>
                        </MenuItem>
                      ))}
                    {useTempAddress && (
                      <MenuItem value={-1}>
                        <Grid container direction="column">
                          <Grid item>{tempAddress.street1}</Grid>
                          <Grid item>{tempAddress.street2}</Grid>
                          <Grid item>
                            {tempAddress.cityCode} {tempAddress.city}
                          </Grid>
                        </Grid>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={useTempAddress}
                      onChange={(event) =>
                        setUseTempAddress(event.target.checked)
                      }
                    />
                  }
                  label={Translate.get("UseTemporaryAddress")}
                />
              </Grid>
              {useTempAddress && (
                <Grid
                  item
                  container
                  spacing={2}
                  direction={{ xs: "column", sm: "row" }}
                >
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      required
                      value={tempAddress.street1 ?? ""}
                      label={Translate.get("Name")}
                      InputProps={{ spellCheck: false }}
                      onChange={(event) =>
                        handleTempAddressChange(event, "street1")
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      required
                      value={tempAddress.street2 ?? ""}
                      label={Translate.get("StreetAddress")}
                      InputProps={{ spellCheck: false }}
                      onChange={(event) =>
                        handleTempAddressChange(event, "street2")
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      required
                      value={tempAddress.cityCode ?? ""}
                      label={Translate.get("ZipCode")}
                      InputProps={{ spellCheck: false }}
                      onChange={(event) =>
                        handleTempAddressChange(event, "cityCode")
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      required
                      value={tempAddress.city ?? ""}
                      label={Translate.get("City")}
                      InputProps={{ spellCheck: false }}
                      onChange={(event) =>
                        handleTempAddressChange(event, "city")
                      }
                    />
                  </Grid>
                  {/* 
                  //Mårten 2023-11-23 tagit bort land eftersom det ställer till problem vid fakturering
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      value={tempAddress.country ?? ""}
                      label={Translate.get("Country")}
                      InputProps={{ spellCheck: false }}
                      onChange={(event) =>
                        handleTempAddressChange(event, "country")
                      }
                    />
                  </Grid> */}
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      value={tempAddress.referenceText ?? ""}
                      label={Translate.get("Attention")}
                      InputProps={{ spellCheck: false }}
                      onChange={(event) =>
                        handleTempAddressChange(event, "referenceText")
                      }
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onClose(false)}>
              {Translate.get("Cancel")}
            </Button>
            <ButtonWithSpinner
              variant="contained"
              disabled={
                !(
                  (selectedAddressId > 0 ||
                    (tempAddress.street1 &&
                      tempAddress.street2 &&
                      tempAddress.cityCode &&
                      tempAddress.city)) &&
                  selectedContactId > 0
                ) || !extraRequestData
              }
              onClick={sendPrintJobToMaSystem}
              endIcon={<ShoppingCart/>}
            >
              {Translate.get("PlaceOrder")}
            </ButtonWithSpinner>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
