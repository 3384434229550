import { useEffect, useState } from "react";

const _defaultPaginationModel = {
  pageSize: 10,
  page: 0,
};

function buildRequest(paginationModel, sortModel, filterModel) {
  return {
    page: paginationModel.page,
    pageSize: paginationModel.pageSize,
    filter: filterModel
      ? {
          items: filterModel.items,
          logicOperator: filterModel.logicOperator,
        }
      : null,
    sort: sortModel
      ? {
          field: sortModel.field,
          direction: sortModel.sort,
        }
      : null,
  };
}

/**
 * The callback that loads the data for usePagination. Will be called each time page, sort or filter changes.
 *
 * @callback loadDataCallback
 * @param {Object} paginationRequest - The request sent to backend, matching ePortWebLibrary/Database/Models/Pagination/PaginationModel.cs
 * @returns {Promise<{rowCount: number, rows: Object[]}>}
 */
/**
 * usePagination will generate props for a DataGrid to make it use server-side pagination.
 * @param {loadDataCallback} loadData - The callback that loads the data to be displayed in the DataGrid
 * @returns {Object} The DataGrid props. Use spread operator into the DataGrid for ex: <DataGrid {...pagination} />
 */
export default function usePagination(loadData, defaultSort) {
  const [rows, setRows] = useState([]);
  const [isInitialLoadDone, setIsInitialLoadDone] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState(
    _defaultPaginationModel
  );
  const [filterModel, setFilerModel] = useState(null);
  const [sortModel, setSortModel] = useState(defaultSort);
  const [rowCount, setRowCount] = useState(0);

  useEffect(() => {
    async function initialLoad() {
      setIsLoading(true);
      const { rows, rowCount } = await loadData(
        buildRequest(_defaultPaginationModel, sortModel, filterModel)
      );
      setRows(rows);
      setRowCount(rowCount);
      setIsLoading(false);
    }
    if (!isInitialLoadDone) {
      initialLoad();
      setIsInitialLoadDone(true);
    }
  }, [loadData, isInitialLoadDone, sortModel, filterModel]);

  async function onFilterChange(filterModel) {
    setIsLoading(true);
    const { rows, rowCount } = await loadData(
      buildRequest(paginationModel, sortModel, filterModel)
    );
    setRows(rows);
    setRowCount(rowCount);
    setFilerModel(filterModel);
    setIsLoading(false);
  }

  async function onSortChange(sortModels) {
    setIsLoading(true);
    const sortModel =
      sortModels && sortModels.length > 0 ? sortModels[0] : null;
    const { rows, rowCount } = await loadData(
      buildRequest(paginationModel, sortModel, filterModel)
    );
    setRows(rows);
    setRowCount(rowCount);
    setSortModel(sortModel);
    setIsLoading(false);
  }

  async function onPaginationChange(paginationModel) {
    setIsLoading(true);
    const { rows, rowCount } = await loadData(
      buildRequest(paginationModel, sortModel, filterModel)
    );
    setRows(rows);
    setRowCount(rowCount);
    setPaginationModel(paginationModel);
    setIsLoading(false);
  }

  return {
    rows: rows,
    pageSizeOptions: [10, 20, 30],
    pagination: true,
    paginationModel: paginationModel,
    filterMode: "server",
    sortingMode: "server",
    paginationMode: "server",
    onFilterModelChange: onFilterChange,
    onSortModelChange: onSortChange,
    onPaginationModelChange: onPaginationChange,
    loading: isLoading,
    rowCount: rowCount,
    localUpdateRows: setRows,
  };
}
