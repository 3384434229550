import * as Yup from "yup";
import { useState, useRef, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import LanguageIcons from "./LanguageIcons";
// material
import Alert from "@mui/material/Alert";
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Button,
  Grow,
  Box,
  Typography,
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { LoadingButton } from "@mui/lab";
import { useApi } from "../../../utils/Api";
import Translate from "../../../utils/Translate";
import Logo from "../../../components/Logo";
import Avatar from "@mui/material/Avatar";
import BusinessIcon from "@mui/icons-material/Business";
import { accessKeys, hasAccess, resetAccess, setAccess } from "../../../utils/userAccess";
import LicenseAgreementApprovalDialog from "./LicenseAgreementApprovalDialog";
import { useSnackbar } from "notistack";
import Preloader from "../../../components/Preloader";
import * as Sentry from "@sentry/react";
import BankIdLoginForm from "./BankIdLoginForm";
import EmailLoginFrom from "./EmailLoginForm";


// ----------------------------------------------------------------------

export default function LoginForm({redirectPath, openBankIdLogin, startQrCodeOnDesktop}) {
  sessionStorage.removeItem("courses");
  const navigate = useNavigate();
  const [showAccounts, setShowAccounts] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(false);
  const [token, setToken] = useState(false);
  const [additionalData, setAdditionalData] = useState({});
  const api = useApi();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  if (selectedUser && !selectedUser.hasUnapprovedLicenseAgreements) {
    resetAccess();
    //console.log("selectedUser", selectedUser);
    //console.log("accountLoginToken", token);
    // (url, data = false, method = 'POST', token = false, json = true) {
    api.fetch(
      `${process.env.REACT_APP_AUTH_URL}auth/login/select-user`,
      selectedUser,
      "POST",
      `Bearer ${token}`
    ).then((data) => {
      if (data.identityToken !== null && data.identityToken?.token) {
        //console.log(data);
        //console.log(`Bearer ${data.identityToken.token}`);
        // sessionStorage.setItem('apiToken', 'Bearer ' + data.identityToken.token);
        sessionStorage.setItem(
          "apiToken",
          `Bearer ${data.identityToken.token}`
        );
        sessionStorage.setItem("apiTokenExpires", data.identityToken.expires);
        //Logout mess -  see DashboardTimer.js
        sessionStorage.setItem("lastConnection", Date.now());
        ////console.log('Timer reset', Date.now());
        //---------------------------------------

        sessionStorage.setItem("apiRefreshToken", data.refreshToken.token);
        sessionStorage.setItem("companyName", selectedUser.companyName);
        Sentry.setUser({
          email: additionalData.email,
          id: selectedUser.ePortUserId,
        });
        setAccess(data.access);
        Translate.loadApp(false).then(() => {
          if(redirectPath) {
            window.location = redirectPath;
          }
          //console.log("redirect start");
         /*  console.log(
            "HAS ACCESS courseAdmin--------------",
            hasAccess(accessKeys.courseAdmin)
          );
          console.log(
            "HAS ACCESS dashboard--------------",
            hasAccess(accessKeys.dashboard)
          ); */
          if (!hasAccess(accessKeys.courseAdmin)) {
            hasAccess(accessKeys.dashboard)
              ? navigate("/dashboard/app", { replace: true })
              : hasAccess(accessKeys.actorPermit) ?
                  navigate("/dashboard/app", { replace: true }) :
                  navigate("/dashboard/licenseregistration", { replace: true });
          } else {
            hasAccess(accessKeys.dashboard)
              ? navigate("/dashboard/app", { replace: true })
              : navigate("/dashboard/courses", { replace: true });
          }
        });
      } else {
        // error
        sessionStorage.removeItem("apiToken");
        const errorMessage = enqueueSnackbar(
          Translate.get(data.errorMessageTranslationKey ?? "SomethingFailed"),
          {
            variant: "error",
            autoHideDuration: 6000,
            onClick: () => closeSnackbar(errorMessage),
          }
        );
        navigate('/', { replace: true });
      }
    });
  }

  const onLoginSuccess = (userFullName, userEmail, authToken, eportUsers) => {
    sessionStorage.setItem("userFullName", userFullName);
    setAdditionalData({ name: userFullName, email: userEmail })
    setToken(authToken);
    if (eportUsers.length > 1) {
      setShowAccounts(true);
      setUsers(eportUsers);
    } else {
      setSelectedUser(eportUsers[0]);
      ////console.log('?');
    }
  }

  if (selectedUser && selectedUser.hasUnapprovedLicenseAgreements) {
    return (
      <LicenseAgreementApprovalDialog
        user={selectedUser}
        accountLoginToken={token}
        open={true}
        onClose={() => setSelectedUser(false)}
        onComplete={() =>
          setSelectedUser({
            ...selectedUser,
            hasUnapprovedLicenseAgreements: false,
          })
        }
      />
    );
  }

  if (showAccounts === true) {
    return (
      <>
       {selectedUser && <Preloader  invisible={true}/>} 
      <div className="loginBox">
        <Box sx={{ mb: 5 }}>
          <Typography variant="h4" gutterBottom>
            {Translate.get("SelectUserCompany")}
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            {Translate.get("MultipleAccountsConnected")}
          </Typography>
        </Box>
        <List>
          {users.map((user, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                className="stdListButton"
                onClick={() => setSelectedUser(user)}
              >
                <ListItemIcon>
                  <Avatar>
                    <BusinessIcon />
                  </Avatar>
                </ListItemIcon>
                <ListItemText
                  primary={user.companyName}
                  secondary={parseInt(user.ePortUserId)}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </div>
      </>
    );
  }

  return (<div className="loginBox">
  <div className="loginLogo">
    <Logo />
  </div>
      {!openBankIdLogin && <EmailLoginFrom onLoginSuccess={onLoginSuccess} />}
      {openBankIdLogin && <BankIdLoginForm onLoginSuccess={onLoginSuccess} startQrCodeOnDesktop={startQrCodeOnDesktop} />}
  </div>);
}