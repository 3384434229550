import { DataGridPremium } from "@mui/x-data-grid-premium";
import { useEffect, useState } from "react";
import Preloader from "../Preloader";
import { dataGridLang } from '../MemToolBar';
import Translate from "../../utils/Translate";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../utils/Api";
import { avatarColumn, textColumn, dateColumn, StatisticsGridToolBar } from "./StatisticsCommon";
import { CustomNoRowsOverlay } from '../../utils/StyledGridOverlay';
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import BuildIcon from "@mui/icons-material/Build";
import PersonIcon from "@mui/icons-material/Person";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PhoneIcon from "@mui/icons-material/Phone";
import NumbersIcon from "@mui/icons-material/Numbers";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import SchoolIcon from "@mui/icons-material/School";
import EmailIcon from "@mui/icons-material/Email";
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DeleteIcon from '@mui/icons-material/Delete';
import { formatDateWithMonthOffSet } from '../../utils/dateTimeFormat';
import Grid from '@mui/material/Grid';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import TagIcon from '@mui/icons-material/Tag';
import { useSnackbar } from 'notistack';
import { useMediaQuery } from '@mui/material';

const ActorLicenseStatistics = () => {

    const colNames = {
        studentName: 'studentName',
        orgNo: 'orgNo',
        studentExternalActorNo: 'studentExternalActorNo',
        studentEmail: 'studentEmail',
        studentMobile: 'studentMobile',
        studentAdr: 'studentAdr',
        studentZipcode: 'studentZipcode',
        studentCity: 'studentCity',
        studentActorPictureUrl: 'studentActorPictureUrl',
        licenseQty: 'licenseQty',
        licenseDesc: 'licenseDesc',
        licenseGroupDesc: 'licenseGroupDesc',
        licenseGroupTheory: 'licenseGroupTheory',
        licenseGroupPractice: 'licenseGroupPractice',
        validFrom: 'validFrom',
        validTo: 'validTo',
        courseCoordinatorName: 'courseCoordinatorName',
        teacher: 'teacher',
        teacherActorId: 'teacherActorId',
        lastPrintdate: 'lastPrintdate',
        numberOfPrints: 'numberOfPrints',
    };

    const columns = [
        avatarColumn(colNames.studentName, null, colNames.studentActorPictureUrl, <PersonIcon />, 'Name'),
        textColumn(colNames.studentEmail, <EmailIcon />, 'Email'),
        textColumn(colNames.orgNo, <FingerprintIcon />, 'PersonalNo'),
        textColumn(colNames.studentExternalActorNo, <TagIcon />, 'EmployeeNo'),
        textColumn(colNames.studentMobile, <PhoneIcon />, 'MobilePhone'),
        textColumn(colNames.studentAdr, null, 'Address'),
        textColumn(colNames.studentZipcode, null, 'ZipCode'),
        textColumn(colNames.studentCity, null, 'City'),
        // column(cols.licenseQty, 'LicenseQty'),
        textColumn(colNames.licenseDesc, <SchoolIcon />, 'LicenceType'),
        textColumn(colNames.licenseGroupDesc, <SchoolIcon />, 'LicenseGroupDesc'),
        textColumn(colNames.licenseGroupTheory, <AutoStoriesIcon />, 'GradeTheoretical'),
        textColumn(colNames.licenseGroupPractice, <BuildIcon />, 'GradePractical'),
        dateColumn(colNames.validFrom, <CalendarTodayIcon />, 'ValidFrom'),
        dateColumn(colNames.validTo, <EventAvailableIcon />, 'ValidTo'),
        // getterColumn(cols.formatValidity, <DateRangeIcon />, 'Validity'),
        textColumn(colNames.courseCoordinatorName, null, 'CourseCoordinator'),
        textColumn(colNames.teacher, <CoPresentIcon />, 'Instructor'),
        textColumn(colNames.teacherActorId, <CoPresentIcon />, 'TeacherActorId'),
        dateColumn(colNames.lastPrintdate, null, 'LastOrdered'),
        textColumn(colNames.numberOfPrints, <NumbersIcon />, 'NumberOfPrints'),
    ];

    const initialHiddenColumns = [
        colNames.orgNo,
        colNames.studentExternalActorNo,
        colNames.studentMobile,
        colNames.studentAdr,
        colNames.studentZipcode,
        colNames.studentCity,
        colNames.courseCoordinatorName,
        colNames.numberOfPrints,
        colNames.lastPrintdate,
        colNames.teacherActorId,
    ];

    const localizedTextsMap = dataGridLang();
    const initialGridState = {
        sorting: { sortModel: [{ field: colNames.validFrom, sort: 'desc' }] },
        columns: {
            columnVisibilityModel: initialHiddenColumns.reduce((ac, a) => ({ ...ac, [a]: false }), {})
        }
    };


    let i = 0;
    const navigate = useNavigate();
    const [licenses, setLicenses] = useState();
    const smallScreen = useMediaQuery('(max-width:700px)');
    const [dataFetched, setDataFetched] = useState(false);
    const api = useApi();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [stdFilters, setStdFilters] = useState(["SelectFilter", "MyCourseParticipants", "MyFilteredInstructorsCps", "LicenseExpires"]);
    const [selectedStdFilter, setSelectedStdFilter] = useState("SelectFilter");
    const [filt, setFilt] = useState({
        items: []
    });

    const [teacherActorIds, setTeacherActorIds] = useState([]);
    const [actorId, setActorId] = useState("");


    useEffect(async () => {
        await loadData();
    }, [api]);

    async function loadData() {
        const [data, filterData] =
            await Promise.all([
                api.fetch(
                    `${process.env.REACT_APP_MAIN_URL}actors/actorlicenses/report`,
                    false,
                    "GET"
                ),
                api.fetch(
                    `${process.env.REACT_APP_MAIN_URL}actors/teachers/filter/actorids`,
                    false,
                    "GET"
                ),
            ]);

        if (data && filterData) {
            let d = data.isSuccessful === false ? [] : data;

            d.forEach((element, index) => {
                element.id = index
            });
            setLicenses(d);

            if (filterData.isSuccessful !== false) {
                setTeacherActorIds(filterData.teacherActorIds);
                if (filterData.teacherActorIds.length < 1) {
                    setStdFilters(stdFilters.filter((i)=>(i !== "MyFilteredInstructorsCps")))
                }
                setActorId(filterData.actorId);
                if (!filterData.actorId) {
                    setStdFilters(stdFilters.filter((i)=>(i !== "MyCourseParticipants")))
                }
            }
        }
        setDataFetched(true);
    }

    const navigateToActor = (actorId) => {
        navigate(`/dashboard/person/${actorId}`, { replace: false });
    };

    function handleStdFilterChange(event) {
        setSelectedStdFilter(event.target.value);
        setStdFilter(event.target.value);
    }

    function setStdFilter(filterName) {
        switch (filterName) {

            case "SelectFilter":
                setFilt({ items: [] });
                break;

            case "MyCourseParticipants":
                setFilt({
                    ...filt,
                    items:
                        [{
                            id: 1,
                            field: "teacherActorId",
                            operator: "equals",
                            value: actorId.toString(),
                            filterName: "MyCourseParticipants"
                        }]
                });
                break;

            case "MyFilteredInstructorsCps":
                if (teacherActorIds.length < 1) {
                    enqueueSnackbar(Translate.get('FiltersMissingOrEmpty'), {
                        variant: 'warning',
                        autoHideDuration: 3000,
                    });

                    setFilt({ items: [] });
                    break;
                }

                setFilt({
                    ...filt,
                    items:
                        [{
                            id: 2,
                            field: "teacherActorId",
                            operator: "isAnyOf",
                            value: teacherActorIds.map(String),
                            filterName: "MyFilteredInstructorsCps"
                        }]
                });
                break;

            case "LicenseExpires":
                setFilt({
                    ...filt,
                    items:
                        [
                            {
                                id: 3,
                                field: "validTo",
                                operator: "onOrAfter",
                                value: formatDateWithMonthOffSet(new Date(), -1),
                                filterName: "LicenseExpires"
                            },
                            {
                                id: 4,
                                field: "validTo",
                                operator: "onOrBefore",
                                value: formatDateWithMonthOffSet(new Date(), 3),
                                filterName: "LicenseExpires"
                            },
                        ]
                });
                break;

            default:
                return;
        }
    }

    function clearFilters() {
        setFilt({ items: [] });
        setSelectedStdFilter("SelectFilter");
    }

    function handleFilterModelChange(newFilterModel, x) {
        if (x.reason === "restoreState") {
            return;
        }

        if (x.reason === "changeLogicOperator") {
            setFilt({
                ...filt,
                logicOperator: newFilterModel.logicOperator
            });
            return;
        }

        const newFilt = { items: newFilterModel.items, logicOperator: newFilterModel.logicOperator };
        setFilt(newFilt);

        var stdFilterExistsInFilters = newFilt.items.findIndex(e => e.filterName === selectedStdFilter)
        if (stdFilterExistsInFilters === -1) {
            setSelectedStdFilter("SelectFilter");
        }
    }


return <div style={{ height: "70vh", /* border:"1px solid blue" */ }}> {/* TODO: är 70vh bra? */}
    {!dataFetched && <Preloader invisible={true} />}
    {dataFetched &&
        <>
            <Grid container justifyContent="flex-start" sx={{ml: 1}}>

                <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{Translate.get('LicenseStatisticsInfo')}</div>}>
                    <IconButton>
                        <InfoIcon color="primary" />
                    </IconButton>
                </Tooltip>

                <FormControl sx={{ minWidth: "5em" }}>
                    <InputLabel id="std-filter">{Translate.get("StdFilter") ?? " "}</InputLabel>
                    <Select
                        sx={{maxWidth: smallScreen ? "10em" : "100%"}}
                        labelId="std-filter"
                        id="std-filter"
                        name="MyCourseParticipantsFilter"
                        value={selectedStdFilter}
                        label={Translate.get("StdFilter") ?? " "}
                        onChange={handleStdFilterChange}
                    >
                        {stdFilters.map((element) =>
                            <MenuItem key={element} value={element} name={element}>{Translate.get(element)}</MenuItem>
                        )}
                    </Select>
                </FormControl>

                <Button
                    onClick={() => clearFilters()}
                    variant="outlined"
                    disabled={filt.items?.length === 0}
                    startIcon={<DeleteIcon />}
                    sx={{ ml: "1em" }}
                    size="xlarge"

                >
                    {Translate.get("Purge")}
                </Button>
            </Grid>

            <DataGridPremium
                getRowId={row => row.id}
                rows={licenses}
                filterModel={filt}
                onFilterModelChange={(newFilterModel, x) => handleFilterModelChange(newFilterModel, x)}
                columns={columns}
                disableColumnMenu={true}
                onRowClick={(params) => navigateToActor(params.row.studentActorId)}
                pageSizeOptions={[10, 50, 100]}
                pagination={true}
                localeText={localizedTextsMap}
                components={{
                    Toolbar: () => StatisticsGridToolBar("actorLicenseStatistics", "Licenses"),
                    NoRowsOverlay: CustomNoRowsOverlay(Translate.get('EmptyHere'))
                }}
                density='comfortable'
                disableDensitySelector={true}
                initialState={initialGridState}
                disableRowSelectionOnClick
            // onExcelExportStateChange={(newState) =>{ 
            //     setDataFetched(newState !== "pending");
            // }}                      
            />
        </>
    }
</div>
};

export default ActorLicenseStatistics;