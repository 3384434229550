import Button from "@mui/material/Button";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Stack,
  useTheme,
  useMediaQuery,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Link,
} from "@mui/material";
import { useState, useCallback, useEffect, Fragment } from "react";
import Translate from "../../utils/Translate";
import { hasSomeAccess, accessKeys } from "../../utils/userAccess";
import AutocompleteSearchField from "../AutocompleteSearchField";
import DomainIcon from "@mui/icons-material/Domain";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ELearningLicenseCount from "../courses/bookings/ELearningLicenseCount";
import { useApi } from "../../utils/Api";
import { useNavigate, useLocation } from "react-router-dom";
import ActorAutocompleteOption from "../ActorAutocompleteOption";
import { actorSearch, actorCompanyTypes } from "../../utils/actorSearch";
import { LoadingButton } from "@mui/lab";
import YesOrNoDialog from "../YesOrNoDialog";
import Preloader from "../Preloader";
import { AlertDialog } from "../AlertDialog";
import { Link as RouterLink } from "react-router-dom";
import { partType } from "../../utils/part";

// TODO
// Eftersom detta är en förenklad order-dialog i dagsläget så finns inte alla
// UI komponenter som behövs när 9999 ska använda den. Följande behöver göras:
//
// 1. När man byter valuta så måste priserna på alla orderLines bytas. Det enklaste hade varit
//    att helt enkelt rensa pcsPrice i orderLines statet, och låta existerande useEffect uppdatera.
//    Men kom ihåg att vi måste kolla om det är tillåtet, baserat på orderstatus. Det kan dessutom
//    finnas orderLines som redan är plockade, medan andra inte är det. Undersök vad som ska göras då.
//
// 2. När man lägger till en orderrad så kan nog existerande useEffect fixa uppdatering av priset.
//    Men beroende på hur det ska funka om man har autoplock, som dom flesta kunder kommer ha,
//    så kanske man behöver kunna urskilja om man bara sparar existerande orderLines när man trycker
//    på spara i dialogen, eller om någon av dom tillagda raderna kommer skickas direkt. Jag har
//    lagt till något preliminärt för detta med kollen av hasAddedOrderLines.
//
// 3. Se även TODO kommentarer i koden nedan gällande ändring av orderLine.qty, samt saveFixedPrice
//    och saveFixedPercent.
export default function OrderForm({ companyActorId, orderId, onClose }) {
  if (companyActorId && orderId) {
    throw new Error("Use either actorId or orderId");
  }

  const [order, setOrder] = useState({ canUpdate: true });
  const [orderLines, setOrderLines] = useState([
    { orderLineId: "dummyId1", canUpdate: true },
  ]);
  // Not really used yet, since we only support one orderLine.
  // And it's mostly relevant for non-9999 users who have auto pick.
  const [hasAddedOrderLines, setHasAddedOrderLines] = useState(false);
  const [company, setCompany] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [currency, setCurrency] = useState(null);
  const [parts, setParts] = useState([]);
  const [availableLicenseCounts, setAvailableLicenseCounts] = useState({});
  const [showOrderQuestion, setShowOrderQuestion] = useState(false);
  const [companyDefaultCustomerOrderNo, setCompanyDefaultCustomerOrderNo] =
    useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [longErrorMessage, setLongErrorMessage] = useState(null);
  const [isServiceAgreement, setIsServiceAgreement] = useState(false);

  const api = useApi();
  const location = useLocation();
  const navigate = useNavigate();
  const closeAndReload = () => {
    //Soft reload with redirect
    const loc = encodeURIComponent(location.pathname);
    navigate(`/redirect/${loc}`, { replace: true });
  };

  const theme = useTheme();
  const isBig = useMediaQuery(theme.breakpoints.up("sm"));

  const handleAvailableLicenseCountChanged = useCallback(
    (newCount, eLearningType) =>
      setAvailableLicenseCounts((prevCounts) => ({
        ...prevCounts,
        [eLearningType]: newCount,
      })),
    []
  );

  const loadActorOrderInfo = useCallback(
    (actorId) => {
      if (actorId <= 0) {
        return;
      }

      api
        .fetch(
          `${process.env.REACT_APP_MAIN_URL}actors/${actorId}/orderinfo`,
          false,
          "GET"
        )
        .then((response) => {
          if (response.isSuccessful) {
            setCurrency(
              (prevCurrency) => prevCurrency ?? response.defaultCurrency
            );
            setCompany((prevCompany) => ({
              ...prevCompany,
              defaultCustomerOrderNoRequired:
                response.defaultCustomerOrderNoRequired,
              defaultCurrency: response.defaultCurrency,
            }));
          }
        });
    },
    [api]
  );

  const loadContacts = useCallback(
    (actorId) => {
      if (actorId <= 0) {
        return;
      }
      api
        .fetchWithOverride(
          `${process.env.REACT_APP_MAIN_URL}actors/clients/${actorId}/contacts`,
          false,
          "GET",
          (response) => response && Array.isArray(response)
        )
        .then((loadedContacts) => {
          if (loadedContacts && Array.isArray(loadedContacts)) {
            setContacts(loadedContacts);
          } else {
            setContacts([]);
          }
        });
    },
    [api]
  );

  const loadCompanyActor = useCallback(
    (actorId) =>
      actorSearch(actorId, actorCompanyTypes, ["ActorId"], 1, true).then(
        (foundCompanies) => {
          if (foundCompanies && foundCompanies.length === 1) {
            setOrder((prevOrder) => ({
              ...prevOrder,
              actorId: foundCompanies[0].actorId,
              customerOrderNo:
                !orderId && !prevOrder.customerOrderNo
                  ? foundCompanies[0].defaultCustomerOrderNo
                  : prevOrder.customerOrderNo,
            }));
            setCompany(foundCompanies[0]);
            setCompanyDefaultCustomerOrderNo(
              foundCompanies[0].defaultCustomerOrderNo
            );
            loadActorOrderInfo(foundCompanies[0].actorId);
            loadContacts(foundCompanies[0].actorId);
          }
          setIsLoading(false);
        }
      ),
    [loadActorOrderInfo, loadContacts, orderId]
  );

  const getPrice = useCallback(
    (orderLineId, partId, qty, actorId) => {
      if (!partId) {
        return;
      }

      const request = {
        partId: partId,
        actorId: actorId,
        qty: qty,
        currency: currency,
      };

      api
        .fetch(
          `${process.env.REACT_APP_MAIN_URL}warehouse/parts/get-price`,
          request,
          "POST"
        )
        .then((response) => {
          if (response.isSuccessful) {
            setOrderLines((prevOrderLines) => {
              const index = prevOrderLines.findIndex(
                (ol) => ol.orderLineId === orderLineId
              );
              if (index !== -1) {
                return prevOrderLines.with(index, {
                  ...prevOrderLines[index],
                  pcsPrice: response.price,
                  discount: response.discount,
                  originalPcsPrice: response.originalPrice,
                  price: response.price * qty,
                });
              } else {
                return prevOrderLines;
              }
            });
          }
        });
    },
    [api, currency]
  );

  useEffect(() => {
    if (companyActorId > 0) {
      loadCompanyActor(companyActorId);
    } else if (orderId > 0) {
      api
        .fetch(
          `${process.env.REACT_APP_MAIN_URL}orders/${orderId}`,
          false,
          "GET"
        )
        .then((response) => {
          if (response.isSuccessful && response.orderLines.length > 1) {
            throw new Error("Multiple order lines not yet supported");
          }

          if (response.isSuccessful) {
            setOrder(response);
            setOrderLines(response.orderLines);
            const orderLine = response.orderLines[0];

            // The list of parts are loaded separately. If they're already loaded
            // we don't change anything here, otherwise add a list with the current part
            setParts((prevParts) =>
              prevParts && prevParts.length > 0 ? prevParts : [orderLine.part]
            );

            setContacts((prevContacts) =>
              response.actorRefId > 0 &&
              !prevContacts.find((c) => c.actorId === response.actorRefId)
                ? [
                    ...prevContacts,
                    {
                      actorName: response.actorRefName,
                      actorId: response.actorRefId,
                    },
                  ]
                : prevContacts
            );
            setCurrency(response.currency);
            loadCompanyActor(response.actorId);
          }
          setIsLoading(false);
        });
    } else {
      // No loading needed
      setIsLoading(false);
    }
  }, [companyActorId, orderId, loadCompanyActor, api]);

  useEffect(() => {
    async function loadParts() {
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}warehouse/parts`,
        false,
        "GET"
      );
      if (response && response.isSuccessful) {
        setParts(response.parts);

        // Set the first and only part as selected when creating a new order
        if (response.parts.length === 1 && !orderId) {
          const part = response.parts[0];
          setOrderLines((prevLines) =>
            prevLines.length === 1
              ? [{ ...prevLines[0], partId: part.partId }]
              : prevLines
          );
        }
      }
    }
    loadParts();
  }, [api, orderId]);

  useEffect(() => {
    const orderLinesWithoutPrice = orderLines.filter(
      (ol) => ol.partId && ol.qty && ol.pcsPrice !== 0 && !ol.pcsPrice
    );
    for (const orderLine of orderLinesWithoutPrice) {
      getPrice(
        orderLine.orderLineId,
        orderLine.partId,
        orderLine.qty,
        company?.actorId ?? 0
      );
    }
    setIsServiceAgreement(
      orderLines.some((ol) => ol.part?.partType === partType.serviceAgreement)
    );
  }, [api, orderLines, company, getPrice]);

  async function addOrUpdateOrder() {
    const request = {
      orderId: order.orderId,
      actorId: company.actorId,
      actorRefId: order.actorRefId ?? null,
      customerOrderNo: order.customerOrderNo,
      version: order.version,
      orderLines: orderLines.map((ol) => ({
        // We use IDs like "dummyId1" here in frontend for new orderLines
        orderLineId: typeof ol.orderLineId === "number" ? ol.orderLineId : 0,
        partId: ol.partId,
        qty: ol.qty,
        pcsPrice: ol.pcsPrice,
        originalPcsPrice: ol.originalPcsPrice,
        discount: ol.discount,
        version: ol.version,
        // TODO saveFixedPrice om man redigerat priset manuellt och svarat JA att man vill spara
        // TODO saveFixedPercent om man redigerat discount manuellt och svarat JA att man vill spara
      })),
    };

    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}orders`,
      request,
      "POST"
    );

    if (response.isSuccessful) {
      closeAndReload();
    } else if (!orderId && !!response.orderId) {
      // If we are here it means the order was already created, but something went wrong.
      // And it couldn't be automatically deleted. Probably because it was autopicked already.
      if (!response.errorMessageTranslationKey.startsWith("Something")) {
        setLongErrorMessage(response.errorMessageTranslationKey);
      } else {
        closeAndReload();
      }
    } else {
      closeAndReload();
    }
  }

  async function handleSaveOrSendOrder() {
    if (orderId && !hasAddedOrderLines) {
      await addOrUpdateOrder();
    } else {
      setShowOrderQuestion(true);
    }
  }

  const companySearch = useCallback(async (searchText) => {
    const foundCompanies = await actorSearch(
      searchText,
      actorCompanyTypes,
      ["ActorName"],
      150
    );
    if (foundCompanies) {
      return foundCompanies.map((company) => ({
        actorId: company.actorId,
        externalDataId: company.externalDataId,
        defaultCustomerOrderNo: company.defaultCustomerOrderNo,
        actorName: company.actorName,
        orgNo: company.orgNo,
        email: company.email,
        addressStreet: company.postalAddress?.actorAddressStreet2,
        addressZipCode: company.postalAddress?.actorAddressZipCode,
        addressCity: company.postalAddress?.actorAddressCity,
      }));
    }
  }, []);

  async function handleCompanySelected(newSelectedCompany) {
    setOrder((prevOrder) => ({
      ...prevOrder,
      actorId: newSelectedCompany?.actorId,
      actorRefId: null,
      customerOrderNo: newSelectedCompany?.defaultCustomerOrderNo,
    }));
    setCompany(newSelectedCompany);
    setCompanyDefaultCustomerOrderNo(
      newSelectedCompany?.defaultCustomerOrderNo
    );
    loadActorOrderInfo(newSelectedCompany?.actorId ?? 0);
    loadContacts(newSelectedCompany?.actorId ?? 0);
    var orderLinesWithItems = orderLines.filter((ol) => ol.partId);
    for (const orderLine of orderLinesWithItems) {
      getPrice(
        orderLine.orderLineId,
        orderLine.partId,
        orderLine.qty,
        newSelectedCompany?.actorId ?? 0
      );
    }
  }

  function handlePartSelected(orderLineId, event) {
    const index = orderLines.findIndex((ol) => ol.orderLineId === orderLineId);
    if (index !== -1) {
      const partId = event.target.value ? Number(event.target.value) : 0;
      setOrderLines(
        orderLines.with(index, { ...orderLines[index], partId: partId })
      );
      getPrice(
        orderLineId,
        partId,
        orderLines[index].qty,
        company?.actorId ?? 0
      );
    }
  }

  function handleQtyChanged(orderLineId, event) {
    const index = orderLines.findIndex((ol) => ol.orderLineId === orderLineId);
    if (index !== -1) {
      const qty = event.target.value ? Number(event.target.value) : 0;
      setOrderLines(orderLines.with(index, { ...orderLines[index], qty: qty }));
      // TODO När vi har möjlighet att ändra pris, så måste vi hålla koll på om man har ändrat det manuellt.
      // I så fall vill vi ju inte anropa getPrice, eftersom den då ersätter det satta priset. Då vill vi
      // nog istället behålla det satta priset, och bara multiplicera det med nya qty för att räkna ut totalpriset.
      // Samma sak gäller om man satt discount manuellt. Kom ihåg att det finns stöd för kampanjpris, baserat
      // på hur många man beställer. Osäker på hur man kan ta hänsyn till både och samtidigt. Tror inte det
      // går, och det är därför jag här i denna kommentaren antagit att man då vill behålla det manuellt satta priset.
      getPrice(
        orderLineId,
        orderLines[index].partId,
        qty,
        company?.actorId ?? 0
      );
    }
  }

  function getPart(partId) {
    return parts.find((p) => p.partId === partId);
  }

  function getLearningType(partId) {
    let part = getPart(partId);
    if (part.eLearningType != null) {
      return part.eLearningType;
    }

    if (part.partType === 23) {
      return 1000;
    }

    return null;
  }

  function isELearningLicense(partId) {
    if (!partId) {
      return false;
    }

    let part = getPart(partId);
    if (part == null) {
      return false;
    }

    if (part.eLearningCode != null || part.partType === 23) {
      return true;
    }

    return false;
  }

  function getAvailableLicenseCount(partId) {
    const eLearningType = getLearningType(partId);
    return eLearningType ? availableLicenseCounts[eLearningType] : null;
  }

  function handleContactSelected(event) {
    const actorId = event.target.value;

    // Only update customerOrderNo automatically if it's a new order, or if it's empty
    if (!order.orderId || (order.canUpdate && !order.customerOrderNo)) {
      if (order.actorRefId > 0) {
        const previousContact = contacts.find(
          (c) => c.actorId === order.actorRefId
        );
        // Reset to company customerOrderNo if deselecting a contact
        if (previousContact.defaultCustomerOrderNo === order.customerOrderNo) {
          setOrder((prevOrder) => ({
            ...prevOrder,
            customerOrderNo: companyDefaultCustomerOrderNo,
          }));
        }
      }

      const contact = contacts.find((c) => c.actorId === actorId);
      if (contact.defaultCustomerOrderNo) {
        setOrder((prevOrder) => ({
          ...prevOrder,
          customerOrderNo: contact.defaultCustomerOrderNo,
        }));
      }
    }

    setOrder((prevOrder) => ({ ...prevOrder, actorRefId: actorId }));
  }

  function isExternalDataIdRequired() {
    return orderLines.some((ol) => isELearningLicense(ol.partId));
  }

  if (isLoading) {
    return <Preloader />;
  }

  return (
    <>
      <DialogTitle>
        {orderId ? Translate.get("EditOrder") : Translate.get("CreateOrder")}
      </DialogTitle>
      <DialogContent>
        <Stack
          sx={{
            paddingTop: "16px",
          }}
          spacing={4}
        >
          <div>{Translate.get("CreateOrderInfo")}</div>
          <Stack direction="row" spacing={1} className="stdFlexLeft">
            <DomainIcon sx={{ marginLeft: "9px" }} />
            <AutocompleteSearchField
              disabled={companyActorId > 0 || order.orderId > 0}
              label={Translate.get("Company")}
              value={company ?? ""}
              onValueChange={handleCompanySelected}
              getOptionLabel={(option) => option?.actorName ?? ""}
              renderOption={(option) => (
                <ActorAutocompleteOption
                  actorName={option?.actorName}
                  orgNo={option?.orgNo}
                  addressStreet={option?.addressStreet}
                  addressZipCode={option?.addressZipCode}
                  addressCity={option?.addressCity}
                />
              )}
              keyPropName="actorId"
              requireSelection={true}
              search={companySearch}
              createNewValue={(text) => ({
                actorName: text,
              })}
              minWidth={isBig ? "496px" : "250px"}
              textFieldProps={{
                required: true,
                error:
                  !!company &&
                  isExternalDataIdRequired() &&
                  !company?.externalDataId,
                helperText:
                  !!company &&
                  isExternalDataIdRequired() &&
                  !company?.externalDataId
                    ? Translate.get("CompanyHasNoDataId")
                    : "",
              }}
            />
          </Stack>
          <Stack direction="row" spacing={1} className="stdFlexLeft">
            <AccountCircleIcon sx={{ marginLeft: "9px" }} />
            <FormControl fullWidth sx={{ marginTop: "10px" }}>
              <InputLabel id="contact-select-label">
                {Translate.get("Contact")}
              </InputLabel>
              <Select
                disabled={
                  !order.canUpdate ||
                  !contacts ||
                  contacts.length === 0 ||
                  isServiceAgreement
                }
                labelId="contact-select-label"
                id="contact-select"
                value={order?.actorRefId ?? ""}
                label={Translate.get("Contact")}
                onChange={handleContactSelected}
              >
                {contacts &&
                  contacts.map((contact) => (
                    <MenuItem key={contact.actorId} value={contact.actorId}>
                      {contact.actorName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack direction="row" spacing={1} className="stdFlexLeft">
            <ListAltIcon sx={{ marginLeft: "9px" }} />
            <TextField
              disabled={!order.canUpdate}
              // Can't require, because some clients need an order confirmation before they can provide order number
              // required={company?.defaultCustomerOrderNoRequired}
              fullWidth
              value={order?.customerOrderNo ?? ""}
              InputProps={{ spellCheck: false }}
              onChange={(event) =>
                setOrder((prevOrder) => ({
                  ...prevOrder,
                  customerOrderNo: event.target.value,
                }))
              }
              label={Translate.get("YourOrderNo")}
              inputProps={{ maxLength: 30 }}
            />
          </Stack>
          {/* We don't support more than one orderLine right now, and there is no way to add more lines. But this dialog
              is still written with the intention of supporting more lines in the future, at least for 9999 */}
          {orderLines.map((ol) => (
            <Fragment key={ol.orderLineId}>
              <Stack direction="row" spacing={1} className="stdFlexLeft">
                <ShoppingBasketIcon sx={{ marginLeft: "9px" }} />
                <FormControl fullWidth>
                  <InputLabel id="part-select-label" required>
                    {Translate.get("Product")}
                  </InputLabel>
                  <Select
                    disabled={!ol.canUpdate || isServiceAgreement}
                    required
                    labelId="part-select-label"
                    id="part-select"
                    label={Translate.get("Product")}
                    value={ol.partId ?? ""}
                    onChange={(event) =>
                      handlePartSelected(ol.orderLineId, event)
                    }
                  >
                    {parts.map((x) => (
                      <MenuItem
                        key={x.partId}
                        value={x.partId}
                        disabled={!x.canAddToOrder}
                      >
                        {x.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              <Stack direction="row" spacing={1} className="stdFlexLeft">
                <ScatterPlotIcon sx={{ marginLeft: "9px" }} />
                <TextField
                  disabled={!ol.canUpdate || isServiceAgreement}
                  required
                  label={Translate.get("Quantity")}
                  sx={{
                    minWidth: isBig ? "120px" : "250px",
                    width: "100%",
                  }}
                  value={isNaN(ol.qty) ? "" : ol.qty}
                  onChange={(event) => handleQtyChanged(ol.orderLineId, event)}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    type: "number",
                  }}
                  error={
                    isELearningLicense(ol.partId) &&
                    ol.qty > getAvailableLicenseCount(ol.partId)
                  }
                />
                {isELearningLicense(ol.partId) && isBig && (
                  <ELearningLicenseCount
                    eLearningType={getLearningType(ol.partId)}
                    onAvailableLicenseCountChanged={
                      handleAvailableLicenseCountChanged
                    }
                  />
                )}
              </Stack>
              {isELearningLicense(ol.partId) && !isBig && (
                <Stack direction="row" spacing={1} className="stdFlexLeft">
                  <ELearningLicenseCount
                    eLearningType={getLearningType(ol.partId)}
                    onAvailableLicenseCountChanged={
                      handleAvailableLicenseCountChanged
                    }
                  />
                </Stack>
              )}
            </Fragment>
          ))}
        </Stack>
        {orderLines.some((ol) => isELearningLicense(ol.partId)) && (
          <>
            <Typography
              variant="body2"
              sx={{
                margin: "33px 0 20px",
                color: "text.secondary",
              }}
            >
              {Translate.get("CreateOrderELearningInfo")}
            </Typography>
            <Link to="/dashboard/licenses" component={RouterLink}>
              {Translate.get("ClickHereToOrderELearning")}
            </Link>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose ?? closeAndReload}>
          {Translate.get("Cancel")}
        </Button>
        <LoadingButton
          variant="contained"
          onClick={handleSaveOrSendOrder}
          disabled={
            orderLines.some(
              (ol) =>
                // !ol.canUpdate || <- Can't block whole order because of this
                !ol.partId ||
                !ol.qty ||
                (isELearningLicense(ol.partId) &&
                  ol.qty > getAvailableLicenseCount(ol.partId))
            ) ||
            // Can't require, because some clients need an order confirmation before they can provide order number
            // (company?.defaultCustomerOrderNoRequired &&
            //   !order?.customerOrderNo) ||
            !company ||
            (isExternalDataIdRequired() && !company.externalDataId) ||
            !order.canUpdate
          }
        >
          {orderId ? Translate.get("Save") : Translate.get("CreateOrder")}
        </LoadingButton>
      </DialogActions>
      <YesOrNoDialog
        open={showOrderQuestion}
        title={Translate.get("CreateOrder")}
        text={Translate.get("SureSendOrder")}
        onNo={() => setShowOrderQuestion(false)}
        onYes={addOrUpdateOrder}
        noText={Translate.get("Cancel")}
        yesText={Translate.get("CreateOrder")}
      />
      <AlertDialog
        titleText={Translate.get("SomethingFailed")}
        bodyText={Translate.get(longErrorMessage)}
        buttonText={Translate.get("Ok")}
        open={!!longErrorMessage}
        onClose={() => closeAndReload()}
      />
    </>
  );
}
