import { Icon } from '@iconify/react';
import { useRef, useState, useEffect } from 'react';
import personFill from '@iconify/icons-eva/person-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import { Box, Divider, MenuItem, Typography, Avatar, IconButton } from '@mui/material';
import MenuPopover from '../../components/MenuPopover';
import Translate from '../../utils/Translate';
import { useApi } from '../../utils/Api'
import LoadingButton from '@mui/lab/LoadingButton';
import LanguagePopover from './LanguagePopover';

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [profile, setProfile] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const api = useApi();

  const MENU_OPTIONS = [
    {
      label: Translate.get('Profile'),
      icon: personFill,
      linkTo: 'myprofilesettings'
    }
  ];

  async function loadUserProfile() {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}profiles`,
      false,
      "GET"
    );
    if (response) {
      setProfile(response);
    }
  }

  useEffect(() => loadUserProfile(), []);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  async function logOut() {
    await sendLogout(sessionStorage.getItem("apiRefreshToken"));
    sessionStorage.clear();
    window.location = '/?logout=1';
  }

  async function sendLogout(apiRefreshToken) {
    setIsLoading(true)
    let reqBody = { RefreshToken : { Token: apiRefreshToken}}
    await api.fetch(
        `${process.env.REACT_APP_AUTH_URL}auth/logout`,
        reqBody,
        'POST',
        false,
        true,
    ).then((data) => {
        if (!data.success) {
            // TODO Loggning
        }
    }).finally(f => {
       setIsLoading(false); 
    })
}

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar src={profile.actor?.profilePictureUrl} alt={profile.name ?? "N/A"} />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {profile.name ?? "N/A"}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {profile.email ?? "N/A"}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}
        <LanguagePopover isPreLogin={false} />
        <Box sx={{ p: 2, pt: 1.5 }}>
          <LoadingButton fullWidth color="inherit" variant="outlined" onClick={logOut} loading={isLoading}>
            {Translate.get('LogOut')}
          </LoadingButton>
        </Box>
      </MenuPopover>
    </>
  );
}
