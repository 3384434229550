import {
  Checkbox,
  IconButton,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useState } from "react";
import "./ActorLicensePrint.css";
import SearchIcon from "@mui/icons-material/Search";
import ActorAvatar from "../ActorAvatar";
import ActorLicensePrintPreviewDialog from "./ActorLicensePrintPreviewDialog";

export default function ActorLicensePrintList({
  studentDetails,
  selectedActorLicenseIds,
  onSelect,
  disableSelection,
  printTemplate,
  sidePrintMode,
  printType,
}) {
  const [showPreviewDialogId, setShowPreviewDialogId] = useState(false);
  studentDetails.sort((a, b) => a.actorName.localeCompare(b.actorName));
  return (
    <>
      {!!showPreviewDialogId && (
        <ActorLicensePrintPreviewDialog
          printTemplate={printTemplate}
          studentDetails={studentDetails.find(
            (s) => s.actorLicenseId === showPreviewDialogId
          )}
          printFront={sidePrintMode === "both" || sidePrintMode === "front"}
          printBack={sidePrintMode === "both" || sidePrintMode === "back"}
          open={!!showPreviewDialogId}
          onClose={() => setShowPreviewDialogId(null)}
          printType={printType}
        />
      )}
      <TableContainer className="licensePrintStudentList">
        <Table>
          <TableBody>
            {studentDetails.map((s) => (
              <TableRow key={s.actorLicenseId}>
                {!disableSelection && (
                  <TableCell className="shrink" align="left">
                    <Checkbox
                      checked={
                        !!selectedActorLicenseIds.find(
                          (c) => c === s.actorLicenseId
                        )
                      }
                      onChange={() => onSelect(s.actorLicenseId)}
                    />
                  </TableCell>
                )}
                <TableCell align="left" className="hideOverflow">
                  <ActorAvatar
                    name={s.actorName}
                    email={s.actorEmail}
                    profilePictureUrl={s.actorImage}
                  />
                </TableCell>
                <TableCell className="shrink" align="right">
                  <IconButton
                    onClick={() => setShowPreviewDialogId(s.actorLicenseId)}
                  >
                    <SearchIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
