import React, { useEffect, useState, useCallback } from 'react';
import { useApi } from '../../utils/Api';
import Paper from '@mui/material/Paper';
import Preloader from '../Preloader';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import Translate from "../../utils/Translate";
import { useMediaQuery } from "@mui/material";

export function ChooseCourseBooking({
  qrToken,
  id,
  setToken,
  setId,
  setFields,
  setActorId,
  setOrgNoFormat,
  setMode,
  setCourseBookingId}) {

  const [isLoading, setIsLoading] = useState(false);
  const [courseBookings, setCourseBookings] = useState(null);
  const [selectedCb, setSelectedCb] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [dataFetched, setDataFetched] = useState(true);
  const api = useApi();

  const smallScreen = useMediaQuery("(max-width:900px)");

  const reserveSeat = useCallback((token, id, courseBookingId) => {
    setIsLoading(true);

    let reqObj = {
      id: id,
      courseBookingId: courseBookingId
    }

    api.fetchWithOverride(
      `${process.env.REACT_APP_MAIN_URL}self/reserve`,
      reqObj,
      'POST',
      null,
      (response) => setErrorMessage(response.errorMessageTranslationKey),
      null,
      null,
      token
    )
      .then((response) => {
        if (response.isSuccessful) {
          setFields(response.fields);
          setActorId(response.actorId);
          setOrgNoFormat(response.orgNoFormat);

          sessionStorage.setItem("selfRegReservationToken", response.reservationToken);

          setIsLoading(false);

          if (showBankIdAuthentication(response)) {
            setMode("BankId");
          } else {
            setMode("Form");
          }
          
        } else {
          setIsLoading(false);
        }
      });
  }, [setFields, setActorId, setOrgNoFormat, setMode, api]);

  useEffect(() => {
    async function effect() {
      setDataFetched(false);
      const lang = Translate.getBrowserLanguage();
      Translate.loadSelfReg(lang).then((data) => {
        setDataFetched(true);
      });
      
      const pathId = getPathParameter();
      const token = await getQrToken(pathId);
      setToken(token);
      setId(pathId);
    }

    if (qrToken === null) {
      // Man har kommit hit med id:t i path:en.
      effect();
    }    
  }, [qrToken, id, setToken, setId]);

  useEffect(() => {
    async function effect() {
      let cbs = await getCourseBookings(qrToken, id);
      setCourseBookings(cbs);

      if (cbs?.length === 1) {
        setCourseBookingId(cbs[0].courseBookingId);
        setSelectedCb(cbs[0].courseBookingId);
        reserveSeat(qrToken, id, cbs[0].courseBookingId);
      }
    }

    if (qrToken !== null) {
      effect();
    } 
  }, [qrToken, id, setCourseBookingId, reserveSeat]);

  async function getCourseBookings(qrToken, id) {
    if (id == null) {return}
    setIsLoading(true);

    let response = null;
    try {
      response = await api.fetchWithOverride(
        `${process.env.REACT_APP_MAIN_URL}self/coursebookings/${id}`,
        false,
        'GET',
        null,
        (response) => setErrorMessage(response.errorMessageTranslationKey),
        null,
        null,
        qrToken
      );
      setIsLoading(false);
    } catch (error) {
      setErrorMessage("SomethingFailed");
      setIsLoading(false);
    }
    finally {
      setIsLoading(false);
    }
    return response.courseBookings;
  }

  async function getQrToken(id) {
    setIsLoading(true);

    let response = null;
    try {
      response = await api.fetchWithOverride(
        `${process.env.REACT_APP_AUTH_URL}auth/login-from-qr-code/${id}`,
        false,
        'GET',
        null,
        (response) => setErrorMessage(response.errorMessageTranslationKey)
      );
      setIsLoading(false);
    } catch (error) {
      setErrorMessage("SomethingFailed");
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
    return response.token;
  }

  function getPathParameter() {
    let path = window.location.pathname;
    let arr = path.split("/");
    return arr[2];
  }

  function handleChange(event) {
    setSelectedCb(event.target.value);
    setCourseBookingId(event.target.value);
    setErrorMessage("");
  }

  function showBankIdAuthentication(data) {
    if (!data || !data.fields) return false;
    
    return data.fields.some(field => field.fieldName === "BankId" && field.markedAsVisible === true);
  }

  return (
    <>
    {(!dataFetched || isLoading) && <Preloader />}
    <div className="stdPage" style={{ display: 'flex', justifyContent: 'center', padding: '1em', paddingLeft: 0.1, paddingRight: 0.1 }}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh', minWidth: "95%" }}
      >
        <Grid item xs={3}>

          <Paper elevation={3} sx={{ p: 8, width: smallScreen ? "95vw" : 600}}>
            <img src="/instructor_logo.png" alt="ePort-logotype" className="maxWidthImageSelf" />

            <p>{Translate.get("ChooseBooking")}</p>

            <Select
              value={selectedCb}
              onChange={handleChange}
              sx={{ minWidth: '100%', maxWidth: '100%', mt: '1em' }}
            >
              {courseBookings?.map(item => (
                <MenuItem value={item.courseBookingId} key={item.courseBookingId}>{item.name}</MenuItem>
              ))}
            </Select>
            {errorMessage !== "" && <Alert severity="error" sx={{ mt: 1, minWidth: '100%', maxWidth: '100%' }}>{Translate.get(errorMessage)}</Alert>}

            <aside className='MuiCallout-root MuiCallout-warning'> {Translate.get("CompletedWithinSession")}</aside>

            <br/>
            <Button variant='outlined' disabled={selectedCb === ""} onClick={() => reserveSeat(qrToken, id, selectedCb)} sx={{ mt: 1 }}>{Translate.get("Continue")}</Button>
          </Paper>
        </Grid>
      </Grid>
    </div>
    </>
  );
}
