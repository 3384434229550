import { useEffect, useState, useRef } from "react";
import {
  Card,
  TextField,
  FormControl,
  useMediaQuery,
  Button,
} from "@mui/material";
import Translate from "../utils/Translate";
import Companies from "../components/actors/Companies";
import Persons from "../components/actors/Persons";
import Preloader from "../components/Preloader";
import BusinessIcon from "@mui/icons-material/Business";
import PersonIcon from "@mui/icons-material/Person";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

import SpeedMenu from "../components/SpeedMenu";
import PersonCrud from "../components/actors/crud/PersonCrud";
import CompanyCrud from "../components/actors/crud/CompanyCrud";
import DuplicateActorMerger from "../components/actors/duplicates/DuplicateActorMerger";
import {
  actorSearch,
  actorCompanyTypes,
  actorPersonTypes,
  actorTypes,
} from "../utils/actorSearch";
import { hasSomeAccess, accessKeys } from "../utils/userAccess";
import SearchIcon from "@mui/icons-material/Search";

const filterableFields = ["actorId", "dataId", "parentActorId"];

const getSpeedMenu = (props) => {
  const menudata = [
    ...(hasSomeAccess(accessKeys.standardPackage, accessKeys.advancedPackage)
      ? [
          {
            icon: <PeopleAltIcon />,
            text: Translate.get("ManageDuplicates"),
            route: false,
            dialogHeader: Translate.get("MergeDuplicates"),
            component: <DuplicateActorMerger {...props} />,
          },
        ]
      : []),
    {
      icon: <BusinessIcon />,
      text: Translate.get("AddCompany"),
      component: <CompanyCrud {...props} isEditOnly />,
    },
    {
      icon: <PersonIcon />,
      text: Translate.get("AddPerson"),
      route: false,
      component: <PersonCrud {...props} isEditOnly />,
    },
  ];
  return menudata;
};

const SearchField = ({ inputRef }) => {
  const [query, setQuery] = useState("");

  return (
    <TextField
      inputRef={inputRef}
      inputProps={{ spellCheck: false }}
      fullWidth
      id="outlined-basic"
      label={Translate.get("SearchNameOrCompany")}
      variant="outlined"
      name="searchString"
      value={query}
      onChange={(evt) => setQuery(evt.target.value)}
    />
  );
};

const formatSearchedPersons = (data) => {
  // Filtering out some actorGroups here instead of backend, because in other
  // cases we might still want these connections from actor search, and we
  // still need to include them in the search, to be able to find all actors.
  return data
    .filter((x) => !x.actorTypes.includes(1))
    .map((x) => ({
      ...x,
      actorTypes: x.actorTypes.filter((a) => a === 3 || a === 4 || a === 6),
      parentActors: x.parentActors.filter(
        (a) =>
          (a.actorType === 3 || a.actorType === 4 || a.actorType === 6) &&
          a.parentActor.actorId > 0 &&
          a.parentActor.actorId !== x.actorId &&
          a.parentActor.actorName !== "ePortMaster"
      ),
    }));
};

const Comp = (props) => {
  const smallScreen = useMediaQuery("(max-width:700px)");
  const [rows, setData] = useState([]);
  const [rowsC, setDataC] = useState([]);
  const [dataFetched, setDataFetched] = useState(true);
  const [search, setSearch] = useState(false);
  const searchFieldRef = useRef();

  const searchActors = async (searchStr, searchId) => {
    setSearch(true);
    const { newSearchStr, filters } = extractFilters(searchStr);
    const actorId = !!searchId
      ? searchId
      : "actorId" in filters
      ? filters.actorId
      : null;
    let personsSearch;
    let companySearch;
    if (actorId) {
      window.global.dataGrid.actorIdSearch = actorId;
      window.global.dataGrid.actorSearch = null;
      personsSearch = actorSearch(
        actorId,
        actorPersonTypes,
        ["ActorId"],
        1,
        true
      );
      companySearch = actorSearch(
        actorId,
        actorCompanyTypes,
        ["ActorId"],
        1,
        true
      );
    } else {
      window.global.dataGrid.actorIdSearch = null;
      window.global.dataGrid.actorSearch = searchStr;
      personsSearch = actorSearch(
        newSearchStr,
        actorPersonTypes,
        [
          "ActorName",
          "OrgNo",
          "ActorEmail",
          "ParentActorName",
          "ExternalActorNo",
          "PostalAddressCity",
        ],
        500,
        false,
        filters
      );
      companySearch = actorSearch(
        newSearchStr,
        actorCompanyTypes,
        [
          "ActorName",
          "OrgNo",
          "ActorEmail",
          "ExternalActorNo",
          "PostalAddressCity",
        ],
        500,
        false,
        filters
      );
    }

    setDataFetched(false);
    const personResult = await personsSearch;
    const companyResult = await companySearch;

    setData(personResult ? formatSearchedPersons(personResult) : []);
    setDataC(companyResult ? companyResult : []);
    setDataFetched(true);
  };

  if (
    !search &&
    (window.global.dataGrid.actorSearch || window.global.dataGrid.actorIdSearch)
  ) {
    searchActors(
      window.global.dataGrid.actorSearch,
      window.global.dataGrid.actorIdSearch
    );
  }

  const handleActorCreated = (actor) => {
    // Because of the page reload after a dialog closes, the state "search" will be re-initialized
    // to false. So searchActors will be called above then. We just need to set the ID here.
    window.global.dataGrid.actorIdSearch = actor.actorId;
  };

  //Form control
  const handleSubmit = (event) => {
    window.global.dataGrid.Companies = false;
    window.global.dataGrid.Users = false;
    searchActors(searchFieldRef.current.value, null);
    event.preventDefault();
  };
  //Form control

  useEffect(() => {
    // autofocus property on textfield did not work, what to do ;)
    const timer = setTimeout(() => searchFieldRef.current.focus(), 300);
    return () => clearTimeout(timer);
  }, []);

  function extractFilters(searchStr) {
    if (!searchStr) {
      return { newSearchStr: searchStr, filters: {} };
    }

    const words = searchStr.split(" ");
    const newSearchStr = words
      .filter(
        (w) => !w.includes(":") || !filterableFields.includes(w.split(":")[0])
      )
      .join(" ");
    const filters = Object.fromEntries(
      words
        .filter(
          (w) => w.includes(":") && filterableFields.includes(w.split(":")[0])
        )
        .map((f) => f.split(":"))
    );
    return { newSearchStr, filters };
  }

  return (
    <>
      {!dataFetched && <Preloader />}
      <div className="stdFlexRowTop">
        <h2>{Translate.get("Register")}</h2>
        <FormControl sx={{ marginLeft: "8px" }} fullWidth>
          <form className="actorsSearchForm" onSubmit={handleSubmit}>
            <SearchField inputRef={searchFieldRef} />
            {!smallScreen && (
              <Button
                type="submit"
                className="actorsSearchButton"
                variant="contained"
                endIcon={<SearchIcon />}
              >
                {Translate.get("Search")}
              </Button>
            )}
          </form>
        </FormControl>
      </div>
      <div className="stdFlexRow">
        <h2>{Translate.get("Persons")}</h2>
      </div>
      <Card>
        <Persons
          rows={rows}
          smallScreen={smallScreen}
          noRowsTransKey={Translate.get("EmptyHereRegister")}
        />
      </Card>
      <div className="stdFlexRow">
        <h2>{Translate.get("Company")}</h2>
      </div>
      <Card>
        <Companies
          rows={rowsC}
          smallScreen={smallScreen}
          noRowsTransKey={Translate.get("EmptyHereRegister")}
        />
      </Card>
      <SpeedMenu
        content={getSpeedMenu({
          actorId: 0,
          onActorSaved: handleActorCreated,
        })}
      />
    </>
  );
};
export default Comp;
