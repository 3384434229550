/* xeslint-disable */
import { useState, useEffect, useMemo } from "react";

// material
import { Card, Grid, Tooltip, useMediaQuery } from "@mui/material";
import FlagIcon from "@mui/icons-material/Flag";
import AddIcon from "@mui/icons-material/Add";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import BuildIcon from "@mui/icons-material/Build";
import PlaceIcon from "@mui/icons-material/Place";
import EmailIcon from "@mui/icons-material/Email";
import BadgeIcon from "@mui/icons-material/Badge";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Companies from "./Companies";
import CourseGrid from "../courses/CourseGrid";
import Preloader from "../Preloader";
import Licences from "./Licenses";
import Permits from "./Permits";
import Competences from "./Competences";
import Translate from "../../utils/Translate";
import { useParams } from "react-router-dom";
import Api, { useApi } from "../../utils/Api";
import DeleteIcon from "@mui/icons-material/Delete";
import BusinessIcon from "@mui/icons-material/Business";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import DotMenu from "../DotMenu";
import SpeedMenu from "../SpeedMenu";
import { hasAccess, hasSomeAccess, accessKeys } from "../../utils/userAccess";
import ActorCardAvatar from "./ActorCardAvatar";
import EditCompetencesDialog from "./EditCompetencesDialog";
import GenerateLicenseDialog from "./GenerateLicenseDialog";
import PersonCrud from "./crud/PersonCrud";
import ConnectPersonAndCompanyDialog from "./ConnectPersonAndCompanyDialog";
import YesOrNoDialog from "../YesOrNoDialog";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import NumbersIcon from "@mui/icons-material/Numbers";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import CakeIcon from "@mui/icons-material/Cake";
import NotesIcon from "@mui/icons-material/Notes";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CommentList from "./dialogs/CommentList";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import { formatDate } from "../../utils/dateTimeFormat";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ActorPermitFlowDialogContainer from "../actor-permits/ActorPermitFlowDialogContainer";
import DocUpload from "./dialogs/DocUpload";
import UploadActorPermit from "./dialogs/UploadActorPermit";
import AttachmentIcon from "@mui/icons-material/Attachment";
import download from "downloadjs";
import AgreementsGrid from "../agreements/AgreementsGrid";
import Personnummer from "personnummer";
import { formatInsertArguments } from "../../utils/formatString";

const getDotMenu = ({
  person,
  onDelete,
  setIsLoading,
  enqueueSnackbar,
  closeSnackbar,
  hasStandardOrAdvanced,
  hasActorPermits,
}) => {
  const menudata = [
    {
      icon: <EditIcon />,
      text: Translate.get("EditPersonalInfo"),
      route: false,
      component: (
        <PersonCrud
          actorId={person.actorId}
          isEditOnly
          isMatchingActorsDisabled
        />
      ),
    },
    {
      icon: <NotesIcon />,
      text: Translate.get("Notes"),
      dialogHeader: Translate.get("Notes"),
      customClass: "mediumCourseDialog",
      route: false,
      component: <CommentList actorId={person.actorId} />,
    },
    {
      icon: <AttachmentIcon />,
      text: Translate.get("Document"),
      //dialogHeader: Translate.get('Document'),
      customClass: "mediumCourseDialog",
      route: false,
      component: (
        <DocUpload
          actorId={person.actorId}
          files={person.files}
          type="actor"
          actorSkills={person.actorSkills}
        />
      ),
      hide: !hasStandardOrAdvanced,
    },
    {
      icon: <AttachmentIcon />,
      text: Translate.get("UploadActorPermit"),
      //dialogHeader: Translate.get('Document'),
      customClass: "mediumCourseDialog",
      route: false,
      component: (
        <UploadActorPermit
          actorData={{
            actorId: person.actorId,
            actorName: person.actorName,
            actorEmail: person.actorEmail,
            actorOrgNo: person.orgNo,
            externalActorNo: person.externalActorNo,
          }}
        />
      ),
      hide: !hasActorPermits,
    },
    {
      icon: <FileDownloadIcon />,
      text: Translate.get("ExportToExcel"),
      onClick: () =>
        downloadToExcel(
          person.actorId,
          setIsLoading,
          enqueueSnackbar,
          closeSnackbar
        ),
    },
    {
      icon: <DeleteIcon />,
      text: Translate.get("DeletePerson"),
      onClick: onDelete,
      // TODO disabled?
    },
  ];
  return menudata;
};

const getCompanyDotMenuFunc = ({ onDeleteRelation }) => {
  return (companyActorId) => {
    const menudata = [
      {
        icon: <BusinessIcon />,
        text: Translate.get("GoToCompany"),
        route: `/dashboard/company/${companyActorId}`,
      },

      {
        icon: <ClearIcon />,
        text: Translate.get("DeleteConnection"),
        onClick: () => onDeleteRelation(companyActorId),
      },
    ];
    return menudata;
  };
};

function downloadToExcel(
  actorId,
  setIsLoading,
  enqueueSnackbar,
  closeSnackbar
) {
  setIsLoading(true);

  let timestamp = new Date()
    .toISOString()
    .slice(0, 19)
    .replace(/-/g, "/")
    .replace("T", " ");
  let lang = localStorage.getItem("lang") ?? 9;

  let serviceUrl = `actors/${actorId}/report/excel/${lang}`;
  Api.fetchBlob(
    `${process.env.REACT_APP_MAIN_URL}` + serviceUrl,
    false,
    "GET",
    false,
    false
  )
    .then((data) => {
      var a = document.createElement("a");
      a.href = window.URL.createObjectURL(data);
      a.download = `export_${timestamp}`;
      a.click();
    })
    .catch(function () {
      const errorMessage = enqueueSnackbar(Translate.get("SomethingFailed"), {
        variant: "error",
        autoHideDuration: 6000,
        onClick: () => closeSnackbar(errorMessage),
      });
    })
    .finally((f) => {
      setIsLoading(false);
    });
}

const Comp = () => {
  //const [refresh, setRefresh] = useState(false);
  const smallScreen = useMediaQuery("(max-width:900px)");
  const id = useParams().id;
  //const id = props.id;
  const [person, setPerson] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [showDeleteQuestion, setShowDeleteQuestion] = useState(false);
  const [idAuthenticated, setIdAuthenticated] = useState(null);
  const hasStandardOrAdvanced = hasSomeAccess(
    accessKeys.standardPackage,
    accessKeys.advancedPackage
  );
  const hasActorPermits = hasAccess(accessKeys.actorPermit);
  const isSwedishCompany = hasAccess(accessKeys.swedishCompany);
  // visa kompetenslistan om användaren har standard eller avancerad
  // eller om användaren har körtillståndaccess och några kompetenser i listan
  const showEducatedOn =
    (hasStandardOrAdvanced && hasAccess(accessKeys.courseAdmin)) ||
    (hasActorPermits &&
      person &&
      Object.entries(person?.actorSkills?.actorSkillsBySkillGroup)?.length > 0);

  // visa intygslistan om användaren har standard eller avancerad, eller om användaren har körtillståndsaccess och några intyg i listan
  const showLicenses =
    (hasStandardOrAdvanced && hasAccess(accessKeys.courseAdmin)) ||
    (hasActorPermits && Boolean(person?.actorLicenses?.length));

  const showCourses =
    hasAccess(accessKeys.courseAdmin) ||
    (hasAccess(accessKeys.actorPermit) &&
      person.studentInCourses &&
      person.studentInCourses.length > 0);

  const [relationParentActorIdToDelete, setRelationParentActorIdToDelete] =
    useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const api = useApi();
  const navigate = useNavigate();

  const actorCompanies = useMemo(() => {
    const companies = [
      ...(person.studentAtCompanies ?? []),
      ...(person.contactForCompanies ?? []).filter(
        (x) => !person.studentAtCompanies.some((y) => y.actorId === x.actorId)
      ),
    ];
    return companies.filter((c) => c.actorId !== person.actorId);
  }, [person]);

  const personConnectionTypesByCompanyId = useMemo(
    () =>
      Object.fromEntries(
        actorCompanies.map((x) => {
          const isStudentConnection = person.studentAtCompanies.some(
            (y) => y.actorId === x.actorId
          );
          const isContactConnection = person.contactForCompanies.some(
            (y) => y.actorId === x.actorId
          );
          return [x.actorId, { isStudentConnection, isContactConnection }];
        })
      ),
    [actorCompanies, person]
  );
  const downLoader = (documentId) => {
    //Denna borde kanske vara egen js,
    //gissar att det alltid är så här man vill göra med av användare uppladdade filer.
    //och kanse även andra filer som sparas i Azure blob storage
    //I sådana fall med full fetch path som parameter istället för documentId
    (async () => {
      const data = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}/actors/file/get/${documentId}`
      );
      //console.log('download', data);
      (async () => {
        const res = await fetch(data.sasURI);
        const contentType = res.headers.get("Content-Type");
        const raw = await res.blob();
        //console.log('meta', data.filename, contentType);
        download(raw, data.filename, contentType);
      })();
    })();
  };
  //actors/{actorId}/as-client/  eller as-person
  useEffect(() => {
    api
      .fetch(
        `${process.env.REACT_APP_MAIN_URL}actors/${id}/as-person`,
        false,
        "GET"
      )
      .then((data) => {
        setPerson(data);
        setDataFetched(true);
        Authenticated(data);
      });
  }, [id, api]);

  async function handleDeletePerson() {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}actors/delete`,
      {
        actorId: person.actorId,
      }
    );
    setShowDeleteQuestion(false);
    if (response && response.isSuccessful) {
      window.history.back();
    }
  }

  async function postDeleteActorRelation(
    parentActorId,
    childActorId,
    actorTypeId
  ) {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}actors/relations/delete`,
      { parentActorId, childActorId, actorTypeId }
    );
    return response && response.isSuccessful;
  }

  async function handleDeleteRelation() {
    let studentConnectionDeleted = false;
    let contactConnectionDeleted = false;
    if (
      personConnectionTypesByCompanyId[relationParentActorIdToDelete]
        ?.isStudentConnection
    ) {
      studentConnectionDeleted = await postDeleteActorRelation(
        relationParentActorIdToDelete,
        person.actorId,
        4
      );
    }

    if (
      personConnectionTypesByCompanyId[relationParentActorIdToDelete]
        ?.isContactConnection
    ) {
      contactConnectionDeleted = await postDeleteActorRelation(
        relationParentActorIdToDelete,
        person.actorId,
        3
      );
    }
    setRelationParentActorIdToDelete(null);
    if (studentConnectionDeleted || contactConnectionDeleted) {
      navigate(0);
    }
  }

  function Authenticated(person) {
    if (person.authentications != null) {
      return BankIdAuthenticated(person);
    }

    if (person.manualAuthentications != null) {
      return ManualAuthenticated(person);
    }

    return "";
  }

  function BankIdAuthenticated(person) {
    let p = person;
    if (p == null) {
      return;
    }

    let auths = p.authentications;
    if (auths.length < 1) {
      return;
    }

    let normalizedSsn = "";
    if (Personnummer.valid(p.orgNo)) {
      let parsedSsn = Personnummer.parse(p.orgNo);
      normalizedSsn = parsedSsn.format(true);
    }

    auths = auths.sort((a, b) => new Date(b.regDate) - new Date(a.regDate));

    for (let i = 0; i < auths.length; i++) {
      if (auths[i].personalNumber !== normalizedSsn) {
        setIdAuthenticated({ Type: "bankIdSsnDiff", Authentication: auths[i] });
        return;
      }

      let bankIdName =
        capitalizeWords(auths[i].givenName) +
        " " +
        capitalizeWords(auths[i].surName);
      if (bankIdName !== p.actorName) {
        setIdAuthenticated({ Type: "bankIdNameDiff", Authentication: auths[i] });
        return;
      }
    }

    setIdAuthenticated({ Type: "bankIdCorrect", Authentication: auths[0] });
  }

  function capitalizeWords(string) {
    if (!string) return "";

    return string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  function ManualAuthenticated(person) {
    if (person.manualAuthentications == null || person.manualAuthentications.length < 1) {
      return "";
    }

    person.manualAuthentications.sort(
      (a, b) => new Date(b.regDate) - new Date(a.regDate)
    );
    
    let latestEntry = person.manualAuthentications[0];
    setIdAuthenticated({ Type: "manAuth", Authentication: latestEntry });
  }

  function getFingerPrintIconAndTooltip() {
    if (idAuthenticated == null) {
      return (
        <div className="stdListType">
          <FingerprintIcon />
          <div>{person.orgNo}</div>
        </div>
      );
    }

    if (idAuthenticated.Type === "bankIdNameDiff") {
      return (
        <div className="stdListType">
          <Tooltip
            title={formatInsertArguments(
              Translate.get("BankIdNameDiff"),
              capitalizeWords(idAuthenticated.Authentication.givenName),
              capitalizeWords(idAuthenticated.Authentication.surName),
              idAuthenticated.Authentication.personalNumber,
              formatDate(idAuthenticated.Authentication.regDate)
            )}
          >
            <FingerprintIcon sx={{ color: "orange" }} />
          </Tooltip>
          <div>{person.orgNo}</div>
        </div>
      );
    }

    if (idAuthenticated.Type === "bankIdSsnDiff") {
      return (
        <div className="stdListType">
          <Tooltip
            title={formatInsertArguments(
              Translate.get("BankIdSsnDoesNotMatch"),
              capitalizeWords(idAuthenticated.Authentication.givenName),
              capitalizeWords(idAuthenticated.Authentication.surName),
              idAuthenticated.Authentication.personalNumber,
              formatDate(idAuthenticated.Authentication.regDate)
            )}
          >
            <FingerprintIcon sx={{ color: "#F4846F" }} />
          </Tooltip>
          <div>{person.orgNo}</div>
        </div>
      );
    }

    if (idAuthenticated.Type === "bankIdCorrect") {
      return (
        <div className="stdListType">
          <Tooltip
            title={
              Translate.get("BankIDAuthenticated") +
              " " +
              formatDate(idAuthenticated.Authentication.regDate)
            }
          >
            <FingerprintIcon sx={{ color: "chartreuse" }} />
          </Tooltip>
          <div>{person.orgNo}</div>
        </div>
      );
    }

    if (idAuthenticated.Type === "manAuth") {
      return (
        <div className="stdListType">
          <Tooltip
            title={
              Translate.get("ManuallyAuthenticated") +
              " " +
              formatDate(idAuthenticated.Authentication.regDate)
            }
          >
            <FingerprintIcon sx={{ color: "chartreuse" }} />
          </Tooltip>
          <div>{person.orgNo}</div>
        </div>
      );
    }
  }

  const speedMenuActions = [];
  if (isSwedishCompany && !hasStandardOrAdvanced) {
    speedMenuActions.push({
      icon: <ContactMailIcon />,
      text: Translate.get("OrderLicense"),
      route: `/dashboard/licenseregistration?actorId=${id}`,
    });
  }
  if (hasStandardOrAdvanced && hasAccess(accessKeys.courseAdmin)) {
    speedMenuActions.push({
      icon: <BadgeIcon />,
      disabled:
        !person.actorSkills?.actorSkillsBySkillGroup ||
        !Object.entries(person.actorSkills?.actorSkillsBySkillGroup).some(
          ([_, v]) => !!v && v.length > 0
        ),
      text: Translate.get("GenerateLicense"),
      component: <GenerateLicenseDialog actorId={id} />,
    });

    speedMenuActions.push({
      icon: <BuildIcon />,
      text: Translate.get("EditSkills"),
      component: <EditCompetencesDialog actorId={id} />,
    });
  }

  speedMenuActions.push({
    icon: <AddIcon />,
    text: Translate.get("AddCompanyConnection"),
    component: <ConnectPersonAndCompanyDialog personActorId={id} />,
  });

  if (hasActorPermits === true) {
    speedMenuActions.push({
      icon: <ReceiptLongIcon />,
      text: Translate.get("CreateActorPermit"),
      dialogHeader: Translate.get("CreateActorPermit"),
      component: (
        <ActorPermitFlowDialogContainer actorIds={[id]} skipStep1={true} />
      ),
    });
  }

  if (dataFetched) {
    return (
      <>
        {isLoading && <Preloader />}
        <Grid container spacing={3}>
          <Grid item lg={12} xl={4} sx={{ width: "100%" }}>
            <div className="stdFlexRowTop">
              <h2>{Translate.get("Person")}</h2>
            </div>
            <Card sx={{ padding: "48px" }}>
              <div className="bigActorAvtar">
                <ActorCardAvatar
                  actorName={person.actorName}
                  profilePictureUrl={person.profilePictureUrl}
                />
                <h1>{person.actorName}</h1>
                <div className="stdMenuPos">
                  <DotMenu
                    content={getDotMenu({
                      person,
                      onDelete: () => setShowDeleteQuestion(true),
                      setIsLoading: setIsLoading,
                      enqueueSnackbar,
                      closeSnackbar,
                      hasStandardOrAdvanced,
                      hasActorPermits,
                    })}
                  />
                </div>
              </div>
              {person.orgNo && getFingerPrintIconAndTooltip()}
              {person.birthDate && (
                <div className="stdListType">
                  <CakeIcon />
                  <div>{person.birthDate}</div>
                </div>
              )}
              {person.externalActorNo && (
                <div className="stdListType">
                  <NumbersIcon />
                  <div>{person.externalActorNo}</div>
                </div>
              )}

              {person.actorEmail && (
                <div className="stdListType">
                  <EmailIcon />
                  <div>
                    <a
                      className="stdMailToLink"
                      href={"mailto:" + person.actorEmail}
                    >
                      {person.actorEmail}{" "}
                    </a>
                  </div>
                </div>
              )}
              {person.mobilePhone?.actorPhoneNumber && (
                <div className="stdListType">
                  <LocalPhoneIcon />
                  <div>{person.mobilePhone.actorPhoneNumber}</div>
                </div>
              )}
              {(person.postalAddress?.actorAddressZipCode ||
                person.postalAddress?.actorAddressCity ||
                person.postalAddress?.actorAddressStreet2) && (
                <div className="stdListType">
                  <PlaceIcon />
                  <div>
                    {person.postalAddress?.actorAddressStreet2 && (
                      <>
                        {person.postalAddress?.actorAddressStreet2} <br />
                      </>
                    )}
                    {(person.postalAddress?.actorAddressZipCode ||
                      person.postalAddress?.actorAddressCity) && (
                      <>
                        {person.postalAddress?.actorAddressZipCode}{" "}
                        {person.postalAddress?.actorAddressCity} <br />
                      </>
                    )}
                    {person.postalAddress?.actorAddressCountry && (
                      <>{person.postalAddress?.actorAddressCountry}</>
                    )}
                  </div>
                </div>
              )}
              {person.observationNote && (
                <div className="stdListType">
                  <ErrorOutlineIcon />
                  <div>{person.observationNote}</div>
                </div>
              )}
              {person.hasApprovedGDPR && (
                <div className="stdListType">
                  <LockPersonIcon />
                  <div>
                    {Translate.get("GDPR")}{" "}
                    {formatDate(person.gdprApprovalDate)}
                  </div>
                </div>
              )}
              {person.nationality && (
                <div className="stdListType">
                  <FlagIcon />
                  <div>{Translate.getCountryName(person.nationality)}</div>
                </div>
              )}

              {Boolean(person.actorFreeText.length) && (
                <Accordion className="stdAccordion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div className="stdFlexLeft16">
                      <NotesIcon />
                      {Translate.get("Notes")} ({person.actorFreeText.length})
                    </div>
                  </AccordionSummary>
                  {person.actorFreeText.map((row, index) => (
                    <Tooltip
                      title={`${formatDate(row.updDate ?? row.regDate)} / ${
                        row.authorName
                      }`}
                      key={index}
                    >
                      <AccordionDetails>{row.textNote}</AccordionDetails>
                    </Tooltip>
                  ))}
                </Accordion>
              )}

              {Boolean(person.files.length) && (
                <Accordion className="stdAccordion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div className="stdFlexLeft16">
                      <AttachmentIcon />
                      {Translate.get("Document")} ({person.files.length})
                    </div>
                  </AccordionSummary>
                  {person.files.map((row, index) => (
                    <AccordionDetails key={index}>
                      <div
                        onClick={() => downLoader(row.blobDocumentId)}
                        className="stdFlexLeft selectable"
                      >
                        <AttachmentIcon />
                        <div>{row.originalFileName}</div>
                      </div>
                    </AccordionDetails>
                  ))}
                </Accordion>
              )}
            </Card>
            <div className="stdFlexRow">
              <h2>{Translate.get("Companies")}</h2>
            </div>
            <Card>
              <Companies
                rows={actorCompanies}
                personConnectionTypesByCompanyId={
                  personConnectionTypesByCompanyId
                }
                smallScreen={true}
                getDotMenuActions={getCompanyDotMenuFunc({
                  onDeleteRelation: (companyActorId) =>
                    setRelationParentActorIdToDelete(companyActorId),
                })}
              />
            </Card>
          </Grid>
          <Grid item lg={12} xl={8} sx={{ width: "100%" }}>
            {showCourses && (
              <>
                <div className="stdFlexRowTop">
                  <h2>{Translate.get("Courses")}</h2>
                </div>
                <Card>
                  <CourseGrid
                    rows={person.studentInCourses}
                    smallScreen={smallScreen}
                    hideFilter={true}
                    hideDotMenu
                    loadFilters={false}
                  />
                </Card>
              </>
            )}

            {showEducatedOn && (
              <>
                <div className="stdFlexRow">
                  <h2>{Translate.get("EducatedOn")}</h2>
                </div>
                <Card>
                  <Competences
                    rows={person.actorSkills} /*  smallScreen={smallScreen} */
                  />
                </Card>{" "}
              </>
            )}
            {showLicenses && (
              <>
                <div className="stdFlexRow">
                  <h2>{Translate.get("Licence")}</h2>
                </div>
                <Card sx={{ boxShadow: 0 }}>
                  <Licences
                    rows={person.actorLicenses}
                    smallScreen={smallScreen}
                  />
                </Card>
              </>
            )}
            {hasActorPermits && (
              <>
                <div className="stdFlexRow">
                  <h2>{Translate.get("ActorPermit")}</h2>
                </div>
                <Card sx={{ boxShadow: 0 }}>
                  <Permits
                    actorName={person.actorName}
                    actorOrgNo={person.orgNo}
                    permits={person.actorPermits}
                    smallScreen={smallScreen}
                    cardView={true}
                  />
                </Card>
              </>
            )}
            {hasAccess(accessKeys.isMASystem) && (
              <>
                <div className="stdFlexRow">
                  <h2>{Translate.get("ServiceAgreementsTitle")}</h2>
                </div>
                <Card sx={{ boxShadow: 0 }}>
                  <AgreementsGrid personActorId={person.actorId} />
                </Card>
              </>
            )}
          </Grid>
        </Grid>
        <SpeedMenu content={speedMenuActions} />
        <YesOrNoDialog
          open={showDeleteQuestion}
          title={Translate.get("DeletePerson")}
          text={Translate.get("SureRemovePerson")}
          onNo={() => setShowDeleteQuestion(false)}
          onYes={() => handleDeletePerson()}
          noText={Translate.get("Cancel")}
          yesText={Translate.get("Delete")}
        />
        <YesOrNoDialog
          open={!!relationParentActorIdToDelete}
          title={Translate.get("DeleteConnection")}
          text={Translate.get("SureRemoveRelation")}
          onNo={() => setRelationParentActorIdToDelete(null)}
          onYes={() => handleDeleteRelation()}
          noText={Translate.get("Cancel")}
          yesText={Translate.get("Delete")}
        />
      </>
    );
  }
  return <Preloader />;
};
export default Comp;
