// material
import { useState } from "react";
// components
import Page from "../components/Page";
import Translate from "../utils/Translate";
import News from "../components/_dashboard/app/News";
import Order from "../components/_dashboard/app/Order";
import Download from "../components/_dashboard/app/Download";
//import BuyLic from '../components/_dashboard/app/BuyLic';
import { useApi } from "../utils/Api";
import Preloader from "../components/Preloader";
import SpeedMenu from "../components/SpeedMenu";
import AddIcon from "@mui/icons-material/Add";
import CourseDialog from "../components/courses/CourseDialog";
import { IconButton } from "@mui/material";
import { accessKeys, hasAccess } from "../utils/userAccess";

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";

const getSpeedMenu = (props) => {
  const menudata = [
    {
      icon: <AddIcon />,
      text: Translate.get("AddCourse"),
      route: false,
      dialogHeader: Translate.get("AddCourse"),
      customClass: "bigCourseDialog",
      component: <CourseDialog {...props} />,
    },
  ];
  return menudata;
};

// ----------------------------------------------------------------------
//Mock data start

export default function DashboardAppMin() {
  const [init, setInit] = useState(false);
  const [newsFeed, setNewsFeed] = useState(null);
  const [open, setOpen] = useState(true);
  const api = useApi();

  const fetchNews = () => {
    api.fetch(`${process.env.REACT_APP_MAIN_URL}dashboard/news/`).then(
      (data) => {
        if (data && data.length) {
          setNewsFeed(data.slice(0, 5));
        }
      }
    );
  };

  if (!init) {
    fetchNews();
    setInit(true);
  }

  if (!init) {
    return <Preloader invisible={true} />;
  }

  return (
    <>
      <Page>
        <div className="stdFlexCenterCol">
          <Box sx={{ width: "100%" }}>
            <Collapse in={open}>
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                icon={false}
                //sx={{ mb: 2, backgroundColor:'red' }}

                sx={{
                  mb: 2,
                  "& .MuiAlert-message": {
                    width: "100%",
                    textAlign: "center",
                    padding: 0,
                  },
                }}
              >
                <div className="stdFlexCenter">
                  <StarIcon color="success" />
                  Uppgradera till Avancerad och lås upp en hel värld av
                  funktioner.
                  <Button
                    variant="contained"
                    color="success"
                    size="small"
                    sx={{ color: "white" }}
                    component={Link}
                    to="/dashboard/flyer/"
                  >
                    Läs mer
                  </Button>
                </div>
              </Alert>
            </Collapse>
          </Box>
          <div
            className="stdCardHolder"
            style={{
              width: "100%",
              maxWidth: "826px",
              justifyContent: "center",
            }}
          >
            <div style={{ width: "100%", maxWidth: "400px" }}>
              <h2 className="stdFlexRowTop">
                {Translate.get("InstructorServices")}
              </h2>
              {!hasAccess(accessKeys.advancedPackage) && <Order />}
              <br />
              {hasAccess(accessKeys.instructorMaterial) && <Download />}
            </div>
            <div style={{ width: "100%", maxWidth: "400px" }}>
              <h2 className="stdFlexRowTop">{Translate.get("NewsFeed")}</h2>
              <News data={newsFeed} />
            </div>
          </div>
        </div>
      </Page>
      <SpeedMenu
        content={getSpeedMenu({
          id: 0,
        })}
      />
    </>
  );
}
