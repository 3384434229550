import { Dialog } from "@mui/material";
import OrderForm from "./OrderForm";

// This just a wrapper, since the OrderForm will be used in different scenarios
export default function OrderFormDialog({ onClose, ...props }) {
  return (
    <Dialog open={true} onClose={onClose}>
      <OrderForm onClose={onClose} {...props} />
    </Dialog>
  );
}
