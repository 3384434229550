import AttachmentIcon from "@mui/icons-material/Attachment";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { useMediaQuery, TextField, Avatar } from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import download from "downloadjs";
import { useState, useEffect } from "react";
import DotMenu from "../../../components/DotMenu";
import api from "../../../utils/Api";
import Translate from "../../../utils/Translate";
import Preloader from "../../Preloader";
import HtmlEditor from "../../HtmlEditor";
import { useSnackbar } from "notistack";
import WarningIcon from "@mui/icons-material/Warning";
import { formatDate } from "../../../utils/dateTimeFormat";
import { stringToColor } from "../../../utils/formatString";
import Chip from "@mui/material/Chip";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PersonCrud from "../../actors/crud/PersonCrud";

const genericErrorMessageKey = "SomethingFailed";

const actions = Object.freeze({
  sendToParticipants: "sendToParticipants",
  sendToContacts: "sendToContacts",
  sendToAll: "sendToAll",
});

const sanitize = (obj) => {
  return JSON.parse(
    JSON.stringify(obj, (key, value) => {
      return value === null ? undefined : value;
    })
  );
};
const sendInvitations = (action, courseId, courseBookings) => {
  let fetchCall;
  if (action === actions.sendToParticipants) {
    let postObj = {
      courseId: courseId,
      courseparticipants: courseBookings.flatMap((cb) =>
        cb.courseParticipants
          .filter((cp) => !!cp.email && cp.exclude !== true)
          .map((cp) => ({
            courseBookingId: cb.id ?? cb.courseBookingId,
            courseParticipantId: cp.courseParticipantId,
            subject: cp.emailSubject,
            htmlBody: cp.htmlBody,
          }))
      ),
    };
    postObj = sanitize(postObj);
    fetchCall = api.fetch(
      `${process.env.REACT_APP_MAIN_URL}courses/email/students/coursesummonings`,
      postObj
    );
  } else if (action === actions.sendToContacts) {
    let postObj = {
      courseId: courseId,
      contacts: courseBookings
        .filter((cb) => !!cb.contactActorEmail)
        .map((cb) => ({
          courseBookingId: cb.courseBookingId,
          subject: cb.emailSubject,
          htmlBody: cb.htmlBody,
          courseParticipantIds: cb.courseParticipants
            .filter((x) => x.courseParticipantId > 0)
            .map((x) => x.courseParticipantId),
        })),
    };
    postObj = sanitize(postObj);
    fetchCall = api.fetch(
      `${process.env.REACT_APP_MAIN_URL}courses/email/contacts/coursesummonings`,
      postObj
    );
  } else if (action === actions.sendToAll) {
    let postObj = {
      courseId: courseId,
      courseparticipants: courseBookings.flatMap((cb) =>
        cb.courseParticipants
          .filter(
            (cp) =>
              !!cp.email &&
              cp.actorId !== cb.contactActorId &&
              cp.exclude !== true
          )
          .map((cp) => ({
            courseBookingId: cb.id ?? cb.courseBookingId,
            courseParticipantId: cp.courseParticipantId,
            subject: cp.emailSubject,
            htmlBody: cp.htmlBody,
          }))
      ),
      contacts: courseBookings
        .filter((cb) => !!cb.contactActorEmail && cb.exclude !== true)
        .map((cb) => ({
          courseBookingId: cb.courseBookingId,
          subject: cb.emailSubject,
          htmlBody: cb.htmlBody,
          courseParticipantIds: cb.courseParticipants
            .filter((x) => x.courseParticipantId > 0)
            .map((x) => x.courseParticipantId),
        })),
    };
    postObj = sanitize(postObj);
    fetchCall = api.fetch(
      `${process.env.REACT_APP_MAIN_URL}courses/email/coursesummonings`,
      postObj
    );
  } else {
    throw new Error("Undefined action");
  }

  return fetchCall.then(
    (response) => {
      if (response.isSuccessful) {
        if (action !== actions.sendToAll) {
          return Promise.resolve();
        } else {
          return Promise.reject();
        }
      }
      return Promise.reject(
        response.errorMessageTranslationKey ?? genericErrorMessageKey
      );
    },
    () => Promise.reject(genericErrorMessageKey)
  );
};

const getStudentInvitationText = (data) =>
  api.fetch(
    `${process.env.REACT_APP_MAIN_URL}courses/email/student/preview`,
    data,
    "POST"
  );

const getContactInvitationText = (data) =>
  api.fetch(
    `${process.env.REACT_APP_MAIN_URL}courses/email/contact/preview`,
    data,
    "POST"
  );

const downloadInvitationPdf = (
  courseParticipantId,
  courseId,
  courseBookingId,
  actorName,
  courseFromDate
) => {
  actorName = actorName?.trim();
  let fileName = Translate.get("CourseSummoning");
  if (actorName) {
    fileName += ` ${actorName}`;
  }
  fileName += courseFromDate ? ` ${formatDate(courseFromDate)}` : "";
  fileName += ".pdf";
  const data = {
    courseParticipantId: courseParticipantId,
    courseId: courseId,
    courseBookingId: courseBookingId,
  };
  return api
    .fetchBlob(
      `${process.env.REACT_APP_MAIN_URL}courses/pdf/coursesummoning`,
      data,
      "POST"
    )
    .then((blob) => download(blob, fileName, "application/pdf"));
};

const countParticipants = (courseBookings) =>
  courseBookings.flatMap((x) => x.courseParticipants.filter((cp) => !!cp.email))
    .length;

const CourseInvitation = (props) => {
  useEffect(() => {
    window.analyticsEvent("eport_xtras", "eport_course_invitation");
    //console.log('CourseInvitation')
  }, []);

  const courseBookingProps = props.courseBookings.map((x) => ({
    ...x,
    courseParticipants: x.courseParticipants.filter(
      (y) => y.courseParticipantId !== -1
    ),
  }));

  const [processing, setProcessing] = useState(false);
  const [
    editingCourseParticipantIdMessage,
    setEditingCourseParticipantIdMessage,
  ] = useState(null);
  const [editingCourseBookingIdMessage, setEditingCourseBookingIdMessage] =
    useState(0);
  const [invitationSubject, setInvitationSubject] = useState("");
  const [htmlMessage, setHtmlMessage] = useState("");
  const [editedHtmlMessage, setEditedHtmlMessage] = useState(null);
  const [attachementImages, setAttachmentImages] = useState([]);
  const [courseBookings, setCourseBookings] = useState(courseBookingProps);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const bigScreen = useMediaQuery("(min-width:1200px)");

  const closeAndReload = () => {
    window.history.back();
    props.loadCourse();
  };

  //----------

  const setFailedToGetText = () => {
    const errorMessage = enqueueSnackbar(
      Translate.get(genericErrorMessageKey),
      {
        variant: "error",
        autoHideDuration: 6000,
        onClick: () => closeSnackbar(errorMessage),
      }
    );

    // jump out of editing mode
    setEditingCourseParticipantIdMessage(false);
    setEditingCourseBookingIdMessage(false);
  };

  const editInvitation = (data) => {
    setProcessing(true);
    if (Object.hasOwn(data, "courseParticipantId")) {
      const courseParticipantId = data.courseParticipantId;
      setEditingCourseParticipantIdMessage(courseParticipantId);
      const participant = courseBookings
        .flatMap((x) => x.courseParticipants)
        .find((x) => x.courseParticipantId === courseParticipantId);
      //wip
      if (!participant.htmlBody) {
        getStudentInvitationText(data)
          .then((response) => {
            if (response.isSuccessful !== false) {
              setInvitationSubject(response.emailSubject);
              const imgs = response.htmlBody.attachments.map((x) => {
                return {
                  name: x.contentId,
                  url: "data:image/png;base64," + x.dataBase64,
                };
              });
              setAttachmentImages(imgs);

              setHtmlMessage(response.htmlBody.html);
            } else {
              setFailedToGetText();
            }
          })
          .finally(() => setProcessing(false));
      } else {
        setInvitationSubject(participant.emailSubject);
        // setInvitationText(participant.htmlBody);
        setHtmlMessage(participant.htmlBody);
        setProcessing(false);
      }
    } else if (Object.hasOwn(data, "courseBookingId")) {
      const courseBookingId = data.courseBookingId;
      setEditingCourseBookingIdMessage(courseBookingId);
      const booking = courseBookings.find(
        (x) => x.courseBookingId === courseBookingId
      );

      if (!booking?.htmlBody) {
        getContactInvitationText(data)
          .then((response) => {
            //wip inte 88 ,false, funkar inte just nu
            if (response.isSuccessful !== false) {
              setInvitationSubject(response.emailSubject);
              const imgs = response.htmlBody.attachments.map((x) => {
                return {
                  name: x.contentId,
                  url: "data:image/png;base64," + x.dataBase64,
                };
              });
              setAttachmentImages(imgs);

              setHtmlMessage(response.htmlBody.html);
            } else {
              setFailedToGetText();
            }
          })
          .finally(() => setProcessing(false));
      } else {
        setInvitationSubject(booking.emailSubject);
        setHtmlMessage(booking.htmlBody);
        setProcessing(false);
      }
    } else {
      setProcessing(false);
      throw new Error("Undefined action");
    }
  };

  const downloadInvitation = (
    courseParticipantId,
    courseId,
    courseBookingId,
    actorName,
    courseFromDate
  ) => {
    setProcessing(true);
    downloadInvitationPdf(
      courseParticipantId,
      courseId,
      courseBookingId,
      actorName,
      courseFromDate
    )
      .catch(() => {
        const errorMessage = enqueueSnackbar(
          Translate.get(genericErrorMessageKey),
          {
            variant: "error",
            autoHideDuration: 6000,
            onClick: () => closeSnackbar(errorMessage),
          }
        );
      })
      .finally(() => setProcessing(false));
  };

  const removeParticipant = (courseParticipantId) => {
    const nextCourseBookings = courseBookings.map((currentBooking) => ({
      ...currentBooking,
      courseParticipants: currentBooking.courseParticipants.map((cp) => {
        if (cp.courseParticipantId !== courseParticipantId) {
          return cp;
        } else {
          return {
            ...cp,
            exclude: true,
          };
        }
      }),
    }));
    setCourseBookings(nextCourseBookings);
  };

  const removeContact = (courseBookingId, actorId) => {
    let nextCourseBookings = courseBookings.map((cb) => {
      if (cb.courseBookingId !== courseBookingId) {
        return cb;
      } else {
        return {
          ...cb,
          exclude: true,
        };
      }
    });

    nextCourseBookings = removeContactStudent(actorId, nextCourseBookings);
    setCourseBookings(nextCourseBookings);
  };

  const removeContactStudent = (actorId, courseBookings) => {
    const nextCourseBookings = courseBookings.map((currentBooking) => ({
      ...currentBooking,
      courseParticipants: currentBooking.courseParticipants.map((cp) => {
        if (cp.actorId !== actorId) {
          return cp;
        } else {
          return {
            ...cp,
            exclude: true,
          };
        }
      }),
    }));
    return nextCourseBookings;
  };

  // OK in pane for editing message
  const editMessageOk = () => {
    updateBookingMessage(editedHtmlMessage);
    setEditingCourseParticipantIdMessage(null);
    setEditingCourseBookingIdMessage(0);
  };

  // update message for single participant / contact
  const updateBookingMessage = (htmlContent) => {
    let updatedCourseBookings;
    if (editingCourseParticipantIdMessage) {
      updatedCourseBookings = courseBookings.map((currentBooking) => ({
        ...currentBooking,
        courseParticipants: currentBooking.courseParticipants.map(
          (participant) =>
            participant.courseParticipantId ===
            editingCourseParticipantIdMessage
              ? {
                  ...participant,
                  htmlBody: htmlContent,
                  emailSubject: invitationSubject,
                }
              : { ...participant }
        ),
      }));
    } else if (editingCourseBookingIdMessage) {
      updatedCourseBookings = courseBookings.map((currentBooking) =>
        currentBooking.courseBookingId === editingCourseBookingIdMessage
          ? {
              ...currentBooking,
              htmlBody: htmlContent,
              emailSubject: invitationSubject,
            }
          : {
              ...currentBooking,
            }
      );
    }
    setCourseBookings(updatedCourseBookings);
  };

  const sendClick = () => {
    setProcessing(true);
    sendInvitations(props.action, props.courseId, courseBookings)
      .then(
        () => closeAndReload(),

        function (errorMessageTranslationKey) {
          if (errorMessageTranslationKey) {
            const errorMessage = enqueueSnackbar(
              Translate.get(errorMessageTranslationKey),
              {
                variant: "error",
                autoHideDuration: 6000,
                onClick: () => closeSnackbar(errorMessage),
              }
            );
          } else {
            const message = enqueueSnackbar(
              Translate.get("WorkingInBackground"),
              {
                variant: "success",
                autoHideDuration: 6000,
                onClick: () => closeSnackbar(message),
              }
            );
            closeAndReload();
          }
        }
      )
      .finally(() => setProcessing(false));
  };

  const cancelMessageEdit = () => {
    setEditingCourseParticipantIdMessage(null);
    setEditingCourseBookingIdMessage(0);
  };

  function canSend() {
    if (props.action === actions.sendToParticipants) {
      return courseBookings.some((cb) =>
        cb.courseParticipants.some((cp) => !!cp.email && cp.exclude !== true)
      );
    }

    if (props.action === actions.sendToContacts) {
      return courseBookings.some(
        (cb) => !!cb.contactActorEmail && cb.exclude !== true
      );
    }

    if (props.action === actions.sendToAll) {
      let a = courseBookings.some((cb) =>
        cb.courseParticipants.some((cp) => !!cp.email && cp.exclude !== true)
      );
      let b = courseBookings.some(
        (cb) => !!cb.contactActorEmail && cb.exclude !== true
      );

      return a || b;
    }
  }

  function getRecipients(cb, props) {
    if (props.action === actions.sendToParticipants) {
      return cb.courseParticipants
        .filter((cp) => cp.exclude !== true)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((cp, index) => (
          <div key={index} className="stdList">
            <div className="tableAvatar">
              <Avatar
                alt={cp.name}
                src={cp.profilePictureUrl}
                sx={{ bgcolor: stringToColor(cp.name) }}
              ></Avatar>
              <div>
                <div className="tableAvatarTitle bold">{cp.name}</div>
                <div>
                  {cp.email ? (
                    cp.email
                  ) : (
                    <div className="smallRedWarning">
                      <WarningIcon fontSize="8" />
                      <div> {Translate.get("InvalidEmail")}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <DotMenu
              content={[
                {
                  icon: <EditIcon />,
                  text: Translate.get("EditMessage"),
                  onClick: () =>
                    editInvitation({
                      courseParticipantId: cp.courseParticipantId,
                      courseBookingId: cb.id,
                      courseId: cb.courseId,
                    }),
                  disabled: !cp.email,
                },
                {
                  icon: <EditIcon />,
                  text: Translate.get("EditPersonalInfo"),
                  route: false,
                  disabled: !cp.actorId,
                  component: (
                    <PersonCrud
                      actorId={cp.actorId}
                      isMatchingActorsDisabled={true}
                      isEditOnly={true}
                    />
                  ),
                },
                {
                  icon: <PictureAsPdfIcon />,
                  text: Translate.get("Download"),
                  onClick: () =>
                    downloadInvitation(
                      cp.courseParticipantId,
                      cb.courseId,
                      cb.id,
                      cp.name,
                      props.courseFromDate
                    ),
                },
                {
                  icon: <CloseIcon />,
                  text: Translate.get("RemoveRecipient"),
                  onClick: () => removeParticipant(cp.courseParticipantId),
                  //disabled: !cp.email,
                },
              ]}
            />
          </div>
        ));
    }
    if (props.action === actions.sendToContacts) {
      if (cb.exclude !== true) {
        return (
          <div className="stdList">
            <div className="tableAvatar">
              <Avatar
                alt={cb.contactActorName}
                src={cb.contactProfilePictureUrl}
                sx={{ bgcolor: stringToColor(cb.contactActorName) }}
              ></Avatar>
              <div>
                <div className="tableAvatarTitle bold">
                  {cb.contactActorName}
                </div>
                <div>
                  {cb.contactActorEmail ? (
                    cb.contactActorEmail
                  ) : (
                    <div className="smallRedWarning">
                      <WarningIcon fontSize="8" />
                      <div> {Translate.get("InvalidEmail")}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <DotMenu
              content={[
                {
                  icon: <EditIcon />,
                  text: Translate.get("EditMessage"),
                  onClick: () =>
                    editInvitation({
                      courseBookingId: cb.courseBookingId,
                      courseId: props.courseId,
                    }),
                  disabled: !cb.contactActorEmail,
                },
                {
                  icon: <EditIcon />,
                  text: Translate.get("EditContact"),
                  route: false,
                  component: <PersonCrud actorId={cb.contactActorId} />,
                },
                {
                  icon: <CloseIcon />,
                  text: Translate.get("RemoveRecipient"),
                  onClick: () =>
                    removeContact(cb.courseBookingId, cb.contactActorId),
                  //disabled: !cb.contactActorEmail,
                },
              ]}
            />
          </div>
        );
      } else {
        return;
      }
    }

    if (props.action === actions.sendToAll) {
      return (
        <div>
          {cb.exclude !== true && (
            <div className="stdList">
              <div className="tableAvatar">
                <Avatar
                  alt={cb.contactActorName}
                  src={cb.contactProfilePictureUrl}
                  sx={{ bgcolor: stringToColor(cb.contactActorName) }}
                ></Avatar>
                <div>
                  <div className="tableAvatarTitle bold">
                    {cb.contactActorName} <br />
                    <Chip
                      icon={<HeadsetMicIcon />}
                      size="small"
                      label={Translate.get("Contact")}
                    />
                  </div>
                  <div>
                    {cb.contactActorEmail ? (
                      cb.contactActorEmail
                    ) : (
                      <div className="smallRedWarning">
                        <WarningIcon fontSize="8" />
                        <div> {Translate.get("InvalidEmail")}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <DotMenu
                content={[
                  {
                    icon: <EditIcon />,
                    text: Translate.get("EditMessage"),
                    onClick: () =>
                      editInvitation({
                        courseBookingId: cb.courseBookingId,
                        courseId: props.courseId,
                        contactActorId: cb.contactActorId,
                      }),
                    disabled: !cb.contactActorEmail,
                  },
                  {
                    icon: <EditIcon />,
                    text: Translate.get("EditContact"),
                    route: false,
                    component: <PersonCrud actorId={cb.contactActorId} />,
                  },
                  {
                    icon: <CloseIcon />,
                    text: Translate.get("RemoveRecipient"),
                    onClick: () =>
                      removeContact(cb.courseBookingId, cb.contactActorId),
                  },
                ]}
              />
            </div>
          )}

          {cb.courseParticipants
            .filter(
              (cp) => cb.contactActorId !== cp.actorId && cp.exclude !== true
            )
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((cp, index) => (
              <div key={index} className="stdList">
                <div className="tableAvatar">
                  <Avatar
                    alt={cp.name}
                    src={cp.profilePictureUrl}
                    sx={{ bgcolor: stringToColor(cp.name) }}
                  ></Avatar>
                  <div>
                    <div className="tableAvatarTitle bold">{cp.name}</div>
                    <div>
                      {cp.email ? (
                        cp.email
                      ) : (
                        <div className="smallRedWarning">
                          <WarningIcon fontSize="8" />
                          <div> {Translate.get("InvalidEmail")}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <DotMenu
                  content={[
                    {
                      icon: <EditIcon />,
                      text: Translate.get("EditMessage"),
                      onClick: () =>
                        editInvitation({
                          courseParticipantId: cp.courseParticipantId,
                          courseBookingId: cb.courseBookingId,
                          courseId: props.courseId,
                        }),
                      disabled: !cp.email,
                    },
                    {
                      icon: <EditIcon />,
                      text: Translate.get("EditPersonalInfo"),
                      route: false,
                      component: <PersonCrud actorId={cp.actorId} />,
                    },
                    {
                      icon: <PictureAsPdfIcon />,
                      text: Translate.get("Download"),
                      onClick: () =>
                        downloadInvitation(
                          cp.courseParticipantId,
                          props.courseId,
                          cb.courseBookingId,
                          cp.name
                        ),
                    },
                    {
                      icon: <CloseIcon />,
                      text: Translate.get("RemoveRecipient"),
                      onClick: () => removeParticipant(cp.courseParticipantId),
                      //disabled: !cp.email,
                    },                    
                  ]}
                />
              </div>
            ))}
        </div>
      );
    }
  }

  return (
    //Allt innehåll i dialog förutom Dialog + DialogHeader
    <>
      {processing ? <Preloader /> : null}
      <DialogContent dividers={true}>
        {!processing &&
        (editingCourseParticipantIdMessage || editingCourseBookingIdMessage) ? ( // edit message mode
          <>
            <TextField
              sx={{ width: 1, mb: 2, mt: 2 }}
              label={Translate.get("Subject")}
              value={invitationSubject}
              onChange={(evt) => setInvitationSubject(evt.target.value)}
            />
            <HtmlEditor
              label={Translate.get("Message")}
              htmlContent={htmlMessage}
              images={attachementImages}
              onBlur={setEditedHtmlMessage}
            />
          </>
        ) : (
          <>
            {courseBookings
              .sort((a, b) => a.companyName.localeCompare(b.companyName))
              .map((cb, index) => (
                <div key={index}>
                  <div className="smallHeader topBottom16">
                    {cb.companyName?.toUpperCase()}
                  </div>
                  {getRecipients(cb, props)}
                </div>
              ))}
          </>
        )}
      </DialogContent>
      <DialogActions>
        {editingCourseParticipantIdMessage || editingCourseBookingIdMessage ? ( // if editing message, show Cancel / OK for editing the message
          <>
            {" "}
            {/* we *are* in editing mode, show cancel / Send for *editing* the messages */}
            <Button onClick={cancelMessageEdit}>
              {Translate.get("Cancel")}
            </Button>
            <Button onClick={editMessageOk}>{Translate.get("Ready")}</Button>
          </>
        ) : (
          <>
            {" "}
            {/* *not* in editing mode, show cancel / Send for *sending* the messages */}
            <Button onClick={() => window.history.back()}>
              {Translate.get("Cancel")}
            </Button>
            <Button
              variant="contained"
              onClick={sendClick}
              disabled={!canSend()}
            >
              {Translate.get("Send")}
            </Button>
          </>
        )}
      </DialogActions>
    </>
  );
};

export default CourseInvitation;
