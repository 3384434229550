import React, { useState } from 'react';
import './stylesheet.css';
import { LoginWithId } from '../components/selfReg/LoginWithId';
import { ChooseCourseBooking } from '../components/selfReg/ChooseCourseBooking';
import { Form } from '../components/selfReg/Form';
import { Success } from './Success';
import { BankId } from '../components/selfReg/BankId';

export default function SelfRegStudent(props) {
  const [mode, setMode] = useState(props.mode ? props.mode : "LoginWithId");
  const [qrToken, setQrToken] = useState(null);
  const [id, setId] = useState(null);
  const [actorId, setActorId] = useState(null);
  const [fields, setFields] = useState([]);
  const [orgNoFormat, setOrgNoFormat] = useState("");
  const [courseBookingId, setCourseBookingId] = useState(-1);
  const [isActorSpecific, setIsActorSpecific] = useState(false);

  const [existingActor, setExistingActor] = useState(null);
  const [bankIdToken, setBankIdToken] = useState("");
  const [givenName, setGivenName] = useState("");
  const [surName, setSurName] = useState("");
  const [ssn, setSsn] = useState("");

  switch (mode) {
    case "LoginWithId":
      return (
        <LoginWithId 
          setToken={setQrToken} 
          setMode={setMode} 
          setId={setId} 
          setIsActorSpecific={setIsActorSpecific} 
        />
      );
    
    case "ChooseCourseBooking":
      return (
        <ChooseCourseBooking 
          qrToken={qrToken} 
          id={id} 
          setActorId={setActorId} 
          setToken={setQrToken} 
          setId={setId} 
          setMode={setMode} 
          setFields={setFields} 
          setCourseBookingId={setCourseBookingId} 
          setOrgNoFormat={setOrgNoFormat}
        />
      );

    case "BankId":
      return (
        <BankId 
          id={id}
          qrToken={qrToken}
          setBankIdToken={setBankIdToken}
          setExistingActor={setExistingActor}
          setGivenName={setGivenName}
          setSurName={setSurName}
          setSsn={setSsn}
          setMode={setMode}
        />
      );
    
    case "Form":
      return (
        <Form 
          id={id} 
          actorId={actorId} 
          qrToken={qrToken} 
          courseBookingId={courseBookingId} 
          setMode={setMode} 
          fields={fields} 
          orgNoFormat={orgNoFormat} 
          isActorSpecific={isActorSpecific} 
          bankIdToken={bankIdToken}
          givenName={givenName}
          surName={surName}
          ssn={ssn}
          existingActor={existingActor}
        />
      );
  
    case "Success":
      return (
        <Success />
      );
  
    default:
      return null;
  }
}
