import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { useApi } from "../../utils/Api";
import Translate from "../../utils/Translate";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Preloader from "../Preloader";
import { pseudoELearningTypes } from "../../utils/eLearningTypes";

export function Order({ switchMode, ...props }) {
  const [qty, setQty] = useState("");
  const [orderNo, setOrderNo] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [isAutoOrderOk, setIsAutoOrderOk] = useState(null);
  const [open, setOpen] = useState(true);
  const api = useApi();

  useEffect(() => {
    const onHashChange = () => setOpen(window.location.hash === "#order");
    window.addEventListener("hashchange", onHashChange);
    return () => window.removeEventListener("hashchange", onHashChange);
  }, []);

  function handleOpen() {
    window.location.hash = "#order";
  }

  function handleClose() {
    switchMode("balance");
    window.history.back();
  }

  useEffect(() => {
    if (!open) {
      switchMode("balance", -1);
    }
  }, [open, switchMode]);

  useEffect(() => {
    handleOpen();
  }, []);

  function handleQtyChange(event) {
    setQty(event.target.value);
  }

  function handleMessageChange(event) {
    setMessage(event.target.value);
  }

  function handleOrderNoChange(event) {
    setOrderNo(event.target.value);
  }

  function handleOrder(event) {
    setIsLoading(true);
    event.preventDefault();
    const orderReq = {
      qty: qty,
      customerOrderNo: orderNo,
      message: message,
    };

    api
      .fetch(
        `${process.env.REACT_APP_MAIN_URL}licenses/elearning/${props.eLearningType}/order`,
        orderReq,
        "POST",
        false,
        true
      )
      .then((data) => {
        if (data.isSuccessful) {
          setQty("");
          setOrderNo("");
          setMessage("");
          setIsAutoOrderOk(data.autoOrderIsOk ?? false);
          setSuccessDialog(true);
        }
      })
      .finally((f) => {
        setIsLoading(false);
      });
  }

  return (
    <div>
      <Dialog open={true}>
        {isLoading && <Preloader />}
        <div>
          <DialogTitle>
            {`${Translate.get("PlaceOrder")} ${Translate.getELearningType(
              props.eLearningType
            )}${
              props.eLearningType in pseudoELearningTypes
                ? ""
                : `-${Translate.get("Passwords").toLowerCase()}`
            }`}
          </DialogTitle>
          <form onSubmit={handleOrder}>
            <DialogContent>
              <TextField
                required={true}
                type="number"
                InputProps={{
                  inputProps: {
                    min: 1,
                  },
                }}
                onChange={handleQtyChange}
                variant="outlined"
                name="qty"
                value={qty}
                label={Translate.get("Quantity")}
                fullWidth
                margin="normal"
              ></TextField>{" "}
              <TextField
                onChange={handleOrderNoChange}
                name="orderNo"
                value={orderNo}
                label={Translate.get("YourOrderNo")}
                variant="outlined"
                fullWidth
                margin="normal"
              ></TextField>{" "}
              <TextField
                onChange={handleMessageChange}
                name="message"
                value={message}
                label={Translate.get("Message")}
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                margin="normal"
              ></TextField>{" "}
              <div style={{ marginTop: "8px" }} className="smallInfoText">
                {Translate.get("ProcessingTime")}
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                name="balance"
                value="-1"
                onClick={handleClose}
                variant="text"
              >
                {Translate.get("Cancel")}
              </Button>
              <Button type="submit" variant="contained">
                {Translate.get("PlaceOrder")}
              </Button>
            </DialogActions>
          </form>
        </div>
      </Dialog>

      <Dialog
        open={successDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {Translate.get("ThanksForYourOrder")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {isAutoOrderOk
              ? Translate.get("CheckLicenseBalanceIncreased")
              : Translate.get("OrderConfirmationWillBeSent")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
