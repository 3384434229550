import Dialog from "@mui/material/Dialog";
import PersonCrud from "./PersonCrud";

// This just wraps the PersonCrud component, to allow using it as a separate Dialog
// without DotMenu or SpeedMenu. This is needed in cases when there are multiple dialogs,
// that do not use the url to determine if it's open. Dot/SpeedMenu can only close
// by using the url and reloading the page.
export default function PersonCrudDialog({ open, personCrudProps }) {
  return (
    <Dialog
      maxWidth={false}
      className="stdDialog"
      scroll="paper"
      onClose={personCrudProps.onCancel ?? personCrudProps.onClose}
      open={open}
    >
      <PersonCrud {...personCrudProps} />
    </Dialog>
  );
}
