import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Drawer, Stack } from '@mui/material';
// components
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
//
import getSidebarConfig from './SidebarConfig';
import { useApi } from "../../utils/Api";
import { useState } from "react";
import { setWithExpiry, getWithExpiry } from '../../utils/sessionStorageWithExpiry';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: 0
  }
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const api = useApi();
  const [logo, setLogo] = useState(null);

  useEffect(async () => {
    if (isOpenSidebar) {
      sessionStorage.removeItem("paginationModel_courses");
      sessionStorage.removeItem("paginationModel_permits");
      onCloseSidebar();
    }
  }, [pathname]);

  useEffect(async () => {
    await getLogo()
  }, []);

  async function getLogo() {

    var cachedLogo = getWithExpiry("logoURL");
    if (cachedLogo !== null) {
      setLogo(cachedLogo);
      return;
    }

    const logoURL = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}dataids/blobs/logo/url`,
      false,
      "GET"
    );
    
    if (logoURL) {
      setLogo(logoURL);
      setWithExpiry("logoURL", logoURL, 23*1000*60*60);
    }  
  }
  
  const renderContent = useMemo(() => (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
      </Box>

      <NavSection navConfig={getSidebarConfig()} />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack
          alignItems="center"
          spacing={3}
          sx={{
            p: 2.5,
            pt: 5,
            borderRadius: 2,
            position: 'relative',
            bgcolor: 'grey.200'
          }}

        >
         {logo && <img key={logo} style={{
            maxHeight: "100px",
            height: "70%",
            width: "auto",
          }} src={logo} alt="Company logotype" loading="lazy"></img>}
        </Stack>
      </Box>
    </Scrollbar>
  ), [logo]);

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
