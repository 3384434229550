import { Button, CircularProgress, Tooltip } from "@mui/material";
import { useState, useRef } from "react";
import Translate from "../utils/Translate";
import ConditionalWrapper from "./ConditionalWrapper";

export default function ButtonWithSpinner({
  onClick,
  children,
  tooltip,
  ...rest
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [buttonWidth, setButtonWidth] = useState(null);
  const buttonRef = useRef(null);

  async function handleClick() {
    setButtonWidth(buttonRef.current.offsetWidth);
    setIsLoading(true);
    await onClick();
    setButtonWidth(null);
    setIsLoading(false);
  }

  return (
    <ConditionalWrapper
      condition={tooltip}
      wrapper={(children) => <Tooltip title={tooltip}>{children}</Tooltip>}
    >
      <Button
        {...rest}
        onClick={handleClick}
        ref={buttonRef}
        sx={buttonWidth ? { width: `${buttonWidth}px` } : {}}
      >
        {isLoading ? <CircularProgress color="inherit" size={23} /> : children}
      </Button>
    </ConditionalWrapper>
  );
}
