import SchoolIcon from "@mui/icons-material/School";
import GroupIcon from "@mui/icons-material/Group";
import ErrorIcon from "@mui/icons-material/Error";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DescriptionIcon from "@mui/icons-material/Description";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import PreviewIcon from "@mui/icons-material/Preview";
import Translate from "../../utils/Translate";
import { formatInsertArguments } from "../../utils/formatString";

export function notificationTypeIcon(notificationType) {
  switch (notificationType) {
    case 1:
      return <SchoolIcon sx={{ mr: 1 }} />;
    case 2:
      return <SchoolIcon sx={{ mr: 1 }} />;
    case 3:
      return <SchoolIcon sx={{ mr: 1 }} />;
    case 4:
      return <CalendarTodayIcon sx={{ mr: 1 }} />;
    case 5:
      return <GroupIcon sx={{ mr: 1 }} />;
    case 6:
      return <SchoolIcon sx={{ mr: 1 }} />;
    case 7:
      return <ErrorIcon sx={{ mr: 1, color: "red" }} />;
    case 8:
      return <SchoolIcon sx={{ mr: 1 }} />;
    case 10:
      return <DescriptionIcon sx={{ mr: 1 }} />;
    case 11:
      return <ErrorIcon sx={{ mr: 1, color: "red" }} />;
    case 12:
      return <ReceiptLongIcon sx={{ mr: 1 }} />;
    case 13:
      return <CalendarTodayIcon sx={{ mr: 1 }} />;
    case 17:
      return <PreviewIcon sx={{ mr: 1 }} />;
    default:
      return <BrokenImageIcon sx={{ mr: 1, color: "orange" }} />;
  }
}

function timeSince(date) {
  var offset = new Date().getTimezoneOffset();
  let seconds = Math.floor((new Date() - date) / 1000) + offset * 60;

  let interval = Math.floor(seconds / 31536000);
  if (interval >= 1) {
    return { unit: getUnitForm("Years", interval), interval: interval };
  }
  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) {
    return { unit: getUnitForm("Months", interval), interval: interval };
  }
  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    return { unit: getUnitForm("Days", interval), interval: interval };
  }
  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return { unit: getUnitForm("Hours", interval), interval: interval };
  }
  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    return { unit: getUnitForm("Minutes", interval), interval: interval };
  }
  return { unit: "Now", interval: Math.abs(interval) };
}

function getUnitForm(pluralForm, interval) {
  if (interval === 1) {
    switch (pluralForm) {
      case "Years":
        return "Year";
      case "Months":
        return "Month";
      case "Days":
        return "Day";
      case "Hours":
        return "Hour";
      case "Minutes":
        return "Minute";
      default:
        return "Now";
    }
  }
  return pluralForm;
}

export function formatDate(date) {
  let obj = timeSince(date);
  if (obj.unit === "Now") {
    return Translate.get(obj.unit);
  }
  return (
    obj.interval +
    " " +
    Translate.get(obj.unit).toLowerCase() +
    " " +
    Translate.get("Ago").toLowerCase()
  );
}

export function getCourseId(message) {
  let messageText;
  try {
    messageText = JSON.parse(message.messageText);
  } catch (e) {
    return -1;
  }
  return messageText.CourseId;
}

function getActorPermitId(message) {
  let messageText;
  try {
    messageText = JSON.parse(message.messageText);
  } catch (e) {
    return -1;
  }
  return messageText.ActorPermitId;
}

function getActorPermitGroupId(message) {
  let messageText;
  try {
    messageText = JSON.parse(message.messageText);
  } catch (e) {
    return -1;
  }
  return messageText.ActorPermitGroupId;
}

function getPermitDriverName(message) {
  let messageText;
  try {
    messageText = JSON.parse(message.messageText);
  } catch (e) {
    return "";
  }
  return messageText.DriverName;
}

function getPermitDriverActorId(message) {
  let messageText;
  try {
    messageText = JSON.parse(message.messageText);
  } catch (e) {
    return "";
  }
  return messageText.DriverActorId;
}

function getPermitResponsibleName(message) {
  let messageText;
  try {
    messageText = JSON.parse(message.messageText);
  } catch (e) {
    return "";
  }
  return messageText.ResponsibleName;
}

export function getParticipantActorId(message) {
  let messageText;
  try {
    messageText = JSON.parse(message.messageText);
  } catch (e) {
    return -1;
  }
  if (messageText?.Participants) {
    return messageText.Participants[0]?.ParticipantActorId;
  }
  return null;
}

export function getRedicrectionLink(row) {
  switch (row.notificationType) {
    case 1:
    case 2:
    case 3:
    case 5:
    case 6:
    case 7:
    case 8:
    case 11: {
      const courseId = getCourseId(row);
      if (courseId > 0) {
        return "/dashboard/course/" + courseId;
      }
      return "#";
    }

    case 4: {
      const participantActorId = getParticipantActorId(row);
      if (participantActorId > 0) {
        return "/dashboard/person/" + participantActorId;
      }
      return "#";
    }

    case 10:
      return "/dashboard/instructormaterial";

    case 12: {
      const actorPermitId = getActorPermitId(row);
      const isGroup = actorPermitId <= 0;
      const driverActorId = getPermitDriverActorId(row);

      // We check for driverActorId because there may still be old notification messages in the db that don't have this new value.
      // If that's the case we navigate to the permit list as before. Eventually we should be able to skip that check.
      return isGroup
        ? `/dashboard/permits?defaultSearch=${getPermitResponsibleName(
            row
          )}`
        : driverActorId
        ? `/dashboard/person/${driverActorId}`
        : `/dashboard/permits?defaultSearch=${getPermitDriverName(row)}`;
    }
    
    case 13:
    case 14:
    case 15: {
      if (row.notificationType === 13) {
        const actorPermitId = getActorPermitId(row);
        const isGroup = actorPermitId <= 0;
        const driverActorId = getPermitDriverActorId(row);

        // We check for driverActorId because there may still be old notification messages in the db that don't have this new value.
        // If that's the case we navigate to the permit list as before. Eventually we should be able to skip that check.
        return isGroup
          ? `/dashboard/permits?defaultSearch=${getPermitResponsibleName(row)}&std-filter=permitExpires`
          : driverActorId
          ? `/dashboard/person/${driverActorId}`
          : `/dashboard/permits?defaultSearch=${getPermitDriverName(row)}&std-filter=permitExpires`;
      }
    }

    case 17:
      return "/dashboard/permits?std-filter=reviewNeeded";

    default:
      return "#";
  }
}

export function parseMessage(message) {
  let messageText;
  try {
    messageText = JSON.parse(message.messageText);
  } catch (e) {
    message.notificationType = -1;
    return Translate.get("UnableToParseMessage");
  }

  switch (message.notificationType) {
    case 1:
      return NotStartedELearning(messageText);
    case 2:
      return NotCompletedHalfELearning(messageText);
    case 3:
      return AllExercisesDone(messageText);
    case 4:
      return LicenseExpires(messageText);
    case 5:
      return MinCourseParticipantsNotSatisfied(messageText);
    case 6:
      return CompletedELearning(messageText);
    case 7:
      return UndeliverableEmail(messageText);
    case 8:
      return CompletedELearningFast(messageText);
    case 10:
      return InstructorMaterialUpdate(messageText);
    case 11:
      return UndeliverableSms(messageText);
    case 12:
      return NotSignedActorPermitOrGroup(messageText);
    case 13:
      return ExpiringActorPermit(messageText);
    case 17:
      return ActorPermitReviewIsDue(messageText);
    default:
      return "Stöd för meddelandetypen har inte implementerats ännu";
  }
}

function NotStartedELearning(messageText) {
  let participants = messageText.Participants.filter(
    (messageText) => messageText.Name != null
  );
  let participantNames = "";

  if (participants.length > 0) {
    for (let i = 0; i < participants.length; i++) {
      participantNames += participants[i].Name;
      if (i === participants.length - 1) {
        continue;
      }
      if (i === participants.length - 2) {
        participantNames += " " + Translate.get("And").toLowerCase() + " ";
        continue;
      }
      participantNames += ", ";
    }
    if (participants.length > 1) {
      return formatInsertArguments(
        Translate.get("NotStartedELearningNotification"),
        participantNames,
        messageText.Description +
          (messageText.ShortComment ? " " + messageText.ShortComment : ""),
        messageText.FromDate.replace(/T/, " ").slice(0, 10)
      );
    } else {
      return formatInsertArguments(
        Translate.get("NotStartedELearningNotificationSingular"),
        participantNames,
        messageText.Description +
          (messageText.ShortComment ? " " + messageText.ShortComment : ""),
        messageText.FromDate.replace(/T/, " ").slice(0, 10)
      );
    }
  } else {
    return formatInsertArguments(
      Translate.get("NobodyHasSignedIn"),
      messageText.Description +
        (messageText.ShortComment ? " " + messageText.ShortComment : " "),
      messageText.FromDate.replace(/T/, " ").slice(0, 10)
    );
  }
}

function NotCompletedHalfELearning(messageText) {
  let participants = messageText.Participants.filter(
    (messageText) => messageText.Name != null
  );
  let participantNames = "";

  if (participants.length > 0) {
    for (let i = 0; i < participants.length; i++) {
      participantNames += participants[i].Name;
      if (i === participants.length - 1) {
        continue;
      }
      if (i === participants.length - 2) {
        participantNames += " " + Translate.get("And").toLowerCase() + " ";
        continue;
      }
      participantNames += ", ";
    }
    if (participants.length > 1) {
      return formatInsertArguments(
        Translate.get("NotCompletedHalfELearningNotification"),
        participantNames,
        messageText.Description +
          (messageText.ShortComment ? " " + messageText.ShortComment : ""),
        messageText.FromDate.replace(/T/, " ").slice(0, 10)
      );
    } else {
      return formatInsertArguments(
        Translate.get("NotCompletedHalfELearningNotificationSingular"),
        participantNames,
        messageText.Description +
          (messageText.ShortComment ? " " + messageText.ShortComment : ""),
        messageText.FromDate.replace(/T/, " ").slice(0, 10)
      );
    }
  } else {
    return formatInsertArguments(
      Translate.get("NobodyHasCompletedHalf"),
      messageText.Description +
        (messageText.ShortComment ? " " + messageText.ShortComment : " "),
      messageText.FromDate.replace(/T/, " ").slice(0, 10)
    );
  }
}

function AllExercisesDone(messageText) {
  return formatInsertArguments(
    Translate.get("AllExercisesDoneForm"),
    messageText.ParticipantData.Name ?? "-",
    messageText.Description +
      (messageText.ShortComment ? " " + messageText.ShortComment : ""),
    messageText.FromDate.replace(/T/, " ").slice(0, 10)
  );
}

function LicenseExpires(messageText) {
  return formatInsertArguments(
    Translate.get("LicenseExpiresFormat"),
    messageText.Participants[0].Name,
    messageText.Participants[0].Company,
    messageText.LicenseDescription,
    messageText.LatesLicenseValidTo.replace(/T/, " ").slice(0, 10)
  );
}

function MinCourseParticipantsNotSatisfied(messageText) {
  return formatInsertArguments(
    Translate.get("MinCourseParticipantsNotSatisfiedNotification"),
    messageText.CourseShortName +
      (messageText.ShortComment ? " " + messageText.ShortComment : ""),
    messageText.StartDate.replace(/T/, " ").slice(0, 10),
    messageText.BookedParticipants,
    messageText.MaxParticipants
  );
}

function CompletedELearning(messageText) {
  return formatInsertArguments(
    Translate.get("FinalExamDoneNotification"),
    messageText.ParticipantData.Name ?? "-",
    messageText.Description +
      (messageText.ShortComment ? " " + messageText.ShortComment : ""),
    messageText.FromDate.replace(/T/, " ").slice(0, 10),
    messageText.FinalExamScore
  );
}

function UndeliverableEmail(messageText) {
  return formatInsertArguments(
    Translate.get("EmailBounced"),
    messageText.RecieverName ?? "-",
    messageText.Email ?? "-",
    messageText.Reason ?? "-"
  );
}

function CompletedELearningFast(messageText) {
  return formatInsertArguments(
    Translate.get("CompletedELearningFastForm"),
    messageText.ParticipantData.Name ?? "-",
    messageText.Description +
      (messageText.ShortComment ? " " + messageText.ShortComment : ""),
    messageText.FromDate.replace(/T/, " ").slice(0, 10)
  );
}

function InstructorMaterialUpdate(messageText) {
  return formatInsertArguments(
    Translate.get("InstructorMaterialText"),
    messageText.Filename ?? "-",
    messageText.Category ?? 0
  );
}

function UndeliverableSms(messageText) {
  return formatInsertArguments(
    Translate.get("SmsBounced"),
    messageText.RecieverName ?? "-",
    messageText.Phone ?? "-",
    messageText.ReasonTranslationKey
      ? Translate.get(messageText.ReasonTranslationKey)
      : messageText.Reason ?? "-"
  );
}

function NotSignedActorPermitOrGroup(messageText) {
  const isGroup = !messageText.ActorPermitId;
  if (isGroup) {
    return formatInsertArguments(
      Translate.get("StillWaitingPermitGroupSign"),
      messageText.ResponsibleName ?? "-"
    );
  } else {
    return formatInsertArguments(
      Translate.get("StillWaitingPermitSign"),
      messageText.DriverName
    );
  }
}

function ExpiringActorPermit(messageText) {
  return formatInsertArguments(
    Translate.get("ExpiringActorPermitFormat"),
    messageText.DriverName,
    messageText.SkillGroupDescription?.toLowerCase(),
    messageText.ValidTo.replace(/T/, " ").slice(0, 10)
  );
}

function ActorPermitReviewIsDue(messageText) {
  return Translate.get("ActorPermitsNeedReview");
}
