import AttachmentIcon from "@mui/icons-material/Attachment";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import {
  useMediaQuery,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TextField,
  Avatar,
} from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import download from "downloadjs";
import React, { useState } from "react";
import DotMenu from "../../../components/DotMenu";
import api from "../../../utils/Api";
import { eLearningTypes } from "../../../utils/eLearningTypes";
import Translate from "../../../utils/Translate";
import Preloader from "../../Preloader";
import HtmlEditor from "../../HtmlEditor";
import { useSnackbar } from "notistack";
import WarningIcon from "@mui/icons-material/Warning";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { stringToColor } from "../../../utils/formatString";
import PersonCrud from "../../actors/crud/PersonCrud";

const genericErrorMessageKey = "SomethingFailed";

const actions = Object.freeze({
  sendToParticipants: "sendToParticipants",
  sendToContacts: "sendToContacts",
});

export const messageTypes = Object.freeze({
  eLearningInvitation: "eLearningInvitation",
  extraExamInfo: "extraExamInfo",
});

const sendInvitations = (action, courseBookings, messageType) => {
  let fetchCall;
  if (action === actions.sendToParticipants) {
    const postObj = {
      emails: courseBookings.flatMap((cb) =>
        cb.courseParticipants
          .filter((cp) => !!cp.email)
          .map((cp) => ({
            eLearningLicenseId: cp.eLearningLicenseId,
            emailSubject: cp.emailSubject,
            htmlBody: cp.htmlBody,
          }))
      ),
    };

    let url;
    switch (messageType) {
      case messageTypes.eLearningInvitation:
        url = `${process.env.REACT_APP_MAIN_URL}licenses/elearning/invitations/to-students`;
        break;
      case messageTypes.extraExamInfo:
        url = `${process.env.REACT_APP_MAIN_URL}licenses/elearning/extra-exam-invitations/to-students`;
        break;
      default:
        throw new Error("Invalid message type " + messageType);
    }

    fetchCall = api.fetch(url, postObj);
  } else if (action === actions.sendToContacts) {
    const postObj = {
      emails: courseBookings
        .filter((cb) => !!cb.contactActorEmail)
        .map((cb) => ({
          contactActorEmail: cb.contactActorEmail,
          contactActorName: cb.contactActorName,
          courseBookingId: cb.courseBookingId,
          languageCode: cb.languageCode,
          emailSubject: cb.emailSubject,
          htmlBody: cb.htmlBody,
          eLearningLicenses: cb.courseParticipants.map((cp) => ({
            eLearningLicenseId: cp.eLearningLicenseId,
          })),
        })),
    };

    fetchCall = api.fetch(
      `${process.env.REACT_APP_MAIN_URL}licenses/elearning/invitations/to-contacts`,
      postObj
    );
  } else {
    throw new Error("Undefined action");
  }

  return fetchCall.then(
    (response) => {
      if (response.isSuccessful) {
        return Promise.resolve();
      }
      return Promise.reject(
        response.errorMessageTranslationKey ?? genericErrorMessageKey
      );
    },
    () => Promise.reject(genericErrorMessageKey)
  );
};

function getStudentInvitationText(eLearningLicenseId, messageType) {
  let url;
  switch (messageType) {
    case messageTypes.eLearningInvitation:
      url = `${process.env.REACT_APP_MAIN_URL}licenses/elearning/${eLearningLicenseId}/invitation/to-student`;
      break;
    case messageTypes.extraExamInfo:
      url = `${process.env.REACT_APP_MAIN_URL}licenses/elearning/${eLearningLicenseId}/extra-exam-invitation/to-student`;
      break;
    default:
      throw new Error("Invalid message type " + messageType);
  }
  return api.fetch(url, null, "GET");
}

function getContactInvitationText(courseBookingId, messageType) {
  if (messageType !== messageTypes.eLearningInvitation) {
    throw new Error("Not implemented");
  }

  return api.fetch(
    `${process.env.REACT_APP_MAIN_URL}licenses/elearning/booking/${courseBookingId}/invitation/to-contact`,
    null,
    "GET"
  );
}

const downloadInvitationPdf = (
  eLearningLicenseId,
  eLearningType,
  actorName
) => {
  if (!(eLearningType && eLearningTypes[eLearningType])) {
    throw new Error("Invalid eLearningType");
  }
  actorName = actorName?.trim();
  const key = `HereIsYourPersonalCode${eLearningTypes[eLearningType]}`;
  let fileName = Translate.get(key);
  if (actorName) {
    fileName += ` ${actorName}`;
  }
  fileName += ".pdf";

  return api
    .fetchBlob(
      `${process.env.REACT_APP_MAIN_URL}licenses/elearning/pdf/${eLearningLicenseId}`
    )
    .then((blob) => download(blob, fileName, "application/pdf"));
};

const downloadContactInvitationPdf = (courseBookingId) => {
  let fileName = Translate.get("PasswordsConcatenated");
  fileName += ".pdf";

  return api
    .fetchBlob(
      `${process.env.REACT_APP_MAIN_URL}licenses/elearning/pdf/all/${courseBookingId}`
    )
    .then((blob) => download(blob, fileName, "application/pdf"));
};

const countParticipants = (courseBookings) =>
  courseBookings.flatMap((x) => x.courseParticipants.filter((cp) => !!cp.email))
    .length;

/* This is what we want : {
  courseId: -1, 
  action: "sendToParticipants" / "sendToContacts"
  courseBookings: [
    courseBookingId: -2,
    companyName: "Acme Ltd",
    contactActorName: "Bugs Bunny", // not needed if sendParticipants
    contactActorEmail: "bugs@acme.com", // not needed if sendParticipants
    licenses: [
      {
        eLearningLicenseId: "aUtxcfrrd",
        actorName: "Foo", // not needed if sendToContacts
        actorEmail: "foo@bar.baz", // not needed if sendToContacts
        language: 29
      }
    ]
  ]
} */

const SendPassword = (props) => {
  if (
    props.action === actions.sendToContacts &&
    props.messageType === messageTypes.extraExamInfo
  ) {
    throw new Error("Not implemented");
  }

  const [processing, setProcessing] = useState(false);
  const [
    editingELearningLicenseIdMessage,
    setEditingELearningLicenseIdMessage,
  ] = useState(null);
  const [editingCourseBookingIdMessage, setEditingCourseBookingIdMessage] =
    useState(0);
  const [invitationSubject, setInvitationSubject] = useState("");
  const [htmlMessage, setHtmlMessage] = useState("");
  const [editedHtmlMessage, setEditedHtmlMessage] = useState(null);
  const [attachementImages, setAttachmentImages] = useState([]);
  const [courseBookings, setCourseBookings] = useState(props.courseBookings);
  const [messageType] = useState(
    props.messageType ?? messageTypes.eLearningInvitation
  );
  const bigScreen = useMediaQuery("(min-width:1200px)");

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const closeAndReload = () => {
    window.history.back();
    props.loadCourse();
  };

  //----------

  const setFailedToGetText = () => {
    const errorMessage = enqueueSnackbar(
      Translate.get(genericErrorMessageKey),
      {
        variant: "error",
        autoHideDuration: 6000,
        onClick: () => closeSnackbar(errorMessage),
      }
    );

    // jump out of editing mode
    setEditingELearningLicenseIdMessage(false);
    setEditingCourseBookingIdMessage(false);
  };

  const editInivtation = (id) => {
    setProcessing(true);
    if (props.action === actions.sendToParticipants) {
      const eLearningLicenseId = id;
      setEditingELearningLicenseIdMessage(eLearningLicenseId);
      const participant = courseBookings
        .flatMap((x) => x.courseParticipants)
        .find(
          (x) =>
            JSON.stringify(x.eLearningLicenseId) ===
            JSON.stringify(eLearningLicenseId)
        );

      if (!participant.htmlBody) {
        getStudentInvitationText(eLearningLicenseId, messageType)
          .then((response) => {
            if (response.isSuccessful !== false) {
              setInvitationSubject(response.emailSubject);
              setAttachmentImages(response.attachementImages);
              setHtmlMessage(response.htmlBody);
            } else {
              setFailedToGetText();
            }
          })
          .finally(() => setProcessing(false));
      } else {
        setInvitationSubject(participant.emailSubject);
        // setInvitationText(participant.htmlBody);
        setHtmlMessage(participant.htmlBody);
        setProcessing(false);
      }
    } else if (props.action === actions.sendToContacts) {
      const courseBookingId = Number(id);
      setEditingCourseBookingIdMessage(courseBookingId);
      const booking = courseBookings.find(
        (x) => x.courseBookingId === courseBookingId
      );

      if (!booking?.htmlBody) {
        getContactInvitationText(courseBookingId, messageType)
          .then((response) => {
            if (response.isSuccessful !== false) {
              setInvitationSubject(response.emailSubject);
              setAttachmentImages(response.attachementImages);
              setHtmlMessage(response.htmlBody);
            } else {
              setFailedToGetText();
            }
          })
          .finally(() => setProcessing(false));
      } else {
        setInvitationSubject(booking.emailSubject);
        setHtmlMessage(booking.htmlBody);
        setProcessing(false);
      }
    } else {
      setProcessing(false);
      throw new Error("Undefined action");
    }
  };

  const downloadInvitation = (eLearningLicenseId, eLearningType, actorName) => {
    setProcessing(true);
    downloadInvitationPdf(eLearningLicenseId, eLearningType, actorName)
      .catch(() => {
        const errorMessage = enqueueSnackbar(
          Translate.get(genericErrorMessageKey),
          {
            variant: "error",
            autoHideDuration: 6000,
            onClick: () => closeSnackbar(errorMessage),
          }
        );
      })
      .finally(() => setProcessing(false));
  };

  const downloadContactInvitation = (courseBookingId) => {
    setProcessing(true);
    downloadContactInvitationPdf(courseBookingId)
      .catch(() => {
        const errorMessage = enqueueSnackbar(
          Translate.get(genericErrorMessageKey),
          {
            variant: "error",
            autoHideDuration: 6000,
            onClick: () => closeSnackbar(errorMessage),
          }
        );
      })
      .finally(() => setProcessing(false));
  };

  const removeParticipant = (eLearningLicenseId) => {
    const updatedCourseBookings = courseBookings.map((currentBooking) => ({
      ...currentBooking,
      courseParticipants: currentBooking.courseParticipants.filter(
        (x) => x.eLearningLicenseId !== eLearningLicenseId
      ),
    }));

    if (countParticipants(updatedCourseBookings) < 1) {
      closeAndReload(); // no more participants left; close dialog
    }

    setCourseBookings(updatedCourseBookings);
  };

  const removeContact = (courseBookingId) => {
    const updatedCourseBookings = courseBookings.filter(
      (x) => x.courseBookingId !== courseBookingId
    );

    if (countParticipants(updatedCourseBookings) < 1) {
      closeAndReload(); // no more participants left; close dialog
    }

    setCourseBookings(updatedCourseBookings);
  };

  // OK in pane for editing message
  const editMessageOk = () => {
    updateBookingMessage(editedHtmlMessage);
    setEditingELearningLicenseIdMessage(null);
    setEditingCourseBookingIdMessage(0);
  };

  // update message for single participant / contact
  const updateBookingMessage = (htmlContent) => {
    let updatedCourseBookings;
    if (
      props.action === actions.sendToParticipants &&
      editingELearningLicenseIdMessage
    ) {
      updatedCourseBookings = courseBookings.map((currentBooking) => ({
        ...currentBooking,
        courseParticipants: currentBooking.courseParticipants.map(
          (participant) =>
            JSON.stringify(participant.eLearningLicenseId) ===
            JSON.stringify(editingELearningLicenseIdMessage)
              ? {
                  ...participant,
                  htmlBody: htmlContent,
                  emailSubject: invitationSubject,
                }
              : { ...participant }
        ),
      }));
    } else if (
      props.action === actions.sendToContacts &&
      editingCourseBookingIdMessage
    ) {
      updatedCourseBookings = courseBookings.map((currentBooking) =>
        currentBooking.courseBookingId === editingCourseBookingIdMessage
          ? {
              ...currentBooking,
              htmlBody: htmlContent,
              emailSubject: invitationSubject,
            }
          : {
              ...currentBooking,
            }
      );
    } else {
      throw new Error("Undefined action");
    }
    setCourseBookings(updatedCourseBookings);
  };

  const sendClick = () => {
    setProcessing(true);
    sendInvitations(props.action, courseBookings, messageType)
      .then(
        () => closeAndReload(),
        (errorMessageTranslationKey) => {
          const errorMessage = enqueueSnackbar(
            Translate.get(errorMessageTranslationKey),
            {
              variant: "error",
              autoHideDuration: 6000,
              onClick: () => closeSnackbar(errorMessage),
              PersonCrud,
            }
          );
        }
      )
      .finally(() => setProcessing(false));
  };

  const cancelMessageEdit = () => {
    setEditingELearningLicenseIdMessage(null);
    setEditingCourseBookingIdMessage(0);
  };

  function canSend() {
    return props.action === actions.sendToParticipants
      ? courseBookings.some((cb) =>
          cb.courseParticipants.some((cp) => !!cp.email)
        )
      : courseBookings.some((cb) => !!cb.contactActorEmail);
  }
  console.log("courseBookings", courseBookings);
  return (
    //Allt innehåll i dialog förutom Dialog + DialogHeader
    <>
      {processing ? <Preloader /> : null}
      <DialogContent dividers={true}>
        {!processing &&
        (editingELearningLicenseIdMessage || editingCourseBookingIdMessage) ? ( // edit message mode
          <>
            <TextField
              sx={{ width: 1, mb: 2, mt: 2 }}
              label={Translate.get("Subject")}
              value={invitationSubject}
              onChange={(evt) => setInvitationSubject(evt.target.value)}
            />
            <HtmlEditor
              label={Translate.get("Message")}
              htmlContent={htmlMessage}
              images={attachementImages}
              onBlur={setEditedHtmlMessage}
            />
          </>
        ) : (
          <>
            {courseBookings
              .sort((a, b) => a.companyName.localeCompare(b.companyName))
              .map((cb) => (
                <>
                  <div className="smallHeader topBottom16">
                    {cb.companyName?.toUpperCase()} (
                    {cb.courseParticipants.length})
                  </div>
                  {}
                  {props.action === actions.sendToParticipants ? (
                    cb.courseParticipants
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((cp, index) => (
                        <div key={index} className="stdList">
                          <div className="tableAvatar">
                            <Avatar
                              alt={cp.name}
                              src={cp.profilePictureUrl}
                              sx={{ bgcolor: stringToColor(cp.name) }}
                            ></Avatar>
                            <div>
                              <div className="tableAvatarTitle bold">
                                {cp.name}
                              </div>
                              <div>
                                {cp.email ? (
                                  cp.email
                                ) : (
                                  <div className="smallRedWarning">
                                    <WarningIcon fontSize="8" />
                                    <div> {Translate.get("InvalidEmail")}</div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <DotMenu
                            content={[
                              {
                                icon: <EditIcon />,
                                text: Translate.get("EditMessage"),
                                onClick: () =>
                                  editInivtation(cp.eLearningLicenseId),
                                disabled: !cp.email,
                              },
                              {
                                icon: <EditIcon />,
                                text: Translate.get("EditPersonalInfo"),
                                route: false,
                                disabled: !cp.actorId,
                                component: (
                                  <PersonCrud
                                    actorId={cp.actorId}
                                    isEditOnly={true}
                                    isMatchingActorsDisabled={true}
                                  />
                                ),
                              },
                              {
                                icon: <PictureAsPdfIcon />,
                                text: Translate.get("DownloadAttachment"),
                                hide:
                                  messageType === messageTypes.extraExamInfo,
                                onClick: () =>
                                  downloadInvitation(
                                    cp.eLearningLicenseId,
                                    props.eLearningType,
                                    cp.name
                                  ),
                              },
                              {
                                icon: <CloseIcon />,
                                text: Translate.get("RemoveRecipient"),
                                hide:
                                  messageType === messageTypes.extraExamInfo,
                                onClick: () =>
                                  removeParticipant(cp.eLearningLicenseId),
                                disabled: !cp.email,
                              },
                            ]}
                          />
                        </div>
                      ))
                  ) : (
                    <div className="stdList">
                      <div className="tableAvatar">
                        <Avatar
                          alt={cb.contactActorName}
                          src={cb.contactProfilePictureUrl}
                          sx={{ bgcolor: stringToColor(cb.contactActorName) }}
                        ></Avatar>
                        <div>
                          <div className="tableAvatarTitle bold">
                            {cb.contactActorName}
                          </div>
                          <div>
                            {cb.contactActorEmail ? (
                              cb.contactActorEmail
                            ) : (
                              <div className="smallRedWarning">
                                <WarningIcon fontSize="8" />
                                <div> {Translate.get("InvalidEmail")}</div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <DotMenu
                        content={[
                          {
                            icon: <EditIcon />,
                            text: Translate.get("EditMessage"),
                            onClick: () => editInivtation(cb.courseBookingId),
                            disabled: !cb.contactActorEmail,
                          },
                          {
                            icon: <EditIcon />,
                            text: Translate.get("EditContact"),
                            route: false,
                            component: (
                              <PersonCrud actorId={cb.contactActorId} />
                            ),
                          },
                          {
                            icon: <PictureAsPdfIcon />,
                            text: Translate.get("DownloadPasswords"),
                            onClick: () =>
                              downloadContactInvitation(cb.courseBookingId),
                          },
                          {
                            icon: <CloseIcon />,
                            text: Translate.get("RemoveRecipient"),
                            onClick: () => removeContact(cb.courseBookingId),
                            disabled: !cb.contactActorEmail,
                          },
                        ]}
                      />
                    </div>
                  )}
                </>
              ))}
          </>
        )}
      </DialogContent>
      <DialogActions>
        {editingELearningLicenseIdMessage || editingCourseBookingIdMessage ? ( // if editing message, show Cancel / OK for editing the message
          <>
            {" "}
            {/* we *are* in editing mode, show cancel / Send for *editing* the messages */}
            <Button onClick={cancelMessageEdit}>
              {Translate.get("Cancel")}
            </Button>
            <Button onClick={editMessageOk}>{Translate.get("Ready")}</Button>
          </>
        ) : (
          <>
            {" "}
            {/* *not* in editing mode, show cancel / Send for *sending* the messages */}
            <Button
              onClick={() =>
                props.reloadOnCancel ? closeAndReload() : window.history.back()
              }
            >
              {Translate.get("Cancel")}
            </Button>
            <Button
              variant="contained"
              onClick={sendClick}
              disabled={!canSend()}
            >
              {Translate.get("Send")}
            </Button>
          </>
        )}
      </DialogActions>
    </>
  );
};

export default SendPassword;
