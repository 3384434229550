import Translate from "../../../utils/Translate";
import { Button } from "@mui/material";
import KeyIcon from "@mui/icons-material/Key";
import BankIdAuthentication from "../../bankId/BankIdAuthentication";
import { useApi } from "../../../utils/Api";
import { useState } from "react";


let timeout;
const BankIdLoginForm = ({onLoginSuccess, startQrCodeOnDesktop}) => {
    const api = useApi();
    const [bankIdCancelUrl, setBankIdCancelUrl] = useState();

    const onAuthenticationSuccess = (loginType, response) => {
        localStorage.setItem("eportLoginMethod", "bankId_" + loginType); // spara för att visa bankidinloggning vid nästa inloggning
        const data = response.data;
        onLoginSuccess(data.name, data.email, data.accountLoginToken.token, data.users);
    }

    const onStatusInitiated = (bankIdStatusResponse) =>
        setBankIdCancelUrl(bankIdStatusResponse.cancelUrl);

    const cancelBankIdLogin = async () => {
        await api.fetch(bankIdCancelUrl, null, "POST", false);
    };


    return (
            <form className="loginForm">
                <BankIdAuthentication startAuthenticationUrl={`${process.env.REACT_APP_AUTH_URL}auth/bankid/login/courseadmin`}
                    onStatusInitiated={onStatusInitiated}
                    onAuthenticationSuccess={onAuthenticationSuccess}
                    startQrCodeOnDesktop={startQrCodeOnDesktop} />
                <Button href="/login" onClick={cancelBankIdLogin} startIcon={<KeyIcon />} sx={{marginTop: "2em"}}>
                    {Translate.get("LogInUsingEmail")}
                </Button>
            </form>
  );
}

export default BankIdLoginForm;