import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  FormControlLabel,
  FormHelperText,
  Select,
  MenuItem,
  Grid,
  TextField,
  Typography,
  Stack,
  Radio,
  RadioGroup,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Translate from "../../utils/Translate";
import { useEffect, useState, useRef, useCallback } from "react";
import { useApi } from "../../utils/Api";
import Preloader from "../Preloader";
import CardPrintAtMaSystemDialog from "./CardPrintAtMaSystemDialog";
import YesOrNoDialog from "../YesOrNoDialog";
import { useReactToPrint } from "react-to-print";
import "./ActorLicensePrint.css";
import ActorLicensePrintView from "./ActorLicensePrintView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ActorLicensePrintList from "./ActorLicensePrintList";
import { accessKeys, hasAccess } from "../../utils/userAccess";
import Bowser from "bowser";

const cr80CardWidth = 2048;
const cr80cardHeight = 1300;
const a4Width = 2480;
const a4Height = 3508;

const browser = Bowser.getParser(window.navigator.userAgent);
const isSafari = browser.isBrowser("Safari");

function getPrintTypeLabel(printType) {
  switch (printType) {
    case "card":
      return Translate.get("Licence");
    case "A4":
      return Translate.get("A4Document");
    case "diploma":
      return Translate.get("Diploma");
  }
}

function getCardDataUrl(courseId, actorLicenseId, includeAllSkills, printType) {
  if (actorLicenseId) {
    return includeAllSkills
      ? `${process.env.REACT_APP_MAIN_URL}licenseprint/${printType}/actorlicenses/${actorLicenseId}/all-skills`
      : `${process.env.REACT_APP_MAIN_URL}licenseprint/${printType}/actorlicenses/${actorLicenseId}`;
  } else {
    return includeAllSkills
      ? `${process.env.REACT_APP_MAIN_URL}licenseprint/${printType}/courses/${courseId}/all-skills`
      : `${process.env.REACT_APP_MAIN_URL}licenseprint/${printType}/courses/${courseId}`;
  }
}

async function loadActorLicensePreviews(
  api,
  courseId,
  actorLicenseId,
  includeAllSkills,
  printType
) {
  const url = getCardDataUrl(
    courseId,
    actorLicenseId,
    includeAllSkills,
    printType
  );
  return await api.fetch(url, false, "GET");
}

function getAvailablePrintTypesUrl(courseId, actorLicenseId) {
  if (actorLicenseId) {
    return `${process.env.REACT_APP_MAIN_URL}licenseprint/actorlicenses/${actorLicenseId}/print-types`;
  } else {
    return `${process.env.REACT_APP_MAIN_URL}licenseprint/courses/${courseId}/print-types`;
  }
}

async function loadAAvailablePrintTypes(api, courseId, actorLicenseId) {
  const url = getAvailablePrintTypesUrl(courseId, actorLicenseId);
  return await api.fetch(url, false, "GET");
}

async function actorLicensesHaveAllLatestSkills(api, courseId, actorLicenseId) {
  const url = courseId
    ? `${process.env.REACT_APP_MAIN_URL}courses/${courseId}/licenses/has-latest-skills`
    : `${process.env.REACT_APP_MAIN_URL}actors/actorlicenses/${actorLicenseId}/has-latest-skills`;
  return await api.fetch(url, false, "GET");
}

async function markAsPrinted(api, actorLicenseIds) {
  return await api.fetch(
    `${process.env.REACT_APP_MAIN_URL}licenseprint/actorlicenses/mark-as-printed`,
    actorLicenseIds,
    "POST"
  );
}

async function updateCourseCoordinatorName(
  api,
  actorLicenseIds,
  courseCoordinatorName
) {
  return await api.fetch(
    `${process.env.REACT_APP_MAIN_URL}actors/actorlicenses/course-coordinator`,
    { actorLicenseIds, courseCoordinatorName },
    "POST"
  );
}

// This dialog can be used either for a courseId (cards for the participants),
// or for an actorLicenseId (printing a single license separately from a course),
export default function ActorLicensePrintDialog({
  courseId,
  actorLicenseId,
  onClose,
}) {
  if (courseId && actorLicenseId) throw new Error("Invalid props");
  if (!courseId && !actorLicenseId) throw new Error("Invalid props");

  const [isLoadingCount, setIsLoadingCount] = useState(0);
  const [studentCardsDetails, setStudentCardsDetails] = useState(null);
  const [availablePrintTypes, setAvailablePrintTypes] = useState([]);
  const [cardPrintTemplate, setCardPrintTemplate] = useState(null);
  const [studentDiplomasDetails, setStudentDiplomasDetails] = useState(null);
  const [diplomaPrintTemplate, setDiplomaPrintTemplate] = useState(null);
  const [studentA4sDetails, setStudentA4sDetails] = useState(null);
  const [A4PrintTemplate, setA4PrintTemplate] = useState(null);
  const [includeAllLatestSkills, setIncludeAllLatestSkills] = useState(null);
  const [showPrintAtMaSystem, setShowPrintAtMaSystem] = useState(false);
  const [selectedActorLicenseIds, setSelectedActorLicenseIds] = useState(null);
  const [
    showIncludeAllLatestSkillsQuestion,
    setShowIncludeAllLatestSkillsQuestion,
  ] = useState(false);
  const [sidePrintMode, setSidePrintMode] = useState("both");
  const [printType, setPrintType] = useState(null);
  const [isPrintingOnOwnPrinterEnabled, setIsPrintingOnOwnPrinterEnabled] =
    useState(false);
  const [printWidth, setPrintWidth] = useState(cr80CardWidth);
  const [printHeight, setPrintHeight] = useState(cr80cardHeight);
  const [newCourseCoordinatorName, setNewCourseCoordinatorName] =
    useState(null);
  const [currentCourseCoordinatorName, setCurrentCourseCoordinatorName] =
    useState(null);
  const [printSideSettings, setPrintSideSettings] = useState([]);

  const [showCourseCoordinatorError, setShowCourseCoordinatorError] =
    useState(false);
  const [courseCoordinatorHelperText, setCourseCoordinatorHelperText] =
    useState("");

  const api = useApi();

  const printViewRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printViewRef.current,
    onAfterPrint: () => markAsPrinted(api, selectedActorLicenseIds),
    // I set the width and height of the page explicitly, and then do the same for the license to be printed.
    // This is because I couldn't get the license to size correctly by simply setting width and height 100%.
    // But it seems like it should be possible to make it completely dynamic using onBeforePrint and useRef.
    pageStyle: () =>
      !isSafari
        ? `
    @page {
      margin: 0 !important;
      size: ${printWidth}px ${printHeight}px;
    }
  `
        : `
    @page {
      margin: 0 !important;
      size: ${printWidth}px ${printHeight}px;
    }

    @media print {
      html,
      body {
        transform: scale(0.54);
        translate: -160px -240px;
      }
    }
  `,
  });

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  const getSelectedPrintTemplate = useCallback(() => {
    if (printType === "card") {
      return cardPrintTemplate;
    } else if (printType === "diploma") {
      return diplomaPrintTemplate;
    } else if (printType === "A4") {
      return A4PrintTemplate;
    }
  }, [printType, cardPrintTemplate, diplomaPrintTemplate, A4PrintTemplate]);

  const getStudentDetails = useCallback(() => {
    if (printType === "card") {
      return studentCardsDetails;
    } else if (printType === "diploma") {
      return studentDiplomasDetails;
    } else if (printType === "A4") {
      return studentA4sDetails;
    } else {
      throw new Error();
    }
  }, [
    printType,
    studentCardsDetails,
    studentDiplomasDetails,
    studentA4sDetails,
  ]);

  useEffect(() => {
    setIsLoadingCount((prevCount) => prevCount + 1);
    actorLicensesHaveAllLatestSkills(api, courseId, actorLicenseId).then(
      (response) => {
        if (response === true) {
          // No need to include all if licenses already have all by default
          setIncludeAllLatestSkills(false);
          setShowIncludeAllLatestSkillsQuestion(false);
        } else {
          setShowIncludeAllLatestSkillsQuestion(true);
        }
        setIsLoadingCount((prevCount) => prevCount - 1);
      }
    );
  }, [courseId, actorLicenseId, api]);

  useEffect(() => {
    setIsLoadingCount((prevCount) => prevCount + 1);
    loadAAvailablePrintTypes(api, courseId, actorLicenseId).then((response) => {
      if (Array.isArray(response)) {
        const sortOrder = ["card", "diploma", "A4"];
        response.sort(
          (a, b) =>
            sortOrder.findIndex((x) => x === a) -
            sortOrder.findIndex((x) => x === b)
        );
        setAvailablePrintTypes(response);
        const printType = response.includes("card") ? "card" : response[0];
        setPrintType(printType);
        setPrintWidth(printType  === "card" ? cr80CardWidth : a4Width);
        setPrintHeight(printType === "card" ? cr80cardHeight : a4Height);
      }
      setIsLoadingCount((prevCount) => prevCount - 1);
    });
  }, [courseId, actorLicenseId, api]);

  useEffect(() => {
    const selectedPrintTemplate = getSelectedPrintTemplate();
    if (
      !selectedPrintTemplate &&
      includeAllLatestSkills !== null &&
      !!printType &&
      availablePrintTypes.length > 0
    ) {
      setIsLoadingCount((prevCount) => prevCount + 1);
      loadActorLicensePreviews(
        api,
        courseId,
        actorLicenseId,
        includeAllLatestSkills,
        printType
      ).then((response) => {
        if (printType === "card") {
          setStudentCardsDetails(response.studentsPrintData);
          setCardPrintTemplate(response.printTemplate);
          setPrintSideSettings((oldArray) => [
            ...oldArray,
            { printType: "card", printSideSetting: response.printSideSetting },
          ]);
        } else if (printType === "diploma") {
          setStudentDiplomasDetails(response.studentsPrintData);
          setDiplomaPrintTemplate(response.printTemplate);
          setPrintSideSettings((oldArray) => [
            ...oldArray,
            {
              printType: "diploma",
              printSideSetting: response.printSideSetting,
            },
          ]);
        } else if (printType === "A4") {
          setStudentA4sDetails(response.studentsPrintData);
          setA4PrintTemplate(response.printTemplate);
          setPrintSideSettings((oldArray) => [
            ...oldArray,
            { printType: "A4", printSideSetting: response.printSideSetting },
          ]);
        }
        setIsPrintingOnOwnPrinterEnabled(
          response.isPrintingOnOwnPrinterEnabled
        );
        setSelectedActorLicenseIds(
          response.studentsPrintData.map((p) => p.actorLicenseId)
        );
        setNewCourseCoordinatorName(null);
        setCurrentCourseCoordinatorName(response.courseCoordinatorName);
        setSidePrintMode(response.printSideSetting ?? "both");
        setIsLoadingCount((prevCount) => prevCount - 1);
      });
    }
  }, [
    includeAllLatestSkills,
    courseId,
    actorLicenseId,
    printType,
    getSelectedPrintTemplate,
    api,
  ]);

  function handleIncludeAllSkillsChange(value) {
    setIncludeAllLatestSkills(value);
    setShowIncludeAllLatestSkillsQuestion(false);
  }

  function handleSelectStudentCardChanged(actorLicenseId) {
    if (selectedActorLicenseIds.find((c) => c === actorLicenseId)) {
      setSelectedActorLicenseIds(
        selectedActorLicenseIds.filter((c) => c !== actorLicenseId)
      );
    } else {
      setSelectedActorLicenseIds([...selectedActorLicenseIds, actorLicenseId]);
    }
  }

  function handlePrintAtMaSystemClose(isPrintDone) {
    setShowPrintAtMaSystem(false);
    if (isPrintDone) {
      // Close this dialog too in that case
      handleClose(isPrintDone);
    }
  }

  function handleClose(isPrintDone) {
    setIncludeAllLatestSkills(null);
    setShowPrintAtMaSystem(false);
    setShowIncludeAllLatestSkillsQuestion(false);
    onClose(isPrintDone);
  }

  function handlePrintTypeChange(event) {
    setPrintType(event.target.value);
    setPrintWidth(event.target.value === "card" ? cr80CardWidth : a4Width);
    setPrintHeight(event.target.value === "card" ? cr80cardHeight : a4Height);

    let printSideMode = printSideSettings.find((obj) => {
      return obj.printType === event.target.value;
    });
    setSidePrintMode(printSideMode?.printSideSetting ?? "both");
  }

  async function handleChangeCourseCoordinator() {
    if (newCourseCoordinatorName.length < 1) {
      setShowCourseCoordinatorError(true);
      setCourseCoordinatorHelperText(Translate.get("NotEmpty"));
      return;
    }

    setShowCourseCoordinatorError(false);
    setCourseCoordinatorHelperText("");

    if (newCourseCoordinatorName !== currentCourseCoordinatorName) {
      const actorLicenseIds = getStudentDetails().map((s) => s.actorLicenseId);
      const response = await updateCourseCoordinatorName(
        api,
        actorLicenseIds,
        newCourseCoordinatorName
      );
      if (response && response.isSuccessful) {
        // Set all of these to null because it will trigger a reload of data,
        // and also because data for other printType will then be reloaded
        setStudentCardsDetails(null);
        setCardPrintTemplate(null);
        setStudentDiplomasDetails(null);
        setDiplomaPrintTemplate(null);
        setStudentA4sDetails(null);
        setA4PrintTemplate(null);
      }
    }
  }

  async function handlePrintSideSettingChange(printMode) {
    let req = { printType: printType, sideSetting: printMode };

    setSidePrintMode(printMode);

    let settings = [...printSideSettings];
    let index = settings.map((e) => e.printType).indexOf(printType);
    if (index >= 0) {
      settings[index] = { printType: printType, printSideSetting: printMode };
      setPrintSideSettings(settings);
    }

    return await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}licenseprint/printSideSetting`,
      req,
      "POST"
    );
  }

  const showDialogContent =
    !showIncludeAllLatestSkillsQuestion &&
    isLoadingCount === 0 &&
    !!getSelectedPrintTemplate() &&
    !!getStudentDetails() &&
    availablePrintTypes.length > 0;

  const selectedTypeIsMissing =
    getSelectedPrintTemplate()?.textBoxes.length <= 0 ?? false;

  return (
    <>
      {isLoadingCount > 0 && <Preloader zIndexOverride={2000} />}
      <YesOrNoDialog
        open={showIncludeAllLatestSkillsQuestion}
        title={Translate.get("SelectSkillsToPrint")}
        text={
          !!courseId
            ? Translate.get("PrintLicensesWhichSkills")
            : Translate.get("PrintLicenseWhichSkills")
        }
        onNo={() => handleIncludeAllSkillsChange(false)}
        onYes={() => handleIncludeAllSkillsChange(true)}
        onCancel={() => handleClose(false)}
        noText={Translate.get("PrintLicenseSpecifiedSkills")}
        yesText={Translate.get("PrintLicenseLatestSkills")}
      />
      <CardPrintAtMaSystemDialog
        open={showPrintAtMaSystem}
        onClose={handlePrintAtMaSystemClose}
        extraRequestData={{
          courseId: courseId,
          includeAllLicenseGroupSkills: includeAllLatestSkills,
          actorLicenseIds: selectedActorLicenseIds,
        }}
      />
      {showDialogContent && (
        <>
          <Dialog
            maxWidth={false}
            onClose={() => handleClose(false)}
            open={true}
          >
            <DialogTitle>{Translate.get("OrderLicense")}</DialogTitle>
            <DialogContent>
              <Grid
                container
                direction={{ xs: "column", md: "row" }}
                wrap="nowrap"
                spacing={4}
                className={"licensePrintDialogGrid" + (isSmall ? " small" : "")}
              >
                <Grid item container md={6} direction="column" spacing={4}>
                  <Grid item>
                    <FormControl fullWidth error={selectedTypeIsMissing}>
                      <FormLabel>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "text.primary",
                            textTransform: "uppercase",
                            marginBottom: "18px",
                          }}
                        >
                          {Translate.get("Document")}
                        </Typography>
                      </FormLabel>
                      <Select
                        value={printType}
                        onChange={handlePrintTypeChange}
                      >
                        {availablePrintTypes.map((pt) => (
                          <MenuItem value={pt}>
                            {getPrintTypeLabel(pt)}
                          </MenuItem>
                        ))}
                      </Select>
                      {selectedTypeIsMissing && (
                        <FormHelperText error sx={{ marginLeft: "12px" }}>
                          {Translate.get("MissingPrintTemplate")}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <Box>
                      {/* Empty Box just to keep Accordion from moving when expanding */}
                    </Box>
                    <Accordion
                      className="advancedSettingsAccordion"
                      disableGutters
                    >
                      <AccordionSummary
                        sx={{ paddingLeft: 0, paddingRight: "6px" }}
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            color: "text.primary",
                            textTransform: "uppercase",
                          }}
                        >
                          {Translate.get("AdvancedSettings")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{ paddingLeft: 0, paddingRight: 0 }}
                      >
                        <Stack
                          direction="column"
                          spacing={4}
                          justifyContent="space-evenly"
                        >
                          {(isPrintingOnOwnPrinterEnabled ||
                            printType !== "card") && (
                            <FormControl>
                              <FormLabel id="side-radio-buttons-group">
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: "text.secondary",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {Translate.get("PrintOptions")}
                                </Typography>
                              </FormLabel>
                              <RadioGroup
                                sx={{ marginLeft: "12px" }}
                                name="side-radio-buttons-group"
                                value={sidePrintMode}
                                onChange={(event) =>
                                  handlePrintSideSettingChange(
                                    event.target.value
                                  )
                                }
                              >
                                <FormControlLabel
                                  value="both"
                                  control={<Radio />}
                                  label={Translate.get("BothFrontAndBack")}
                                />
                                <FormControlLabel
                                  value="front"
                                  control={<Radio />}
                                  label={Translate.get("FrontOnly")}
                                />
                                <FormControlLabel
                                  value="back"
                                  control={<Radio />}
                                  label={Translate.get("BackOnly")}
                                />
                              </RadioGroup>
                            </FormControl>
                          )}
                          <TextField
                            label={Translate.get("CourseCoordinator")}
                            value={
                              newCourseCoordinatorName ??
                              currentCourseCoordinatorName
                            }
                            onChange={(event) =>
                              setNewCourseCoordinatorName(event.target.value)
                            }
                            onBlur={handleChangeCourseCoordinator}
                            error={showCourseCoordinatorError}
                            helperText={courseCoordinatorHelperText}
                          />
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
                <Grid item container md={6} direction="column">
                  <Grid item>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "text.primary",
                        textTransform: "uppercase",
                        marginBottom: "18px",
                      }}
                    >
                      {Translate.get("PrintList")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={{ marginBottom: "12px" }}>
                      {printType !== "card"
                        ? Translate.get("LicensePrintInfoPrint")
                        : isPrintingOnOwnPrinterEnabled
                        ? Translate.get("LicensePrintInfoPrintOrOrder")
                        : Translate.get("LicensePrintInfoOrder")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {getSelectedPrintTemplate().textBoxes.length > 0 && (
                      <ActorLicensePrintList
                        studentDetails={getStudentDetails()}
                        selectedActorLicenseIds={selectedActorLicenseIds}
                        onSelect={handleSelectStudentCardChanged}
                        disableSelection={!!actorLicenseId} // Disable selection when working with individual actorLicenses
                        printTemplate={getSelectedPrintTemplate()}
                        sidePrintMode={sidePrintMode}
                        printType={printType}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleClose(false)}>
                {Translate.get("Cancel")}
              </Button>
              {(isPrintingOnOwnPrinterEnabled || printType !== "card") && (
                <Button
                  disabled={
                    !selectedActorLicenseIds ||
                    selectedActorLicenseIds?.length === 0
                  }
                  onClick={handlePrint}
                >
                  {Translate.get("Print")}
                </Button>
              )}
              <Button
                variant="contained"
                disabled={
                  printType !== "card" ||
                  !selectedActorLicenseIds ||
                  selectedActorLicenseIds?.length === 0 ||
                  hasAccess(accessKeys.isMASystem) ||
                  newCourseCoordinatorName?.length < 1 ||
                  currentCourseCoordinatorName?.length < 1
                }
                onClick={() => setShowPrintAtMaSystem(true)}
              >
                {Translate.get("PlaceOrder")}
              </Button>
            </DialogActions>
          </Dialog>
          {/* ActorLicensePrintView is only visible when printing, via media query in ActorLicensePrint.css */}
          <ActorLicensePrintView
            ref={printViewRef}
            printTemplate={getSelectedPrintTemplate()}
            studentCardsDetails={getStudentDetails().filter(
              (s) =>
                !!selectedActorLicenseIds.find((c) => c === s.actorLicenseId)
            )}
            printFront={sidePrintMode === "both" || sidePrintMode === "front"}
            printBack={sidePrintMode === "both" || sidePrintMode === "back"}
            printWidth={printWidth}
            printHeight={printHeight}
            printType={printType}
          />
        </>
      )}
    </>
  );
}
