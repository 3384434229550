import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import Login from "./pages/Login";
import Register from "./pages/Register";
import Person from "./components/actors/Person";
import Company from "./components/actors/Company";
import ForgotPassword from './pages/ForgotPassword'
import SetPassword from './pages/SetPassword'
import DashboardApp from "./pages/DashboardApp";
import DashboardAppMin from "./pages/DashboardAppMin";
import DashboardAppKs from "./pages/DashboardAppKs";
import ConfirmPermits from "./pages/ConfirmPermits";
import CoursesRoute from "./pages/CoursesRoute";
import Bookings from "./pages/Bookings";
import Settings from "./pages/Settings";
import LicenseRegistration from "./pages/LicenseRegistration";
import NotFound from "./pages/Page404";
import Actors from "./pages/Actors"
import Help from "./pages/Help"
import Flyer from "./pages/Flyer"
import LostCard from "./pages/LostCard"
import Privacy from "./pages/Privacy"
import Test1 from "./pages/Test1";
import Test2 from "./pages/Test2";
import LicenseManagement from "./pages/LicenseManagement"
import Notifications from "./pages/Notifications.js"
import InstructorMaterial from "./pages/InstructorMaterial";
import { accessKeys, hasAccess } from "./utils/userAccess";
import Redirector from "./pages/Redirector";
import MyProfileSettings from "./pages/MyProfileSettings";
import SelfReg from "./pages/SelfReg";
import SelfRegStudent from "./pages/SelfRegStudent";
import ActorPermits from "./pages/ActorPermits";
import Statistics from "./pages/Statistics";
import PrivateComponent from "./privateComponent";
import OrdersPage from "./pages/OrdersPage";
import PermitSignLogin from "./pages/PermitSignLogin.js";

const courseAdminRoutes = [
  { path: "courses", element: <PrivateComponent component={CoursesRoute} accessKeysAll={[accessKeys.courseAdmin]}/> },
  { path: "course/:id", element: <PrivateComponent component={Bookings} accessKeysAll={[accessKeys.courseAdmin]}/> },
  { path: "person/:id", element: <PrivateComponent component={Person} accessKeysSome={[accessKeys.courseAdmin, accessKeys.actorPermit]}/> },
  { path: "company/:id", element: <PrivateComponent component={Company} accessKeysSome={[accessKeys.courseAdmin, accessKeys.actorPermit]}/> },
  { path: "settings", element: <PrivateComponent component={Settings} accessKeysSome={[accessKeys.courseAdmin, accessKeys.actorPermit]}/> },
  { path: "actors", element: <PrivateComponent component={Actors} accessKeysSome={[accessKeys.courseAdmin, accessKeys.actorPermit]}/> },
  { path: "licenses", element: <PrivateComponent component={LicenseManagement} accessKeysAll={[accessKeys.courseAdmin]}/> },
  { path: "notifications", element: <PrivateComponent component={Notifications} accessKeysSome={[accessKeys.courseAdmin, accessKeys.actorPermit]}/> },
  { path: "course/:id/selfreg", element: <PrivateComponent component={SelfReg} accessKeysAll={[accessKeys.courseAdmin]}/> },
  { path: "course/:id/:courseBookingId/:actorId/selfreg", element: <PrivateComponent component={SelfReg} accessKeysAll={[accessKeys.courseAdmin]}/> },
];

const licenseRegistrationRoutes = [
  { path: "licenseregistration", element: <PrivateComponent component={LicenseRegistration} accessKeysAll={[accessKeys.swedishCompany]}/> }
];

const instructorMaterialRoutes = [
  { path: "instructormaterial", element: <PrivateComponent component={InstructorMaterial} accessKeysAll={[accessKeys.instructorMaterial]}/> }
];

const actorPermitRoutes = [
  { path: "permits", element: <PrivateComponent component={ActorPermits} accessKeysAll={[accessKeys.actorPermit]}/> },
];

const statisticsRoutes = [
  { path: "statistics", element: <PrivateComponent component={Statistics} accessKeysAll={[accessKeys.advancedPackage, accessKeys.courseAdmin]} /> }
];

// TODO finns ännu ingen key för just orderhantering
const orderManagementRoutes = [
  { path: "orders", element: <PrivateComponent component={OrdersPage} accessKeysAll={[accessKeys.licenseIssuer, accessKeys.courseAdmin]} />}
];

const emailLoginIsDefault =
!localStorage.getItem("eportLoginMethod")
|| localStorage.getItem("eportLoginMethod") === "email";

// Mårten: a bit dirty...
const loginQueryString = window.location.search ?  window.location.search : "";

export default function Router() {
  const onlyPermits=!hasAccess(accessKeys.courseAdmin) && hasAccess(accessKeys.actorPermit);
  console.log('onlyPermits',onlyPermits)
  const commonRoutes = [
    { element: <Navigate to="/dashboard/app" replace /> },
    ...hasAccess(accessKeys.courseAdmin) ? [{ path: "app", element: <DashboardApp /> }] : onlyPermits ? [{ path: "app", element: <DashboardAppKs /> }] : [{ path: "app", element: <DashboardAppMin /> }] ,
    { path: "myprofilesettings", element: <MyProfileSettings /> },
    { path: "help", element: <Help /> },
    { path: "flyer", element: <Flyer /> },
  ];
  return useRoutes([
    {
      path: "/dashboard",
      element: <PrivateComponent component={DashboardLayout} />,
      children: [
        ...commonRoutes,
        ...courseAdminRoutes,
        ...licenseRegistrationRoutes,
        ...instructorMaterialRoutes,
        ...actorPermitRoutes,
        ...statisticsRoutes,
        ...orderManagementRoutes,
      ],
    },
    // Dessa permits sign routes ligger inte i gruppen innan eftersom de har annan inloggningslösning (inte <PrivateComponent />)
    { path: "/dashboard/permits/groups/:actorPermitGroupId/sign", element: <ConfirmPermits isPermitGroup={true} /> },
    { path: "/dashboard/permits/:actorPermitId/sign", element: <ConfirmPermits isPermitGroup={false} /> },
    {
      path: "/test",
      element: <LogoOnlyLayout />,/*  element: <DashboardLayout />, */
      children: [
        // TODO ta bort
        { path: "1/group/:actorPermitGroupId", element: <ConfirmPermits /> },
        { path: "2", element: <DashboardAppMin /> },
        { path: "3/:actorPermitId", element: <ConfirmPermits /> },
      ],
    },
    { path: "/redirect/:id", element: <Redirector /> }, 
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "login", element: <Login openBankIdLogin={false} /> },
        { path: "login/bankId", element: <Login openBankIdLogin={true} /> },
        { path: "login/permit-sign/:encryptedData", element: <PermitSignLogin isPermitGroup={false} /> },
        { path: "login/permit-sign/group/:encryptedData", element: <PermitSignLogin isPermitGroup={true} /> },
        { path: "forgotpw", element: <ForgotPassword /> },
        { path: "register", element: <Register /> },
        { path: "reset", element: <SetPassword /> },
        { path: "Flyer", element: <Flyer /> },
        { path: "self", element: <SelfRegStudent /> },
        { path: "self/:id", element: <SelfRegStudent mode="ChooseCourseBooking"/>},
        { path: "self/:id/:courseBookingId/:actorId", element: <SelfRegStudent mode="Form" />},
        { path: "card/order", element: <LostCard />},
        { path: "privacy", element: <Privacy /> },

        { path: "404", element: <NotFound /> },
        { path: "/", element: <Navigate to={emailLoginIsDefault ? "/login" + loginQueryString : "/login/bankId" + loginQueryString } /> }, // visa epost om det var den senaste 
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
