// this object should match the eLearningType enum in ePortWebLibrary
export const eLearningTypes = Object.freeze({
  1: "ETruck",
  2: "ELift",
  3: "ETruckRepetition",
});

// These are used like eLearningLicenses in some cases,
// but don't really have eLearningTypes in DB
export const pseudoELearningTypes = Object.freeze({
  1000: "ExtraFinalExam",
});

export const eLearningTypeEnum = Object.freeze({
  ETruck: 1,
  ELift: 2,
  ETruckRepetition: 3,
});

export const pseudoELearningTypeEnum = Object.freeze({
  ExtraFinalExam: 1000,
});
