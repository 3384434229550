import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Translate from "../../utils/Translate";
import ActorLicensePreview from "./ActorLicensePreview";
import "./ActorLicensePrint.css";

export default function ActorLicensePrintPreviewDialog({
  printTemplate,
  studentDetails,
  printFront,
  printBack,
  open,
  onClose,
  printType,
}) {
  return (
    <Dialog maxWidth={false} scroll="paper" onClose={onClose} open={open}>
      <DialogTitle>{Translate.get("PreeView")}</DialogTitle>
      <DialogContent>
        {open && studentDetails && (
          <ActorLicensePreview
            printTemplate={printTemplate}
            cardDetails={studentDetails}
            isSelected={true}
            onSelectedChanged={() => {}}
            showFront={printFront}
            showBack={printBack}
            printType={printType}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{Translate.get("Close")}</Button>
      </DialogActions>
    </Dialog>
  );
}
