import { DataGridPremium } from "@mui/x-data-grid-premium";
import { useEffect, useState } from "react";
import Preloader from "../Preloader";
import { dataGridLang } from "../MemToolBar";
import Translate from "../../utils/Translate";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../utils/Api";
import {
  avatarColumn,
  textColumn,
  customCellColumn,
  StatisticsGridToolBar,
} from "./StatisticsCommon";
import { CustomNoRowsOverlay } from "../../utils/StyledGridOverlay";
import PersonIcon from "@mui/icons-material/Person";
import NumbersIcon from "@mui/icons-material/Numbers";
import PlaceIcon from "@mui/icons-material/Place";
import BusinessIcon from "@mui/icons-material/Business";
import EmailIcon from "@mui/icons-material/Email";
import HomeIcon from "@mui/icons-material/Home";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useSnackbar } from "notistack";
import { useMediaQuery } from "@mui/material";
import { accessKeys, hasAccess } from "../../utils/userAccess";

const ContactPersonStatistics = () => {
  const colNames = {
    dataId: "dataId",
    actorId: "actorId",
    parentName: "parentName",
    externalId: "externalId",
    parentEmail: "parentEmail",
    parentOrgNo: "parentOrgNo",
    parentAddress1: "parentAddress1",
    parentAddress2: "parentAddress2",
    parentZipCode: "parentZipCode",
    parentCity: "parentCity",
    parentPhone: "parentPhone",
    parentMobile: "parentMobile",
    childActorId: "childActorId",
    actorName: "actorName",
    actorPictureUrl: "actorPictureUrl",
    employeeNumber: "employeeNumber",
    actorEmail: "actorEmail",
    orgNo: "orgNo",
    webSite: "webSite",
    address1: "address1",
    address2: "address2",
    zipCode: "zipCode",
    city: "city",
    phone: "phone",
    mobile: "mobile",
    profileType: "ePortCloudLicenseLineType",
    roleName: "roleName",
  };

  const columns = [
    // textColumn(colNames.dataId, null, 'DataId'),
    // textColumn(colNames.actorId, null, 'ActorId'),
    textColumn(colNames.parentName, <BusinessIcon />, "Company"),
    textColumn(colNames.externalId, <NumbersIcon />, "BookerExternalActorNo"),
    textColumn(colNames.parentEmail, <EmailIcon />, "CompanyEmail"),
    textColumn(colNames.parentOrgNo, <NumbersIcon />, "OrgNo"),
    // textColumn(colNames.parentAddress1, null, 'ParentAddress1'), // samma som parentName ovan
    textColumn(colNames.parentAddress2, null, "CompanyAddress"),
    textColumn(colNames.parentZipCode, null, "CompanyZip"),
    textColumn(colNames.parentCity, <PlaceIcon />, "CompanyCity"),
    textColumn(colNames.parentPhone, null, "BookerPhone"),
    textColumn(colNames.parentMobile, null, "CompanyMobile"),
    // textColumn(colNames.childActorId, null, 'ChildActorId'),
    avatarColumn(
      colNames.actorName,
      null,
      colNames.actorPictureUrl,
      <PersonIcon />,
      "Name"
    ),
    textColumn(colNames.employeeNumber, null, "ExternalActorNo"),
    textColumn(colNames.actorEmail, <EmailIcon />, "ActorEmail"),
    textColumn(colNames.orgNo, <FingerprintIcon />, "PersonalNo"),
    textColumn(colNames.webSite, <HomeIcon />, "Website"),
    // textColumn(colNames.address1, null, 'Address1'), // samma som actorname ovan
    textColumn(colNames.address2, null, "Address2"),
    textColumn(colNames.zipCode, null, "ZipCode"),
    textColumn(colNames.city, <PlaceIcon />, "City"),
    textColumn(colNames.phone, null, "Phone"),
    textColumn(colNames.mobile, null, "MobilePhone"),
    ...(hasAccess(accessKeys.isMASystem)
      ? [
          customCellColumn(
            colNames.profileType,
            null,
            "EPortProfile",
            (params) =>
              params.row.ePortUserId
                ? Translate.getEPortLicenseLineType(params.value)
                : ""
          ),
          customCellColumn(colNames.roleName, null, "Role", (params) =>
            params.row.ePortUserId ? params.value ?? "STANDARD" : ""
          ),
        ]
      : []),
  ];

  const initialHiddenColumns = [
    //colNames.parentName,
    colNames.externalId,
    colNames.parentEmail,
    colNames.parentOrgNo,
    colNames.parentAddress2,
    //colNames.parentZipCode,
    //colNames.parentCity,
    colNames.parentPhone,
    colNames.parentMobile,
    //colNames.actorName,
    colNames.employeeNumber,
    //colNames.actorEmail,
    colNames.orgNo,
    colNames.webSite,
    colNames.address1,
    colNames.address2,
    colNames.zipCode,
    //colNames.city,
    colNames.phone,
    colNames.mobile,
  ];

  const localizedTextsMap = dataGridLang();
  const initialGridState = {
    sorting: {
      sortModel: [
        { field: colNames.parentName, sort: "asc" },
        { field: colNames.actorName, sort: "asc" },
      ],
    },
    columns: {
      columnVisibilityModel: initialHiddenColumns.reduce(
        (ac, a) => ({ ...ac, [a]: false }),
        {}
      ),
    },
  };

  let i = 0;
  const navigate = useNavigate();
  const [licenses, setLicenses] = useState();
  const smallScreen = useMediaQuery("(max-width:700px)");
  const [dataFetched, setDataFetched] = useState(false);
  const api = useApi();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [stdFilters, setStdFilters] = useState([
    "SelectFilter",
    "ContactHasEmail",
  ]);
  const [selectedStdFilter, setSelectedStdFilter] = useState("SelectFilter");
  const [filt, setFilt] = useState({
    items: [],
  });

  useEffect(async () => {
    let data = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}actors/contacts/report`
    );
    data = data.isSuccessful === false ? [] : data;

    data.forEach((element, index) => {
      element.id = index;
    });
    setLicenses(data);
    setDataFetched(true);
  }, [api]);

  const navigateToActor = (actorId) => {
    navigate(`/dashboard/person/${actorId}`, { replace: false });
  };

  function handleStdFilterChange(event) {
    setSelectedStdFilter(event.target.value);
    setStdFilter(event.target.value);
  }

  function setStdFilter(filterName) {
    switch (filterName) {
      case "SelectFilter":
        setFilt({ items: [] });
        break;

      case "ContactHasEmail":
        setFilt({
          ...filt,
          items: [
            {
              id: 1,
              field: "actorEmail",
              operator: "isNotEmpty",
              filterName: "ContactHasEmail",
            },
          ],
        });
        break;
      default:
        return;
    }
  }

  function clearFilters() {
    setFilt({ items: [] });
    setSelectedStdFilter("SelectFilter");
  }

  function handleFilterModelChange(newFilterModel, x) {
    if (x.reason === "restoreState") {
      return;
    }

    if (x.reason === "changeLogicOperator") {
      setFilt({
        ...filt,
        logicOperator: newFilterModel.logicOperator,
      });
      return;
    }

    const newFilt = {
      items: newFilterModel.items,
      logicOperator: newFilterModel.logicOperator,
    };
    setFilt(newFilt);

    var stdFilterExistsInFilters = newFilt.items.findIndex(
      (e) => e.filterName === selectedStdFilter
    );
    if (stdFilterExistsInFilters === -1) {
      setSelectedStdFilter("SelectFilter");
    }
  }

  return (
    <div style={{ height: "70vh" /* border:"1px solid blue" */ }}>
      {" "}
      {/* TODO: är 70vh bra? */}
      {!dataFetched && <Preloader invisible={true} />}
      {dataFetched && (
        <>
          <Grid container justifyContent="flex-start" sx={{ ml: 1 }}>
            <Tooltip
              title={
                <div style={{ whiteSpace: "pre-line" }}>
                  {Translate.get("ContactPersonStatisticsInfo")}
                </div>
              }
            >
              <IconButton>
                <InfoIcon color="primary" />
              </IconButton>
            </Tooltip>

            <FormControl sx={{ minWidth: "5em" }}>
              <InputLabel id="std-filter">
                {Translate.get("StdFilter") ?? " "}
              </InputLabel>
              <Select
                sx={{ maxWidth: smallScreen ? "10em" : "100%" }}
                labelId="std-filter"
                id="std-filter"
                name="ContactHasEmailFilter"
                value={selectedStdFilter}
                label={Translate.get("StdFilter") ?? " "}
                onChange={handleStdFilterChange}
              >
                {stdFilters.map((element) => (
                  <MenuItem key={element} value={element} name={element}>
                    {Translate.get(element)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              onClick={() => clearFilters()}
              variant="outlined"
              disabled={filt.items?.length === 0}
              startIcon={<DeleteIcon />}
              sx={{ ml: "1em" }}
              size="xlarge"
            >
              {Translate.get("Purge")}
            </Button>
          </Grid>

          <DataGridPremium
            getRowId={(row) => row.id}
            rows={licenses}
            filterModel={filt}
            onFilterModelChange={(newFilterModel, x) =>
              handleFilterModelChange(newFilterModel, x)
            }
            columns={columns}
            disableColumnMenu={true}
            onRowClick={(params) => navigateToActor(params.row.childActorId)}
            pageSizeOptions={[10, 50, 100]}
            pagination={true}
            localeText={localizedTextsMap}
            slots={{
              toolbar: () =>
                StatisticsGridToolBar(
                  "contactPersonStatistics",
                  "ContactPersons"
                ),
              noRowsOverlay: CustomNoRowsOverlay(Translate.get("EmptyHere")),
            }}
            density="comfortable"
            disableDensitySelector={true}
            initialState={initialGridState}
            disableRowSelectionOnClick
            // onExcelExportStateChange={(newState) =>{
            //     setDataFetched(newState !== "pending");
            // }}
          />
        </>
      )}
    </div>
  );
};

export default ContactPersonStatistics;
