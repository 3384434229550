import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

export default function ActionAreaCard({ image, date, headline, text, url }) {
  return (
    <Card
      title={text}
      sx={{
        display:'flex',
        maxWidth: '100%',
        padding: '4px',
        boxShadow: 'none',
        textAlign: 'left',
        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
        borderRadius:'0',
        
      }}
    >
      <CardActionArea href={url}
        target="_blank"
        sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start'
          }}
        >
        <CardMedia
          sx={{ 
            marginLeft: '16px' ,
            marginTop: '24px',
            marginBottom: '12px',
            width:'90px',
            height:'90px'

            
          }}
          component="img"
          image={image}
          alt="News image"
        />
        <CardContent>
          {/* <Typography
            gutterBottom
            variant="body3"
            color="text.secondary"
            component="div"
          >
            {date}
          </Typography> */}
          <Typography sx={{color: '#6373818f', fontSize:'11px'}}>
          {new Date(date).toLocaleDateString()}
          </Typography>
          <Typography variant="body2">{headline}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
