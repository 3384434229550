import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, Grid, TextField, Typography, Select, FormControl, MenuItem, Button, InputLabel } from "@mui/material";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import Api, { useApi } from "../../utils/Api";
import Preloader from "../Preloader";
import Translate from "../../utils/Translate";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import download from "downloadjs";
import { hasAccess, hasSomeAccess, accessKeys } from "../../utils/userAccess";
import debounce from "lodash.debounce";
import HtmlEditor from "../HtmlEditor";

export default function CompanyDetailsCard() {
  const [isLoading, setIsLoading] = useState(true);
  const [companyDetails, setCompanyDetails] = useState({});
  const [emailSignatureLoaded, setEmailSignatureLoaded] = useState(false);
  const [emailSignature, setEmailSignature] = useState(null);
  const [emailTemplateNames, setEmailTemplateNames] = useState([]);
  const [sendTestMailTo, setSendTestMailTo] = useState("");
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState("");
  const [documentTemplateNames, setDocumentTemplateNames] = useState([]);
  const [selectedDocumentTemplate, setSelectedDocumentTemplate] = useState("");
  const api = useApi();

  const loadCompanyDetails = useCallback(async () => {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}dataId/details`,
      false,
      "GET"
    );
    !!response && setCompanyDetails(response);
    setIsLoading(false);
  }, [api]);

  const saveCompanyDetails = useMemo(
    () =>
      debounce(async (companyDetails) => {
        await api.fetch(
          `${process.env.REACT_APP_MAIN_URL}dataId/addOrUpdateDataIdDetails`,
          companyDetails,
          "POST"
        );
      }, 2000),
    [api]
  );

  const loadEmailSignature = useCallback(async () => {
    const response = await api.fetchWithOverride(
      `${process.env.REACT_APP_MAIN_URL}email/signature`,
      false,
      "GET",
      (response) => (response || response === "") && response.isSuccessful !== false,
      "CouldNotLoadEmailSignature"
    );

    if((response || response === "") && response.isSuccessful !== false) {
      setEmailSignature(response);
      setEmailSignatureLoaded(true);
    } else {
      setEmailSignatureLoaded(false);
      setEmailSignature("");
    }
  }, [api]);

  const saveEmailSignature =
    async (emailSignature) => {
        if(emailSignatureLoaded) {
          const dto = { emailSignature };
          await api.fetch(
            `${process.env.REACT_APP_MAIN_URL}email/signature`,
            dto,
            "POST"
          );
        }
      };

  async function loadUserProfile() {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}profiles`,
      false,
      "GET"
    );
    !!response && setSendTestMailTo(response?.email || "");
    setIsLoading(false);
  }
  
  async function loadEmailTemplates() {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}email/test/templateNames`,
      false,
      "GET"
    );
    !!response && setEmailTemplateNames(response);
    !!response && setSelectedEmailTemplate(response[0]);
    setIsLoading(false);
  }

  async function loadDocumentTemplates() {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}documents/test/templateNames`,
      false,
      "GET"
    );
    !!response && setDocumentTemplateNames(response);
    setIsLoading(false);
  }

  async function sendTestEmail() {
    setIsLoading(true);
    await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}email/test`,
      {
        emailAddress: sendTestMailTo,
        templateName: selectedEmailTemplate
      },
      "POST");
    setIsLoading(false);
  }

  async function generateTestDocument() {
    const request =  { documentTemplateName: selectedDocumentTemplate };

    setIsLoading(true);
    const blob = await Api.fetchBlob(`${process.env.REACT_APP_MAIN_URL}documents/test`, request, "POST");
    download(blob, "testDocument.pdf", "application/pdf");
    setIsLoading(false);
  }

  useEffect(() => {
    loadCompanyDetails();
    loadEmailSignature();
    loadUserProfile();
    loadEmailTemplates();
    loadDocumentTemplates();
  }, [loadCompanyDetails, loadEmailSignature]);

  const CompanyDetailsSection = useCallback(
    ({ title, items }) => (
      <Grid item container spacing={3}>
        <Grid item container>
            <div className="smallHeader">{title}</div> 
        </Grid>
        <Grid item container columnSpacing={2} rowSpacing={3}>
          {items.map(({ placeholder, value, fullRow, ...rest }, idx) => (
            <Grid item key={idx} xs={12} sm={fullRow ? 12 : 6}>
              <TextField
                fullWidth
                value={value ?? ""}
                InputProps={{ spellCheck: false }}
                {...rest}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    ),
    []
  );

  function handleCompanyDetailsChange(event, detailName) {
    const newValue = event.target.value;
    const companyDetailsCopy = { ...companyDetails };
    companyDetailsCopy[detailName] = newValue;
    setCompanyDetails(companyDetailsCopy);
    saveCompanyDetails(companyDetailsCopy);
  }

  function handleEmailSignatureChange(newSignature) {
    setEmailSignature(newSignature);
    return saveEmailSignature(newSignature);
  }

  return (
    <Card sx={{ maxWidth: { sm: "900px" }, paddingBottom: "10px" }}>
      {isLoading && <Preloader />}
      <CardContent>
        <Grid container spacing={5}>
          <Grid item>
            <Typography variant="h4">
              {sessionStorage.companyName ?? Translate.get("Company")}
            </Typography>
          </Grid>
          <CompanyDetailsSection
            title={Translate.get("ContactInformation")}
            items={[
              {
                value: companyDetails.orgNo,
                label: Translate.get("OrgNo"),
                onChange: (event) => handleCompanyDetailsChange(event, "orgNo"),
              },
              {
                value: companyDetails.webPage,
                label: Translate.get("Website"),
                onChange: (event) =>
                  handleCompanyDetailsChange(event, "webPage"),
              },
              {
                value: companyDetails.phone,
                label: Translate.get("Phone"),
                onChange: (event) => handleCompanyDetailsChange(event, "phone"),
              },
              {
                value: companyDetails.emailAddress,
                label: Translate.get("Email"),
                onChange: (event) =>
                  handleCompanyDetailsChange(event, "emailAddress"),
              },
            ]}
          />
          <CompanyDetailsSection
            title={Translate.get("Address")}
            items={[
              {
                value: companyDetails.addressStreet2,
                label: Translate.get("Address"),
                onChange: (event) =>
                  handleCompanyDetailsChange(event, "addressStreet2"),
              },
              {
                value: companyDetails.addressCity,
                label: Translate.get("City"),
                onChange: (event) =>
                  handleCompanyDetailsChange(event, "addressCity"),
              },
              {
                value: companyDetails.addressZipCode,
                label: Translate.get("ZipCode"),
                onChange: (event) =>
                  handleCompanyDetailsChange(event, "addressZipCode"),
              },
              {
                value: companyDetails.addressCountry,
                label: Translate.get("Country"),
                onChange: (event) =>
                  handleCompanyDetailsChange(event, "addressCountry"),
              },
            ]}
          />
          {hasAccess(accessKeys.advancedPackage) && (
            <CompanyDetailsSection
              title={Translate.get("Billing")}
              items={[
                {
                  value: companyDetails.bankAccount1,
                  label: Translate.get("BankGiro"),
                  onChange: (event) =>
                    handleCompanyDetailsChange(event, "bankAccount1"),
                },
                {
                  value: companyDetails.bankAccount2,
                  label: Translate.get("PlusGiro"),
                  onChange: (event) =>
                    handleCompanyDetailsChange(event, "bankAccount2"),
                },
                {
                  value: companyDetails.iban,
                  label: Translate.get("IBAN"),
                  onChange: (event) =>
                    handleCompanyDetailsChange(event, "iban"),
                },
              ]}
            />
          )}
          {hasSomeAccess(
            accessKeys.standardPackage,
            accessKeys.advancedPackage
          ) && (
            <Grid item container spacing={3}>
              <Grid item container>
                  <div className="smallHeader">{Translate.get("EmailSignature")}</div> 
              </Grid>
              <Grid item container columnSpacing={2} rowSpacing={3}>
                  <HtmlEditor htmlContent={emailSignature} disabled={!emailSignatureLoaded} onBlur={(content) => handleEmailSignatureChange(content)}/>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item container spacing={5}>
          <Grid item container>
          <Accordion sx={{width: "100%"}}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography
                  sx={{
                    color: "text.secondary",
                    textTransform: "uppercase",
                    fontSize:'12px',
                  }}
                >{Translate.get("TestDocuments")}</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Grid item container rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12} sm={1} justifyContent="center" alignItems="center">
                  Email
                </Grid>
                <Grid item  xs={12} sm={4}>
                  <TextField label={Translate.get("SendTo")} sx={{width: "100%"}} value={sendTestMailTo} onChange={(evt) => setSendTestMailTo(evt.target.value)} />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <FormControl>
                    <InputLabel id="choose-email-template-select-label">
                      {Translate.get("ChooseTemplate")}
                    </InputLabel>
                    <Select labelId="choose-email-template-select-label" label={Translate.get("ChooseTemplate")} sx={{width: "100%"}} value={selectedEmailTemplate} onChange={(evt) => setSelectedEmailTemplate(evt.target.value)}>
                      {emailTemplateNames.map(templateName => <MenuItem value={templateName} key={templateName}>{Translate.get(templateName)}</MenuItem>)}
                    </Select>
                  </FormControl>
                  </Grid>
                <Grid item xs={12} sm={2}>
                  <Button onClick={sendTestEmail} sx={{marginTop: ".7rem"}}>{Translate.get("Send")}</Button>
                </Grid>
                <Grid item xs={12} sm={1} justifyContent="center" alignItems="center">
                  PDF
                </Grid>
                <Grid item xs={12} sm={9}>
                  <FormControl sx={{width: "93%"}}>
                    <InputLabel id="choose-email-template-select-label">
                      {Translate.get("ChooseTemplate")}
                    </InputLabel>
                    <Select label={Translate.get("ChooseTemplate")} sx={{minWidth:"20rem", width: "100%"}} value={selectedDocumentTemplate} onChange={evt => setSelectedDocumentTemplate(evt.target.value)}>
                      {documentTemplateNames?.map(template => <MenuItem value={template} key={template}>{Translate.get(template)}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Button sx={{marginTop: ".7rem"}} onClick={generateTestDocument}>{Translate.get("Generate")}</Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
          </Accordion>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}