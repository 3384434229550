// scroll bar
import "simplebar/src/simplebar.css";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ReactGA from "react-ga4";

//
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import reportWebVitals from "./reportWebVitals";
import "./App.css";
//ReactGA.initialize("G-L9RDWTGGQV",{ debug: true }); //skarp
ReactGA.initialize("G-NNEV8TXGER",{ debug: true }); //develop
ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search});  //Behövs denna, tror inte när man använder ga4?
//import { StrictMode } from 'react';

// TODO Ska vi lägga till integration med router? https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
// Tracing verkar kosta om man går över 100K events/månad. Vi får se hur mycket vi når upp i, och sedan se hur viktigt det är om det skulle kosta.
Sentry.init({
  dsn: "https://0da52213abd546d08b69900a75536cee@o4504128120881152.ingest.sentry.io/4504128191660032",
  integrations: [new BrowserTracing()],
  environment: process.env.NODE_ENV ?? "unknown",

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 0.2,
});

window.global = {};
window.global.dataGrid = {};
/* 
Om kortillstand.se ska stödja fler språk än svenska:
Ändra till statiskt window.global.isKs=false
I components/Page.js Ta bort rem från  const kortillstandSE= (window.location.host.indexOf('kortillstand.se') !== -1); 
Och ändra window.global.isKs till kortillstandSE i components/Page.js
Då ska allt rulla på det normala sättet med språkval 
*/
//window.global.isKs=false;
window.global.isKs= (window.location.host.indexOf('kortillstand.se') !== -1);
window.globalTriggerClick = (id) => {
  console.log(id);
  let link = document.getElementById(id);
  if (link) {
    link.click();
    return true;
  } else {
    return false;
  }
};
//Action = Händelse i Analytics
window.analyticsEvent = (category,action) => {
  console.log('analyticsEvent', category, action)
  ReactGA.event({
    category: category,
    action: action,
    //label:label, // optional
    //value: 99, // optional, must be a number
    //nonInteraction: true, // optional, true/false
    //transport: "xhr", // optional, beacon/xhr/image
  });
// ----------------------------------------------------------------------
};
ReactDOM.render(
  <HelmetProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </HelmetProvider>,
  document.getElementById("root")
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
