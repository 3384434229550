import { PhoneNumberUtil } from 'google-libphonenumber';
import { hasAccess, accessKeys } from "./userAccess";

const PNF = PhoneNumberUtil.getInstance();

export function validatePhoneNumber(phoneNumber) {
    let regionCode = getRegionCode();
    let parsedNumber = PNF.parse(phoneNumber, regionCode)
    let validationResult = PNF.isValidNumberForRegion(parsedNumber, regionCode);
    return validationResult;
}

function getRegionCode() {
    if (hasAccess(accessKeys.swedishCompany)) {
      return "SE";
    }
  
    if (hasAccess(accessKeys.englishCompany)) {
      return "GB";
    }
  
    if (hasAccess(accessKeys.frenchCompany)) {
      return "FR";
    }
  
    if (hasAccess(accessKeys.norwegianCompany)) {
      return "NO";
    }
  
    return "SE"
  }