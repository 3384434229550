import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useApi } from "../../utils/Api";
import "./stylesheet.css";
import Translate from "../../utils/Translate";
import Preloader from "../Preloader";
import { pseudoELearningTypes } from "../../utils/eLearningTypes";
import { ShoppingCart } from "@mui/icons-material";

export function Balance(props) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const api = useApi();

  useEffect(() => {
    recalculate();
  }, []);

  function recalculate() {
    api
      .fetch(
        `${process.env.REACT_APP_MAIN_URL}licenses/elearning/count/allELearningTypesBalance`,
        false,
        "GET"
      )
      .then((data) => {
        if (!data.error) {
          setData(data);
        }
      })
      .finally((f) => {
        setIsLoading(false);
      });
  }

  function switchMode(event) {
    props.switchMode(event.target.name, parseInt(event.target.value));
  }

  /* const cards = data.map((item, i) => {
        return (
            <Card key={i}>
                <CardContent>
                    <h3>{eLearningTypeNames[item.eLearningType]}</h3>
                    <Box
                        sx={{
                            mx: 'auto',
                            width: 300,
                            p: 1,
                            m: 0.01,
                            mt: 1,
                            bgcolor: (theme) =>
                                theme.palette.mode === 'dark' ? '#101010' : 'grey.50',
                            color: (theme) =>
                                theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                            border: '1px solid',
                            borderColor: (theme) =>
                                theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                            borderRadius: 1,
                            textAlign: 'left',
                            fontSize: '0.875rem',
                            fontWeight: '700',
                        }}
                    >
                        {Translate.get('Balance') + ": " + item.numberOfFreeLicenses}
                    </Box>
                </CardContent>
                <Grid container justifyContent="flex-end" mt={3}>
                    <Button name="recall" value={item.eLearningType} onClick={switchMode} variant="text">{Translate.get('RecallUnused')}</Button>
                    <Button name="order" value={item.eLearningType} onClick={switchMode} variant="contained">{Translate.get('PlaceOrder')}</Button>
                </Grid>
            </Card>
        );
    });

    return (
        <div>
            {isLoading && <Preloader />}
            <h2 className="titleBalance">{Translate.get('Passwords')}</h2>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1,
                        width: 450,
                        height: 200,
                        padding: 1,
                    },
                }}
            >
                {cards}
            </Box>
        </div>
    ); */
  const cards = data.map((item, i) => {
    return (
      <div className="stdCard" key={i} style={{ maxWidth: "400px" }}>
        <div className="stdCardHeader">
          {Translate.getELearningType(item.eLearningType)}
        </div>

        <div className="stdCardBody">
          <div className="stdCardInnerBox">
            {Translate.get("Balance") + ": " + item.numberOfFreeLicenses}
          </div>
        </div>

        <div className="stdCardFooter">
          {/* Removed feature for extra final exams for now since it's not implemented backend.
              But it should be pretty easy to implement it using the existing logic in ePortWebLibrary
              ELearningLicenseDbService.ELearningLicense_UndoAddExtraFinalExamAsync */}
          {!(item.eLearningType in pseudoELearningTypes) && (
            <Button
              name="recall"
              value={item.eLearningType}
              onClick={switchMode}
              variant="text"
            >
              {Translate.get("RecallUnused")}
            </Button>
          )}
          <Button
            name="order"
            value={item.eLearningType}
            onClick={switchMode}
            variant="contained"
            endIcon={<ShoppingCart/>}
          >
            {Translate.get("PlaceOrder")}
          </Button>
        </div>
      </div>
    );
  });

  return (
    <div className="stdPage">
      <div className="stdPageTitle">{Translate.get("Passwords")}</div>
      {isLoading && <Preloader />}

      <div className="stdCardHolder">{cards}</div>
    </div>
  );
}
