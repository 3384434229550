import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import { TabPanel } from "../components/settings/TabPanel";
import { GeneralSettings } from "../components/settings/GeneralSettings";
import CompanySettings from "../components/settings/CompanySettings";
import InstructorSettings from "../components/settings/InstructorSettings";
import ClassRoomSettings from "../components/settings/ClassRoomSettings";
import CourseTypeSettings from "../components/settings/course-types/CourseTypeSettings";
import IframeSettings from "../components/settings/IframeSettings";
import Translate from "../utils/Translate";
import { hasSomeAccess, accessKeys, hasAccess } from "../utils/userAccess";
import { getQueryParam } from "../utils/uriHelper";
import { useNavigate, useLocation } from "react-router-dom";
import OrderSettings from "../components/settings/OrderSettings";
import LicenseGroupSettings from "../components/settings/LicenseGroupSettings";
import SkillGroupSettings from "../components/settings/skillgroups/SkillGroupSettings";
import UsersSettings from "../components/settings/users/UsersSettings";
import { useApi } from "../utils/Api";
import { ActorPermitSettings } from "../components/settings/ActorPermitsSettings";

export function Settings(props) {
  const [value, setValue] = useState(0);
  const [canManageReadOnlyUsers, setCanManageReadOnlyUsers] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const api = useApi();

  useEffect(() => {
    async function checkReadOnlyProfiles() {
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}licenses/eport/users/readonly/self/any`,
        false,
        "GET"
      );
      if (response.isSuccessful) {
        setCanManageReadOnlyUsers(response.result);
      }
    }

    if (hasAccess(accessKeys.courseAdmin)) {
      checkReadOnlyProfiles();
    }
  }, [api]);

  useEffect(() => {
    const tabParamValue = getQueryParam("tab");
    if (tabParamValue) {
      setValue(parseInt(tabParamValue));
    } else {
      setValue(0);
      navigate(`${location.pathname}?tab=0`, { replace: true });
    }
  }, [navigate, location]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`${location.pathname}?tab=${newValue}`, { replace: true });
  };

  const tabs = [
    {
      label: Translate.get("Company"),
      component: <CompanySettings />,
      isVisible: true,
    },
    {
      label: Translate.get("Instructors"),
      component: <InstructorSettings />,
      isVisible: hasAccess(accessKeys.courseAdmin),
    },

    {
      label: Translate.get("ClassRooms"),
      component: <ClassRoomSettings />,
      isVisible:
        hasAccess(accessKeys.courseAdmin) &&
        hasAccess(accessKeys.advancedPackage),
    },
    {
      label: Translate.get("CourseTemplates"),
      component: <CourseTypeSettings />,
      isVisible:
        hasAccess(accessKeys.courseAdmin) &&
        hasSomeAccess(accessKeys.standardPackage, accessKeys.advancedPackage),
    },
    {
      label: Translate.get("Licences"),
      component: <LicenseGroupSettings />,
      isVisible: hasAccess(accessKeys.admin),
    },
    {
      label: Translate.get("Competences"),
      component: <SkillGroupSettings />,
      isVisible: hasAccess(accessKeys.admin),
    },
    {
      label: Translate.get("IframeCoursebooking"),
      component: <IframeSettings />,
      isVisible: hasAccess(accessKeys.iframe),
    },
    {
      label: Translate.get("Orders"),
      component: <OrderSettings />,
      isVisible:
        hasAccess(accessKeys.courseAdmin) &&
        hasAccess(accessKeys.licenseIssuer),
    },
    {
      label: Translate.get("Users"),
      component: <UsersSettings />,
      isVisible: canManageReadOnlyUsers,
    },
    {
      label: Translate.get("General"),
      component: <GeneralSettings />,
      isVisible: hasAccess(accessKeys.courseAdmin),
    },
    {
      label: Translate.get("ActorPermit"),
      component: <ActorPermitSettings />,
      isVisible: hasAccess(accessKeys.actorPermit),
    },
  ];

  return (
    <div>
      <h2>{Translate.get("Settings")}</h2>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} centered>
            {tabs
              .filter((t) => t.isVisible)
              .map((t) => (
                <Tab
                  className="tabFixFlow"
                  label={t.label}
                  key={"tab-" + t.label}
                />
              ))}
          </Tabs>
        </Box>
        {tabs
          .filter((t) => t.isVisible)
          .map((t, i) => (
            <TabPanel
              className="tabPanelNoPadding"
              value={value}
              index={i}
              key={"tab-panel-" + i}
            >
              {t.component}
            </TabPanel>
          ))}
      </Box>
    </div>
  );
}
export default Settings;
