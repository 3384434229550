import { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import BadgeIcon from "@mui/icons-material/Badge";
import SchoolIcon from "@mui/icons-material/School";
import BuildIcon from "@mui/icons-material/Build";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import Translate from "../../utils/Translate";
import { formatDate } from "../../utils/dateTimeFormat";
import { hasAccess, accessKeys, hasSomeAccess } from "../../utils/userAccess";
import { getLicenseRegistrationCourseTypes } from "../../utils/externalUserHelper";
import DotMenu from "../DotMenu";
import YesOrNoDialog from "../YesOrNoDialog";
import { useApi } from "../../utils/Api";
import ActorLicensePrintDialog from "../actor-license-print/ActorLicensePrintDialog";
import { CustomNoRowsOverlay } from "../../utils/StyledGridOverlay";
import LicensCard from "./LicensCard";

const getDotMenuActions = ({ onDeleteLicense }) => {
  const showMenuActions =
    hasAccess(accessKeys.courseAdmin) &&
    hasSomeAccess(accessKeys.standardPackage, accessKeys.advancedPackage);
  const menudata = showMenuActions
    ? [
        {
          icon: <DeleteIcon />,
          text: Translate.get("DeleteActorLicense"),
          onClick: onDeleteLicense,
        },
      ]
    : [];
  return menudata;
};

const Comp = ({ rows, smallScreen, hideFilter = true }) => {
  const [printActorLicenseId, setPrintActorLicenseId] = useState(null);
  const [licenseRegistrationCourseTypes, setLicenseRegistrationCourseTypes] =
    useState([]);
  const [hasAdvanced] = useState(hasAccess(accessKeys.advancedPackage));
  const [actorLicenseIdToDelete, setActorLicenseIdToDelete] = useState(null);
  const api = useApi();

  const navigate = useNavigate();

  const localizedTextsMap = {
    columnMenuUnsort: Translate.get("DoNotSort"),
    columnMenuSortAsc: Translate.get("SortAscending"),
    columnMenuSortDesc: Translate.get("SortDescending"),
    columnMenuFilter: Translate.get("Filter"),
    columnMenuHideColumn: Translate.get("Hide"),
    columnMenuShowColumns: Translate.get("Show"),
    toolbarDensity: Translate.get("RowHeight"),
    toolbarExport: Translate.get("Export"),
    toolbarFilters: Translate.get("Filter"),
    toolbarColumns: Translate.get("Columns"),
    MuiTablePagination: {
      labelRowsPerPage: Translate.get("ShowNumberEntries"),
      labelDisplayedRows: ({ from, to, count }) =>
        `${from}-${to} ${Translate.get("Of")} ${
          count !== -1 ? count : `${Translate.get("Total")} ${to}`
        }`,
    },
  };

  //data:actorLicenses
  //const smallScreen = useMediaQuery('(max-width:700px)');
  let columns = [];
  let toolbarCss = "stdVisible dgDynIconText";
  if (hideFilter) {
    toolbarCss = "dgNoFilter dgNoIconText";
  }

  function isOrderLicenseAvailable(courseType) {
    // Advanced can print all courseTypes, while standard is limited to
    // the types available in <LicenseRegistration />
    return hasAdvanced || licenseRegistrationCourseTypes.includes(courseType);
  }

  function handleOrderLicensesClick(actorLicenseId) {
    if (hasAdvanced) {
      setPrintActorLicenseId(actorLicenseId);
    } else {
      navigate(
        `/dashboard/licenseregistration?actorLicenseId=${actorLicenseId}`
      );
    }
  }

  function handleClosePrintDialog(isPrintDone) {
    setPrintActorLicenseId(null);
    if (isPrintDone) {
      navigate(0);
    }
  }

  async function handleDeleteActorLicense() {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}actors/actorlicenses/${actorLicenseIdToDelete}`,
      true,
      "DELETE"
    );
    if (response && response.isSuccessful) {
      navigate(0);
    }
    setActorLicenseIdToDelete(null);
  }

  if (!smallScreen) {
    columns = [
      {
        field: "licenseGroupDescription",
        headerName: Translate.get("LicenceType"),
        flex: 1,
        renderHeader: () => (
          <div className="stdFlexLeft">
            <SchoolIcon />
            <div>{Translate.get("LicenceType")}</div>
          </div>
        ),
      },
      {
        field: "customx",
        headerName: Translate.get("Competences"),
        renderHeader: () => (
          <div className="stdFlexLeft">
            <BuildIcon />
            <div>{Translate.get("Competences")}</div>
          </div>
        ),
        renderCell: (params) => {
          return (
            <div>
              {
                <span>
                  {[
                    params.row.licenseGroupPractice,
                    params.row.licenseGroupTheory,
                  ]
                    .filter((s) => !!s)
                    .join(", ")}
                </span>
              }
            </div>
          );
        },
        valueGetter: (params) => params.row.licenseGroupPractice || "",
        sortComparator: (v1, v2) => v1.localeCompare(v2),
        flex: 1,
      },
      {
        field: "validFrom",
        flex: 1,
        headerName: Translate.get("ValidTime"),
        renderHeader: () => (
          <div className="stdFlexLeft">
            <CalendarTodayIcon />
            <div>{Translate.get("ValidTime")}</div>
          </div>
        ),
        valueGetter: (params) =>
          `${params.row.validFrom ? formatDate(params.row.validFrom) : ""} - ${
            params.row.validTo ? formatDate(params.row.validTo) : ""
          }`,
      },
      {
        field: "instructorName",
        headerName: Translate.get("Instructor"),
        flex: 1,
        renderHeader: () => (
          <div className="stdFlexLeft">
            <CoPresentIcon />
            <div>{Translate.get("Instructor")}</div>
          </div>
        ),
      },
      {
        field: "lastPrintDate",
        headerName: Translate.get("LastOrdered"),
        flex: 1,
        renderHeader: () => (
          <div className="stdFlexLeft">
            <EventAvailableIcon />
            <div>{Translate.get("LastOrdered")}</div>
          </div>
        ),
        valueGetter: ({ value }) => value && formatDate(value),
      },
      {
        field: "custom1",
        headerName: Translate.get("PlaceOrder"),
        flex: 1,
        minWidth: 200,
        sortable: false,
        renderHeader: () => (
          <div></div>
          /*  <div className="stdFlexLeft">
            <DomainIcon />
            <div>{Translate.get("PlaceOrder")}</div>
          </div> */
        ),
        renderCell: (params) => {
          return (
            //params.row

            <Button
              variant="outlined"
              disabled={
                !isOrderLicenseAvailable(
                  params.row.licenseGroupDescription?.trim()
                )
              }
              onClick={() =>
                handleOrderLicensesClick(params.row.actorLicenseId)
              }
            >
              {Translate.get("OrderLicense")}
            </Button>
          );
        },
      },
      {
        field: "custom2",
        headerName: "",
        flex: 0,
        align: "right",
        sortable: false,
        maxWidth: 50,
        //hide:hideFilter,

        renderHeader: () => <div></div>,
        renderCell: (params) => {
          const menuActions = getDotMenuActions({
            onDeleteLicense: () =>
              setActorLicenseIdToDelete(params.row.actorLicenseId),
          });
          return (
            <>
              {menuActions.length > 0 && (
                <DotMenu
                  content={getDotMenuActions({
                    onDeleteLicense: () =>
                      setActorLicenseIdToDelete(params.row.actorLicenseId),
                  })}
                  horizontal
                />
              )}
            </>
          );
        },
      },
    ];
  }

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });

  useEffect(() => {
    async function loadLicenseRegistrationCourseTypes() {
      const courseTypes = await getLicenseRegistrationCourseTypes();
      setLicenseRegistrationCourseTypes(courseTypes);
    }
    loadLicenseRegistrationCourseTypes();
  }, []);

  return (
    <>
      {!!printActorLicenseId && (
        <ActorLicensePrintDialog
          actorLicenseId={printActorLicenseId}
          onClose={handleClosePrintDialog}
        />
      )}
      {smallScreen &&
        rows.map((row) => (
          <LicensCard
            row={row}
            getDotMenuActions={getDotMenuActions}
            setActorLicenseIdToDelete={setActorLicenseIdToDelete}
            isOrderLicenseAvailable={isOrderLicenseAvailable}
            handleOrderLicensesClick={handleOrderLicensesClick}
          />
        ))}
      {!smallScreen && (
        <DataGrid
          className={toolbarCss}
          rows={rows}
          columns={columns}
          //checkboxSelection
          disableSelectionOnClick
          getRowId={(row) => row.actorLicenseId}
          autoHeight={true}
          initialState={{
            sorting: {
              sortModel: [{ field: "validFrom", sort: "desc" }],
            },
          }}
          // onRowClick={(params) => navToCompany(params.id)}
          // onCellClick={(params, event) => handleCellClick(params, event)}
          localeText={localizedTextsMap}
          pagination
          pageSizeOptions={[5]}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          density="comfortable"
          components={{
            Toolbar: GridToolbar,
            NoRowsOverlay: CustomNoRowsOverlay(Translate.get("EmptyHere")),
          }}
          disableColumnMenu
          getRowHeight={({ id, densityFactor }) => {
            if (smallScreen) {
              return 300; //345 om stylad som card
            }
            return null;
          }}
          //densityFactor={240}
          //rowHeight={280}
        />
      )}
      <YesOrNoDialog
        open={!!actorLicenseIdToDelete}
        title={Translate.get("DeleteActorLicense")}
        text={Translate.get("SureDeleteActorLicense")}
        onNo={() => setActorLicenseIdToDelete(null)}
        onYes={() => handleDeleteActorLicense()}
        noText={Translate.get("Cancel")}
        yesText={Translate.get("Delete")}
      />
    </>
  );
};
export default Comp;
