import { Grid, Switch } from "@mui/material";
import Translate from "../../utils/Translate";
import { useState } from "react";
import Preloader from "../Preloader";
import { useEffect } from "react";
import { useApi } from "../../utils/Api";

export default function OrderSettings() {
  const [isLoading, setIsLoading] = useState(true);
  const [autoFinishOrders, setAutoFinishOrders] = useState(null);
  const api = useApi();

  useEffect(() => {
    api
      .fetch(`${process.env.REACT_APP_MAIN_URL}orders/settings`, false, "GET")
      .then((response) => {
        if (response.isSuccessful) {
          setAutoFinishOrders(response.autoFinishOrders ?? false);
        }
        setIsLoading(false);
      });
  }, [api]);

  function postUpdateSettings(autoFinishOrders) {
    const request = {
      autoFinishOrders,
    };
    api.fetch(
      `${process.env.REACT_APP_MAIN_URL}orders/settings`,
      request,
      "POST"
    );
  }

  if (isLoading) {
    return <Preloader />;
  }

  return (
    <div
      className="stdPage"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div className="stdCard" style={{ minWidth: "400px", maxWidth: "500px" }}>
        <div className="stdCardHeader">{Translate.get("Orders")}</div>
        <div className="stdCardBody">
          <Grid container direction="column">
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item>
                <div>{Translate.get("AutoFinishOrdersSettingsLabel")}</div>
              </Grid>
              <Grid item>
                <Switch
                  checked={autoFinishOrders}
                  onChange={(event) => {
                    setAutoFinishOrders(event.target.checked);
                    postUpdateSettings(event.target.checked);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
