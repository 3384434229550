export const partType = {
        book: 1,
        course: 2,
        serviceAgreement: 3,
        teacher: 4,
        storeGoods: 5,
        freight: 6,
        resourceThing: 7,
        eLearning: 8,
        license: 9,
        plasticCardPrintJob: 10,
        ePortProgamLicense: 11,
        ePortUserLicense: 12,
        SMS: 13,
        ePortReportClient: 14,
        iframe: 15,
        courseAdminHTML: 17,
        drivingPermitsHTML: 18,
        drivingPermitsHTMLUser: 19,
        courseAdminReadOnly: 20,
        userReadOnly: 21,
        freight25Vat: 61,
        freight12Vat: 62,
        freight06Vat: 63,
        freightEUVat: 64,
        freightOutsideEUVat: 65,
        resellableELearningLicense: 88,
        expired: 99
}