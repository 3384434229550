import DashboardIcon from "@mui/icons-material/Dashboard";
import DescriptionIcon from "@mui/icons-material/Description";
import SchoolIcon from "@mui/icons-material/School";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import SettingsIcon from "@mui/icons-material/Settings";
import { accessKeys, hasAccess, hasSomeAccess } from "../../utils/userAccess";
import BadgeIcon from "@mui/icons-material/Badge";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import SummarizeIcon from "@mui/icons-material/Summarize";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

// ----------------------------------------------------------------------

const dashboardLink = {
  title: "GoToDashboard",
  path: "/dashboard/app",
  icon: <DashboardIcon />,
};

const coursesLink = {
  title: "Courses",
  path: "/dashboard/courses",
  icon: <SchoolIcon />,
};

const registryLink = {
  title: "Register",
  path: "/dashboard/actors",
  // path: '/404',
  icon: <FolderCopyIcon />,
};

const passwordsLink = {
  title: "Passwords",
  path: "/dashboard/licenses",
  icon: <VpnKeyIcon />,
};

const settingsLink = {
  title: "Settings",
  path: "/dashboard/settings",
  // path: '/register',
  icon: <SettingsIcon />,
};

const licenseRegistrationLink = {
  title: "RegisterLicense",
  path: "/dashboard/licenseregistration",
  icon: <BadgeIcon />,
};

const instructorMaterialLink = {
  title: "InstructorMaterial",
  path: "/dashboard/instructormaterial",
  icon: <DescriptionIcon />,
};

const actorPermitLink = {
  title: "ActorPermit",
  path: "/dashboard/permits",
  icon: <ReceiptLongIcon />,
};

const statisticsLink = {
  title: "Reports",
  path: "/dashboard/statistics",
  icon: <QueryStatsIcon />,
};

const orderManagementLink = {
  title: "Orders",
  path: "/dashboard/orders",
  icon: <ShoppingCartIcon />,
};

function addIfAccess(accessKey, link) {
  return addIfTrue(hasAccess(accessKey), link);
}

function addIfTrue(condition, link) {
  return condition ? link : null;
}

export default function getSidebarConfig() {
  const onlyPermits=!hasAccess(accessKeys.courseAdmin) && hasAccess(accessKeys.actorPermit);
  return [
    addIfTrue(onlyPermits,dashboardLink),
    addIfAccess(accessKeys.dashboard, dashboardLink),
    addIfAccess(accessKeys.courseAdmin, coursesLink),
    addIfTrue(
      hasSomeAccess(accessKeys.courseAdmin, accessKeys.actorPermit),
      registryLink
    ),
    addIfAccess(accessKeys.courseAdmin, passwordsLink),
    addIfTrue(
      !hasAccess(accessKeys.advancedPackage) &&
        hasAccess(accessKeys.swedishCompany) && (hasAccess(accessKeys.courseAdmin) || (hasAccess(accessKeys.standardPackage) && !hasAccess(accessKeys.actorPermit))),
      licenseRegistrationLink
    ),
    addIfAccess(accessKeys.instructorMaterial, instructorMaterialLink),
    addIfAccess(accessKeys.actorPermit, actorPermitLink),
    addIfTrue(
      hasAccess(accessKeys.advancedPackage) &&
        hasAccess(accessKeys.courseAdmin),
      statisticsLink
    ),
    // TODO specifik key för orders
    addIfTrue(
      hasAccess(accessKeys.licenseIssuer) && hasAccess(accessKeys.courseAdmin),
      orderManagementLink
    ),
    addIfTrue(
      hasSomeAccess(accessKeys.courseAdmin, accessKeys.actorPermit),
      settingsLink
    ),
    //...helpLinks,
  ].filter((x) => !!x);
}
