export const accessKeys = {
  license: "LIC",
  userLicense: "USR",
  sms: "SMS",
  iframe: "IFRAME",
  eportAssist: "APP",
  courseAdmin: "ICA",
  report: "REPORT",
  actorPermit: "PERMITS",
  licenseRegistration: "LICENSE_REG",
  instructorMaterial: "INST_MAT",
  freePackage: "FREE",
  standardPackage: "STD",
  advancedPackage: "ADV",
  dashboard: "DASH",
  swedishCompany : "SWE",
  norwegianCompany : "NOR",
  frenchCompany : "FRA",
  englishCompany : "ENG",
  germanCompany : "GER",
  isMASystem : "LUND",
  licenseIssuer: "LIC_ISS",
  admin: "ADMIN",
};

let parsedUserAccess;
function getUserAccess() {
  if (!parsedUserAccess && sessionStorage.userAccess) {
    parsedUserAccess = 
       new Set(JSON.parse(sessionStorage.userAccess));
  }
  return parsedUserAccess ?? new Set();
}

export function hasSomeAccess(...accesses) {
  const userAccess = getUserAccess();
  return accesses.some((access) => userAccess.has(access));
}

export function hasAccess(...accesses) {
  const userAccess = getUserAccess();
  return accesses.every((access) => userAccess.has(access));
}

export function setAccess(accessCollection) {
  sessionStorage.setItem("userAccess", JSON.stringify(accessCollection));
}

export function resetAccess() {
  parsedUserAccess = null;
  sessionStorage.removeItem("userAccess");
}

export const refreshTokenExpirationMinutes = 60;
