import VpnKeyIcon from '@mui/icons-material/VpnKey';
import NoteAddIcon from '@mui/icons-material/NoteAdd';


export function hasBeenSentToActor(actorId, courseCommunication, role, documentType) {
  if (!Array.isArray(courseCommunication) || !courseCommunication.length) {
    return
  }
  let courseCom = courseCommunication.find(obj => obj.actorId === actorId && obj.documentType === documentType && obj.media === 1 && obj.role === role)
  let sendDate = courseCom?.sendDate ?? ""
  return sendDate.substring(0, 10)
}

export function hasBeenSent(actorId, courseCommunication, role, documentType) {
  let sendDate = hasBeenSentToActor(actorId, courseCommunication, role, documentType)
  if (sendDate === undefined) {
    return false;
  }
  return sendDate !== ""
}

export function emailBounce(actorId, courseCommunication, role, documentType) {
  if (!Array.isArray(courseCommunication) || !courseCommunication.length) {
    return
  }
  let courseCom = courseCommunication.find(obj => obj.actorId === actorId && obj.documentType === documentType && obj.media === 1 && obj.role === role)
  let bounced = courseCom?.bounced ?? false
  return bounced
}

export function licenseIcon(actorId, courseCommunication, role, documentType) {
  if (hasBeenSent(actorId, courseCommunication, role, documentType)) {
    if (emailBounce(actorId, courseCommunication, role, documentType)) {
      return (
        <VpnKeyIcon color="error" />
      )
    } else {
      return (
        <VpnKeyIcon />
      )
    }
  } else {
    return (
      <></>
    )
  }
}

export function extraFinalExamIcon(actorId, courseCommunication, role, documentType) {
  if (hasBeenSent(actorId, courseCommunication, role, documentType)) {
    if (emailBounce(actorId, courseCommunication, role, documentType)) {
      return (
        <NoteAddIcon color="error" />
      )
    } else {
      return (
        <NoteAddIcon />
      )
    }
  } else {
    return (
      <></>
    )
  }
}
