import { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import { useApi } from '../../utils/Api';
import Translate from '../../utils/Translate';
import Box from '@mui/material/Box';
import { TextField } from '@mui/material';

const StdOrderConfirmationText = () => {
    const api = useApi();
    const [text, setText] = useState('');
    const [loaded, setLoaded] = useState(false);

    if (!loaded) {
        (async () => {
            const data = await api.fetch(
                `${process.env.REACT_APP_MAIN_URL}dataIdSettings/orderconfirmation`,
            );
            setLoaded(true);
            setText(data.settingValue);

        })();
    }
    const submit = () => {

        //console.log('text', text);
        (async () => {
            const data = {};
            data.settingValue = text;
            const ret = await api.fetch(
                `${process.env.REACT_APP_MAIN_URL}dataIdSettings/orderconfirmation`, data
            );
            if (ret.isSuccessful === false) {
                //console.log('error', ret.errorMessageTranslationKey);
            }
        })();
    };
    return (
        <>
            <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                    <TextField
                        multiline
                        rows={6}
                        fullWidth
                        value={text}
                        label={Translate.get("Text")}
                        inputProps={{
                            onBlur: () => {
                                submit();
                            }
                        }}
                        onChange={(event) => {
                            setText(event.target.value);
                        }}
                    />
                </FormControl>
            </Box>
        </>
    );
};
export default StdOrderConfirmationText;
